import Axios from "axios" 
import { useNavigate,useLocation } from "react-router-dom";
import { useEffect,useState } from "react";
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Constants from "../services/Constants";  
import MessageComponent from '../components/MessageComponent';
import ListmessageComponent from '../components/ListmessageComponent';
import Skeleton , { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import ResultNotFoundComponent from '../components/ResultNotFoundComponent';

export default function MessagesentComponent() {
	const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const state = location.state;
  let userToken = JSON.parse(localStorage.getItem('userToken'));
  const [messagesentUsers, setMessageSentUsers] = useState([]);
  const [update, setUpdate] = useState(0);
  const [loading,setLoading] = useState(0);
  const [width,setWidth] = useState({size:0,data:0});

  if(window.innerWidth >= 481 && window.innerWidth <= 768 && width.size===0){
    setWidth({...width,size:1});
  }

  if(window.innerWidth >= 769 && window.innerWidth <= 1024 && width.size===0){
    setWidth({...width,size:2});
  }

  if(window.innerWidth >= 1050 && window.innerWidth <= 1400 && width.size===0){
    setWidth({...width,size:3});
  }


  useEffect(() => { 
    const getSentMessages =  () => {
      Axios.post(Constants.API_URL+"getsentmessages",{
          api_token : userToken
        }).then((response)=>{
          setMessageSentUsers(response.data.data); 
          setLoading(1);
        });
    }
    getSentMessages();
  },[userToken,update]);
  
	if(!localStorage.getItem('user')){
			navigate('/login',{state:{title:true,message:t('please_login_to_continue'),error:true}});
	}
  
  useEffect(()=>{
    if(state!==null){
      var title = state.title;
      if(title===true){
        if(state.error===true) toast.error(state.message);
        else toast.success(state.message);
        if(state.title) navigate(location.pathname, { replace: true });
      }
    }
	},[location,navigate,state,t]);


    return (
      <>
        {loading=== 0 && 
        <SkeletonTheme baseColor="#dfbb26" height="10px" highlightColor="#fff">
          <Skeleton />
        </SkeletonTheme>}
        <ToastContainer />
      	<div className="container mt-5">
          <h3 className="gtPageTitle">{t('message_sent')}</h3>
          <p className="gtPageSubTitle">{t('message_sent_description')}</p>
      		<div className="row">
    				<div className="col-md-4">
    					<MessageComponent />
    				</div>
    				<div className="col-md-8">
              {messagesentUsers.length===0 &&  <ResultNotFoundComponent data={width.size} />}
              {messagesentUsers.length > 0 && <ListmessageComponent data={messagesentUsers} update={update} setUpdate={setUpdate} />}
    				</div>
    			</div>
    			<div className="col-md-1"></div>
    		</div>
      </>	
   	)
}