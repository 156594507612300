import React, { useState,useEffect,useCallback } from 'react';
import { Link,useNavigate,useLocation } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import Constants from "../services/Constants";
import Axios from "axios";
import ListsearchuserComponenet from '../components/ListsearchuserComponenet'
import Skeleton , { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import ResultNotFoundComponent from '../components/ResultNotFoundComponent';

export default function SearchByIDComponent() {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const state  = location.state;
  let userGender = parseInt(localStorage.getItem('userGender'));
  let gender = (userGender!==null) ? (userGender===1 ? 2 : 1) : 0;
  const { handleSubmit } = useForm();
  let getData = state.data!==null ? state.data : null;
  const [getCaste,setgetCaste] = useState([]);
  const [getSubCaste,setgetSubCaste] = useState([]);
  const [getState,setgetState]  = useState([]);
  const [getCity,setgetCity]  = useState([]);
  const [multiCity,setMultiCity] = useState([]);
  const [inputErrors,setInputErrors] = useState(0);
  const [getReligionData,setgetReligion] = useState([]);
  const [getCountryData,setgetCountry] = useState([]);
  const [getOccupationData,setgetOccupation] = useState([]);
  const [getMotherToungueData,setgetMotherTongue] = useState([]);
  const [getEducationData,setgetEducation] = useState([]);
  const [width,setWidth] = useState({size:0,data:0});
  const [inputValue,setInputValues] = useState(getData!==null ? getData : { 
    matri_id: "",gender : "",asc_age :"",desc_age: "", asc_height:"",desc_height:"",marital_status : "",
    religion :"",caste : "",subcaste : "",country :"" ,dhosa :"", state :"",city : "", occupation : "",
    mother_tongue : "",education : ""});
  const [loading,setLoading] = useState(0);

    if(gender!=null && inputValue.gender!==gender){
        setInputValues({...inputValue,gender:gender});
    }

    if(window.innerWidth >= 481 && window.innerWidth <= 768 && width.size===0){
      setWidth({...width,size:1});
    }

    if(window.innerWidth >= 769 && window.innerWidth <= 1024 && width.size===0){
      setWidth({...width,size:2});
    }

    if(window.innerWidth >= 1050 && window.innerWidth <= 1400 && width.size===0){
      setWidth({...width,size:3});
    }

    useEffect(() => {
      const getSearchData = () => {
        if(getData!==null){
          if((getData.religion!==null && getData.religion!=="") && getCaste.length === 0){
            Axios.get(Constants.API_URL+"religion/"+getData.religion+"/0/0").then((response)=>{
              if(response.data.data.length > 0) setgetCaste(response.data.data);
              else {
                setgetCaste([]);
                setgetSubCaste([]);
              }
            });
          }

          if((getData.subcaste!==null && getData.subcaste!=="") && getSubCaste.length === 0){
              Axios.get(Constants.API_URL+"religion/"+getData.religion+"/"+getData.caste+"/0").then((response)=>{
               setgetSubCaste(response.data.data);
              });
          }

          if((getData.country!==null && getData.country!=="") && getState.length === 0){
              Axios.get(Constants.API_URL+"location/"+getData.country+"/0/0").then((response)=>{
               if(response.data.data.length > 0) setgetState(response.data.data);
              else {
                setgetState([]);
                setgetCity([]);
              }
              });
          }

          if((getData.state!==null && getData.state!=="") && getCity.length === 0){
              Axios.get(Constants.API_URL+"location/"+getData.country+"/"+getData.state+"/0").then((response)=>{
               setgetCity(response.data.data);
              });
          }
        }
      }
      getSearchData();
    },[getData,gender,inputValue,getCaste.length,getCity.length,getState.length,getSubCaste.length]);
      

    useEffect(() => {
      Axios.get(Constants.API_URL+"getdropdowndata").then((response)=>{
          if(response.data.data){
            setgetReligion(response.data.data.getReligion);
            setgetCountry(response.data.data.getCountry);
            setgetOccupation(response.data.data.getOccupation);
            setgetMotherTongue(response.data.data.getMotherToungue);
            setgetEducation(response.data.data.getEducation);
          }
        }).catch(errors => {
            console.log(errors);
        });
    },[])


    const setSubmit = () => {
      getData = inputValue;
      setInputErrors(1);
    }

    const onSubmit = (data) => {
      if(inputValue.asc_age!=="" && inputValue.desc_age!=="" && inputValue.marital_status!==""
        && inputValue.marital_status!=="" && inputValue.religion!=="" && inputValue.caste!==""
        && inputValue.mother_tongue!==""){
        setInputErrors(0);
        navigate('/searchbyid',{state:{data:inputValue}});
      }
    };

    function handleReligionChange(e) {
      if(e.value!==''){
        setInputValues({...inputValue,religion: e.value});
        Axios.get(Constants.API_URL+"religion/"+e.value+"/0/0").then((response)=>{
          if(response.data.data.length > 0) setgetCaste(response.data.data);
          else{
            setgetCaste([]);
            setgetSubCaste([]);
          }
        });
      }
    }


    function handleCasteChange(e) {
      if(e.value!==''){
        setInputValues({...inputValue,caste: e.value});
        Axios.get(Constants.API_URL+"religion/"+inputValue.religion+"/"+e.value+"/0").then((response)=>{
          setgetSubCaste(response.data.data);
        });
      }
    }

    function handleCountryChange(e) {
      if(e.value!==''){
        setInputValues({...inputValue,country: e.value});
        Axios.get(Constants.API_URL+"location/"+e.value+"/0/0").then((response)=>{
          if(response.data.data.length > 0) setgetState(response.data.data);
          else{
            setgetState([]);
            setgetCity([]);
          }
        });
      }
    }

    function handleStateChange(e) {
      if(e.value!==''){
        setInputValues({...inputValue,state: e.value});
        Axios.get(Constants.API_URL+"location/"+inputValue.country+"/"+e.value+"/0").then((response)=>{
            if(response.data.data.length > 0) setgetCity(response.data.data);
        });
      }
    }


    function handleCityChange(e){
      let value = "";
      setMultiCity(e);
      if(multiCity.length>0) value += e.map((e) => e.value); 
      setInputValues({...inputValue,city: value});
    }


    const handleMultiCountry = useCallback(() => {
      if (getCity.length > 0 && inputValue.hasOwnProperty('city')) {
        const updateCities = getCity.filter(city => inputValue.city.includes(city.value.toString()));
        setMultiCity(updateCities);
      }
    }, [inputValue, getCity]);




    useEffect(() => {
      handleMultiCountry();
    },[handleMultiCountry,inputValue.city]);


    return (
      <>
      {loading === 0 && 
      <SkeletonTheme baseColor="#dfbb26" height="10px" highlightColor="#fff">
        <Skeleton />
      </SkeletonTheme>}
      <div className="container mt-5">
        <div className="row">
          <div className="col-xl-4">
            <h3 className="gtPageTitle text-left ml-4">{t('search_results')}</h3>
            <p className="gtPageSubTitle text-left ml-4">{t('search_results_description')}</p>
          </div>
          <div className="col-xl-4 pt-3">
          </div>
          <div className="col-xl-4 text-right mt-2">
            <Link to="/search" className="btn btnBacktoSer">
              {t('modify_results')}
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div className="col-xl-12 col-md-12 col-12">
              <form className="mt-4" onSubmit={handleSubmit(onSubmit)}>             
                <div className="gtRefBox card">
                  <div className="gtRefBoxH">
                    <div className="row">
                      <div className="col-xl-7" data-toggle="collapse" data-target="#collapse1" aria-expanded="false" aria-controls="collapse1">
                        <h5>{t('age')}<span className="required">*</span></h5>
                      </div>
                      <div className="col-xl-5 text-right">
                      </div>
                    </div>
                  </div>
                  <div className="gtRefBoxB" id="collapse1">
                    <div className="row">
                      <div className="col-xl-6">
                        <div className="form-group">
                           <Select placeholder={t('select_any_one')} value={(getData!==null || inputValue.asc_age!==null) ? Constants.getAgeAsc.find(obj => obj.value === inputValue.asc_age) : ''} 
                           onChange={(e) => setInputValues({...inputValue,asc_age: e.value})} options = {Constants.getAgeAsc}>
                          </Select> 
                          {(inputErrors===1 && inputValue.asc_age==="") && <span className="error-msg text-left">{t('please_select_any_one')}</span>}                                                        
                        </div>
                      </div>
                      <div className="col-xl-6">
                        <div className="form-group">
                          <Select placeholder={t('select_any_one')} value={(getData!==null || inputValue.desc_age!==null) ? Constants.getAgeDesc.filter(obj => obj.value === inputValue.desc_age) : ''}  
                           onChange={(e) => setInputValues({...inputValue,desc_age: e.value})} options = {Constants.getAgeDesc} >
                          </Select>     
                          {(inputErrors===1 && inputValue.desc_age==="") && <span className="error-msg text-left">{t('please_select_any_one')}</span>}                                                              
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="gtRefBox card">
                  <div className="gtRefBoxH">
                    <div className="row">
                      <div className="col-xl-7" data-toggle="collapse" data-target="#collapse2" aria-expanded="false" aria-controls="collapse2">
                          <h5>{t('height')}</h5>
                      </div>
                    </div>
                  </div>
                  <div className="gtRefBoxB" id="collapse2">
                    <div className="row">
                      <div className="col-xl-6">
                        <div className="form-group">
                          <Select placeholder={t('select_any_one')} value={(getData!==null || inputValue.asc_height!==null) ? Constants.getHeightAsc.filter(obj => obj.value === inputValue.asc_height) : ''}   
                            onChange={(e) => setInputValues({...inputValue,asc_height: e.value})}  options = {Constants.getHeightAsc} >
                          </Select> 
                        </div>
                      </div>
                      <div className="col-xl-6">
                        <div className="form-group">
                          <Select placeholder={t('select_any_one')} value={(getData!==null || inputValue.desc_height!==null) ? Constants.getHeightDesc.filter(obj => obj.value === inputValue.desc_height) : ''}   
                            onChange={(e) => setInputValues({...inputValue,desc_height: e.value})}  options = {Constants.getHeightDesc} >
                          </Select>                                      
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="gtRefBox card">
                  <div className="gtRefBoxH">
                    <div className="row">
                      <div className="col-xl-7" data-toggle="collapse" data-target="#collapse4" aria-expanded="false" aria-controls="collapse4">
                        <h5>{t('martial_status')}<span className="required">*</span></h5>
                      </div>
                    </div>
                  </div>
                  <div className="gtRefBoxB" id="collapse4">
                    <Select placeholder={t('select_any_one')} value={(getData!==null || inputValue.marital_status!==null) ? Constants.getMartialStatus.filter(obj => obj.value === inputValue.marital_status) : ''}   
                    onChange={(e) => setInputValues({...inputValue,marital_status: e.value})}  options = {Constants.getMartialStatus}  >
                      </Select> 
                      {(inputErrors===1 && inputValue.marital_status==="") && <span className="error-msg text-left">{t('martialstatus_is_required')}</span>}                                                                      
                  </div>
                </div>
                <div className="gtRefBox card">
                  <div className="gtRefBoxH">
                    <div className="row">
                      <div className="col-xl-7" data-toggle="collapse" data-target="#collapse5" aria-expanded="false" aria-controls="collapse5">
                        <h5>{t('religion')}<span className="required">*</span></h5>
                      </div>
                    </div>
                  </div>
                  <div className="gtRefBoxB" id="collapse5">
                    <Select placeholder={t('select_any_one')} value={((getData!==null || inputValue.religion!==null) && getReligionData!==null) ? getReligionData.filter(obj => obj.value === inputValue.religion) : ''}   
                    onChange={(e) => handleReligionChange(e)}  options = {getReligionData} >
                      </Select>                 
                      {(inputErrors===1 && inputValue.religion==="") && <span className="error-msg text-left">{t('religion_is_required')}</span>}                                                     
                  </div>
                </div>
                <div className="gtRefBox card" id="getcaste">
                  <div id="users_caste">
                    <div className="gtRefBoxH">
                      <div className="row">
                        <div className="col-xl-7" data-toggle="collapse" data-target="#collapse6" aria-expanded="false" aria-controls="collapse6">
                          <h5>{t('caste')}<span className="required">*</span></h5>
                        </div>
                      </div>
                    </div>
                    <div className="gtRefBoxB " id="collapse6">
                      <Select placeholder={t('select_any_one')} value={((getData!==null || inputValue.caste!==null) && getCaste!==null) ? getCaste.filter(obj => obj.value === inputValue.caste) : ''}   
                        onChange={(e) => handleCasteChange(e)}   options = {getCaste} >
                    </Select>                               
                    {(inputErrors===1 && inputValue.caste==="") && <span className="error-msg text-left">{t('caste_cannot_be_blank')}</span>}                                                                    
                    </div>
                  </div>
                </div>
                <div className="gtRefBox card" id="getcaste">
                  <div id="users_caste">
                    <div className="gtRefBoxH">
                      <div className="row">
                        <div className="col-xl-7" data-toggle="collapse" data-target="#collapse6" aria-expanded="false" aria-controls="collapse6">
                          <h5>{t('sub_caste')}</h5>
                        </div>
                      </div>
                    </div>
                    <div className="gtRefBoxB " id="collapse6">
                      <Select placeholder={t('select_any_one')} value={((getData!==null || inputValue.subcaste!==null) && getSubCaste!==null)  ? getSubCaste.filter(obj => obj.value === inputValue.subcaste) : ''}   
                       onChange={(e) => setInputValues({...inputValue,subcaste: e.value})}  options = {getSubCaste} >
                      </Select>                          
                    </div>
                  </div>
                </div>
                <div className="gtRefBox card">
                  <div className="gtRefBoxH">
                    <div className="row">
                      <div className="col-xl-7" data-toggle="collapse" data-target="#collapse7" aria-expanded="false" aria-controls="collapse7">
                        <h5>{t('country')}</h5>
                      </div>
                    </div>
                  </div>
                  <div className="gtRefBoxB" id="collapse7">
                    <Select placeholder={t('select_any_one')} value={((getData!==null || inputValue.country!==null) && getCountryData!==null) ? getCountryData.filter(obj => obj.value === inputValue.country) : ''}   
                     onChange={(e) => handleCountryChange(e)} options = {getCountryData} >
                    </Select>                          
                  </div>
                </div>
                <div className="gtRefBox card" id="getstate">
                  <div id="users_state">
                    <div className="gtRefBoxH">
                      <div className="row">
                        <div className="col-xl-7" data-toggle="collapse" data-target="#collapse8" aria-expanded="false" aria-controls="collapse8">
                          <h5>{t('state')}</h5>
                        </div>
                      </div>
                    </div>
                    <div className="gtRefBoxB " id="collapse8">
                      <Select placeholder={t('select_any_one')} value={((getData!==null || inputValue.state!==null) && getState!==null) ? getState.filter(obj => obj.value === inputValue.state) : ''}   
                      onChange={(e) => handleStateChange(e)} options = {getState} >
                    </Select>                                
                    </div>
                  </div>
                </div>
                <div className="gtRefBox card">
                  <div className="gtRefBoxH">
                    <div className="row">
                      <div className="col-xl-7" data-toggle="collapse" data-target="#collapse9" aria-expanded="false" aria-controls="collapse9">
                        <h5>{t('city')}</h5>
                      </div>
                    </div>
                  </div>
                  <div className="gtRefBoxB" id="collapse9">
                    <Select isMulti 
                    isClearable={true}  
                    placeholder={t('select_any_one')} 
                    onChange={(e) => handleCityChange(e)} 
                    value={multiCity} 
                     options={getCity} >
                    </Select>                            
                  </div>
                </div>
                <div className="gtRefBox card">
                  <div className="gtRefBoxH">
                    <div className="row">
                      <div className="col-xl-7" data-toggle="collapse" data-target="#collapse9" aria-expanded="false" aria-controls="collapse9">
                        <h5>{t('mother_toungue')}<span className="required">*</span></h5>
                      </div>
                    </div>
                  </div>
                  <div className="gtRefBoxB" id="collapse9">
                    <Select placeholder={t('select_any_one')} value={((getData!==null || inputValue.mother_tongue!==null) && getMotherToungueData!==null)  ? getMotherToungueData.filter(obj => obj.value === inputValue.mother_tongue) : ''}   
                    onChange={(e) => setInputValues({...inputValue, mother_tongue : e.value})}
                     options={getMotherToungueData} >
                    </Select>
                    {(inputErrors===1 && inputValue.mother_tongue==="") && <span className="error-msg text-left">{t('mother_toungue_cannot_be_blank')}</span>}                                                                                                
                  </div>
                </div>
                <div className="gtRefBox card">
                  <div className="gtRefBoxH">
                    <div className="row">
                      <div className="col-xl-7" data-toggle="collapse" data-target="#collapse10" aria-expanded="false" aria-controls="collapse10">
                        <h5>{t('education')}</h5>
                      </div>
                    </div>
                  </div>
                  <div className="gtRefBoxB " id="collapse10">
                     <Select placeholder={t('select_any_one')} value={((getData!==null || inputValue.education!==null) && getEducationData!==null) ? getEducationData.filter(obj => obj.value === inputValue.education) : ''}   
                      onChange={(e) => setInputValues({...inputValue, education : e.value})}
                      options={getEducationData} >
                    </Select>                            
                  </div>
                </div>
                <div className="gtRefBox card">
                  <div className="gtRefBoxH">
                    <div className="row">
                      <div className="col-xl-7" data-toggle="collapse" data-target="#collapse11" aria-expanded="false" aria-controls="collapse11">
                          <h5>{t('occupation')}</h5>
                      </div>
                    </div>
                  </div>
                  <div className="gtRefBoxB " id="collapse11">
                    <Select placeholder={t('select_any_one')}  value={((getData!==null || inputValue.occupation!==null) && getOccupationData!==null) ? getOccupationData.filter(obj => obj.value === inputValue.occupation) : ''}    
                     onChange={(e) => setInputValues({...inputValue, occupation : e.value})} 
                     options={getOccupationData} >
                    </Select>                             
                  </div>
                </div>
                <div className="gtRefBoxB">
                  <div className="col-xl-7">
                    <input onClick={setSubmit} className="btn btnRegFsubmit" type="submit" value={t('search')} />                    
                  </div>
                </div>
              </form>                
            </div>
          </div>
          <div className="col-md-8 mt-4">
            {(getData!==null || state.searchid!==null) &&
              <ListsearchuserComponenet search={getData} page={8} searchid={state.searchid} loading={loading} setLoading={setLoading} />
            }
            {(getData===null || state.searchid===null) &&
              <ResultNotFoundComponent  data={width.size} />
            }
          </div>
        </div>
      </div>
     </>
  )
}