import { Link,useNavigate,useLocation } from "react-router-dom"
import { useEffect,useState } from "react";
import Axios from "axios"
import { useTranslation } from 'react-i18next';
import Constants from "../services/Constants"; 
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import ReactPaginate from 'react-paginate';
import ResultNotFoundComponent from '../components/ResultNotFoundComponent';

export default function ListUserComponent({data,update,setUpdate}){
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 10;
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const [users,setUsers] = useState([]);
  const [copy,setCopy] = useState({copied: false});
  let userToken = JSON.parse(localStorage.getItem('userToken'));
  let userPaid = parseInt(localStorage.getItem('userPaid'));
  let userGender = localStorage.getItem('userGender');
  let userTransaction  = parseInt(localStorage.getItem('userTransaction'));


  useEffect(() => {
    setUsers(data);
  },[data]) 

  const updatePage = () => {
    setTimeout(() => setUpdate(update + 1),5000);
  }


  const requestSent = (e) => {
    var gender = e.target.getAttribute("data-gender");
    if(gender===userGender){
      navigate(location.pathname,{state:{title:true,error:true,message:t('access_is_denied')}});
    }else{
      var id = e.target.getAttribute("data-id");
      var paid = parseInt(e.target.getAttribute("data-paid"));
      if(paid===0){
        if(userTransaction!==0){
          toast.error(t('please_contact_support'));
        }else{
          if(userTransaction===0){
            toast.error(t('please_add_a_membership'));
          }else{
            toast.error(t('please_login_to_continue'));
          }
        }
      }else{
        if(userToken!==null){
          Axios.post(Constants.API_URL+"addsendinterest",{
            api_token : userToken, 
            profile_id : id
          }).then((response)=>{
            if(response.data.error===true) toast.error(response.data.message);
            else toast.success(response.data.message);
            updatePage();
          });
        }else{
          toast.error(t('please_login_to_continue'));
        }
      }
    }
  }

  if(copy.copied===true){
      toast.success(t('link_copied_successfully'));
      setCopy(previousState => { return {...previousState,copied:false}});
  }


  const addshortlist = (userID,add) => {
    if(userID && userToken!==null){
      var url;
      if(add===1) url = "addshortlist";
      else url = "removeshortlist";
        Axios.post(Constants.API_URL+url,{
          api_token : userToken, 
          profile_id : userID
        }).then((response)=>{
          if(response.data.error===true) toast.error(response.data.message);
          else toast.success(response.data.message);
          updatePage();
        });
    }else{
      toast.error(t('please_login_to_continue'));
    }
  }

  const addblocklist = (userID,add) => {
    if(userID && userToken!==null){
      var url;
      if(add===1) url = "addblocklist";
      else url = "removeblocklist";
        Axios.post(Constants.API_URL+url,{
          api_token : userToken, 
          profile_id : userID
        }).then((response)=>{
          if(response.data.error===true){
            toast.error(response.data.message);
          }else{
            toast.success(response.data.message);
          }
          updatePage();
        });
    }else{
      toast.error(t('please_login_to_continue'));
    }
  }

  useEffect(() => {
    if(users.length > 0){
      const endOffset = itemOffset + itemsPerPage;
      setCurrentItems(users.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(users.length / itemsPerPage));
    }
  }, [itemOffset, itemsPerPage,users]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % users.length;
    setItemOffset(newOffset);
  };
  
  return (
    <div className="col-md-10 offset-md-1">
      <ToastContainer/>
        <div className="gtMainResult mb-3">
          {currentItems===null &&  <ResultNotFoundComponent />}
          {(currentItems!==null && currentItems.length > 0) && currentItems.map((user,index) => (
            <div className="row bor-card" key={user.id}>
              <div className="col-xl-3 col-12 col-md-6 col-lg-4" style={{'paddingLeft':'0px'}}>
                {(((user.photoprivacy===2 && userPaid===1) || (user.photoprivacy===1 && userToken!==null)) && user.photoprivacy!==0) && 
                  <img alt={user.name} src={user.image!=="" ? Constants.IMAGE_URL+user.image : (user.gender===1 ? require('../assets/images/no_image_groom.png') : require('../assets/images/no_image_bride.png'))} 
                  className="img-fluid img-shadow" />
                }
                {((user.photoprivacy===0 && userToken!==null) || (user.photoprivacy===2 && userPaid===0)) && 
                  <img alt={user.name} src={user.gender===1 ? require('../assets/images/no_image_groom.png') : require('../assets/images/no_image_bride.png')} 
                  className="img-fluid img-shadow" />
                }
              </div>
              <div className="col-xl-7 col-12 col-md-6 col-lg-5 pt-2">
                <div className="w-100 gtMainResFir gtXSP15 gtSMP15">
                  {(userPaid===0 || userToken===null || userTransaction===0) && 
                    <Link to={userToken!==null ? "/memberplan" : "/login"}
                    state={{title:true,error:true,message:(userPaid===0 ? ((userTransaction!==0) ? t('please_contact_support') : t('please_add_a_membership')) : t('please_login_to_continue'))}}>
                      <h3>{user.name}</h3>
                      <h5>{user.matri_id}</h5>
                    </Link>
                  }
                  {(userPaid===1 && userToken!==null) && 
                     <Link to={`/viewprofile/${user.id}`}>
                       <h3>{user.name}</h3>
                       <h5>{user.matri_id}</h5>
                    </Link>
                  }
                </div>
                <div className="w-100 gtMainResSec gtXSP15 gtSMP15">
                  <div className="row mb5">
                    <div className="col-5 gtResTag">{t('age')} / {t('height')} </div>
                    <div className="col-7 gtResDet">{user.age} / {user.height}</div>
                  </div>
                  <div className="row mb5">
                    <div className="col-5 gtResTag">{t('religion')}</div>
                    <div className="col-7 gtResDet">{user.religion}</div>
                  </div>
                  <div className="row mb5">
                    <div className="col-5 gtResTag">{t('caste')}</div>
                    <div className="col-7 gtResDet">{user.caste}</div>
                  </div>
                  <div className="row mb5">
                    <div className="col-5 gtResTag">{t('country')}</div>
                    <div className="col-7 gtResDet">{user.country}</div>
                  </div>
                  <div className="row mb5">
                    <div className="col-5 gtResTag">{t('martial_status')}</div>
                    <div className="col-7 gtResDet">{user.marital_status}</div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-xl-2 col-md-12 col-lg-3 gtMainResAct" style={{'paddingRight':'0px','paddingLeft':'0px'}}>
                {user.is_already_interest===0  && <button onClick={(e) => requestSent(e)} data-gender={user.gender}  data-id={user.id} data-paid={user.user_paidmember} className="btn" style={{'width':'100%'}}> <i className="fas fa-heart mr-2"></i>{t('send_interest')}</button>}
                {(user.is_already_interest===1 && user.is_already_status===0) && <button onClick={(e) => requestSent(e)} data-gender={user.gender} data-status={user.is_already_status} data-id={user.id} data-paid={user.user_paidmember} className="btn" style={{'width':'100%'}}><i className="fas fa-heart mr-2"></i>{t('request_sent')}</button>}
                {(user.is_already_interest===1 && (user.is_already_status===1 || user.is_already_status===2)) && <button className="btn" style={{'width':'100%','textAlign':'left'}}><Link to={`/composemessage/${user.id}`}  data-status={user.is_already_status} data-id={user.id} data-paid={user.user_paidmember} style={{'color':'#000'}}><i className="fas fa-envelope mr-2"></i>{t('send_message')}</Link></button>}
                <CopyToClipboard text={Constants.APP_URL+"viewprofile/"+user.id}  onCopy={() => setCopy(previousState => {return {...copy,copied: true}})} >
                <button className="btn"  style={{'width':'100%'}}><i className="fas fa-clone mr-2"></i>{t('copy_link')}</button>
                 </CopyToClipboard>
                  {user.is_already_shortlist===0 && <button onClick={(e) => addshortlist(user.id,1)} className="btn"  style={{'width':'100%'}}><i className="fas fa-sort mr-2"></i>{t('add_shortlist')}</button>}
                  {user.is_already_shortlist===1 && <button onClick={(e) => addshortlist(user.id,0)} className="btn"  style={{'width':'100%'}}><i className="fas fa-sort mr-2"></i>{t('remove_shortlist')}</button>}
                <span>
                  {user.is_already_block===0 && <button onClick={(e) => addblocklist(user.id,1)} className="btn"  style={{'width':'100%'}} ><i className="fas fa-ban mr-2"></i>{t('block')}</button>}
                  {user.is_already_block===1 && <button onClick={(e) => addblocklist(user.id,0)} className="btn"  style={{'width':'100%'}}><i className="fas fa-ban mr-2"></i>{t('un_block')}</button>}
                </span>
              </div>
            </div>
          ))}
        </div>
        {users.length > 9 &&
          <div style={{'float':'right'}}>
            <ReactPaginate
            previousLabel={"<< Previous"}
            nextLabel={"Next >>"}
            pageCount={pageCount}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            previousLinkClassName={"pagination__link"}
            nextLinkClassName={"pagination__link"}
            disabledClassName={"pagination__link--disabled"}
            activeClassName={"pagination__link--active"}
          />
          </div>
        }
      </div>
  )
}



