import React, { useEffect } from 'react'
import { useForm,Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Axios from "axios"
import Select from 'react-select';
import Constants from "../services/Constants";
import useEducation from "../hook/useEducation";
import useOccupation from "../hook/useOccupation";
import $ from "jquery";

export default function UserPartnerProInfoComponent({userBasicInfo,issubmit,setIsSubmit}){
  let userToken = JSON.parse(localStorage.getItem('userToken'));
  const { t } = useTranslation();
  const [getEducationData] = useEducation("education");
  const [getOccupationData] = useOccupation("occupation");
  const { register,handleSubmit,resetField,getValues ,setValue, control} = useForm({mode : "onChange",defaultValue:{
    partner_education:"",partner_occupation:"",partner_employee:""
  }});

  const onBasicSubmit = (data) => {
    Axios.post(Constants.API_URL+"postmyprofile",{
        api_token : userToken,partner : 1,partner_education:data.partner_education,
        partner_occupation : data.partner_occupation,partner_employee : data.partner_employee
      }).then((response)=>{
        setIsSubmit('');
        setIsSubmit(response.data.error+'/'+response.data.message);
        $(".closePartnerProfModal").click();
      }).catch((err) => {
         console.log(err);
      })
  }

  useEffect(() => {
    if(userBasicInfo!=null){
      setValue('partner_education',userBasicInfo.partner_education); 
      setValue('partner_occupation',userBasicInfo.partner_occupation); 
      setValue('partner_employee',String(userBasicInfo.partner_employee));
    }
  },[userBasicInfo,setValue]);

  const handleEmployeeChange = (val) => {
    resetField('partner_employee');
    setValue('partner_employee',val);
  }


	return (
		<div className="modal fade gtEditForm" id="professionalPreferencesModal" tabIndex="-1" role="dialog" aria-labelledby="professionalPreferencesModal" aria-hidden="true" data-backdrop="static">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header text-center">
            <div className="col-12">
              <h5 className="modal-title" id="exampleModalLabel">Professional Preferences
              <button type="button" className="close closePartnerProfModal" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
              </button>
              </h5>
            </div>
          </div>
          <div className="modal-body">
            <div className="row">
              <form className="form-horizontal" onSubmit={handleSubmit(onBasicSubmit)}>  
                <div className="container">
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="form-group">
                        <label htmlFor="Userdetails_partner_education">Education</label>             
                        {getEducationData && (
                            <Controller
                             name="partner_education"
                             control={control}
                             render={({ field: { onChange, value, name, ref } }) => (
                               <Select
                                inputRef={ref}
                                placeholder ={t('select_any_one')}
                                className="mb-4"
                                value={getEducationData.find((c) => c.value === value) || ''}
                                options={getEducationData}
                                defaultValue={Constants.prompt}
                                onChange={(val: ICategory) => {
                                 onChange(val.value);
                                }} 
                               />
                             )} 
                          />)}                              
                      </div>
                    </div>
                    <div className="col-xl-12">
                      <div className="form-group">
                        <label htmlFor="Userdetails_partner_occupation">Occupation</label>             
                         {getOccupationData && (
                            <Controller
                             name="partner_occupation"
                             control={control}
                             render={({ field: { onChange, value, name, ref } }) => (
                               <Select
                                inputRef={ref}
                                placeholder ={t('select_any_one')}
                                className="mb-4"
                                value={getOccupationData.find((c) => c.value === value) || ''}
                                options={getOccupationData}
                                defaultValue={Constants.prompt}
                                onChange={(val: ICategory) => {
                                 onChange(val.value);
                                }} 
                               />
                             )} 
                          />)}              
                      </div>
                    </div>
                    <div className="col-xl-12">
                      <div className="form-group">
                        <label htmlFor="Userdetails_partner_employee">Employeed In</label>              
                        <div className="row">
                          <div className="col-12"> 
                              <span id="Userdetails_partner_employee">
                                <input type="radio" value="1" onClick={(e) => handleEmployeeChange(e.target.value)}
                                checked={getValues('partner_employee') === "1"}
                                {...register('partner_employee')}
                                />&nbsp; 
                                <label htmlFor="Userdetails_partner_employee_0">Government</label>
                                <br/>
                                <input type="radio" value="2" onClick={(e) => handleEmployeeChange(e.target.value)}
                                checked={getValues('partner_employee') === "2"}
                                {...register('partner_employee')}
                                />&nbsp; 
                                <label htmlFor="Userdetails_partner_employee_1">Private</label>
                                <br/>
                                <input type="radio" value="3" onClick={(e) => handleEmployeeChange(e.target.value)}
                                checked={getValues('partner_employee') === "3"}
                                {...register('partner_employee')}
                                />&nbsp; 
                                <label htmlFor="Userdetails_partner_employee_2">Business
                                </label>
                                <br/>
                                <input type="radio" value="4" onClick={(e) => handleEmployeeChange(e.target.value)}
                                checked={getValues('partner_employee') === "4"}
                                {...register('partner_employee')}
                                />&nbsp; 
                                <label htmlFor="Userdetails_partner_employee_3">Defence</label>
                                <br/>
                                <input type="radio" value="5" onClick={(e) => handleEmployeeChange(e.target.value)}
                                checked={getValues('partner_employee') === "5"}
                                {...register('partner_employee')}
                                />&nbsp; 
                                <label htmlFor="Userdetails_partner_employee_4">
                                Self Employee</label>
                                <br/>
                                <input type="radio" value="6" onClick={(e) => handleEmployeeChange(e.target.value)}
                                checked={getValues('partner_employee') === "6"}
                                {...register('partner_employee')}
                                />&nbsp;
                                <label htmlFor="Userdetails_partner_employee_5">Not Working</label>
                              </span>                            
                            </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-12">
                      <div className="form-group text-center">
                        <input className="btn btnRegFsubmit" type="submit" value="Update" />           
                      </div>
                    </div>
                  </div>
                </div>
              </form>        
            </div>
          </div>
        </div>
      </div>
    </div>
    )
}