import { useParams,useNavigate } from "react-router-dom";
import { useEffect,useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';
import Axios from "axios" 
import Constants from "../services/Constants";   
import $ from 'jquery'

export default function ViewProfile(props) {
  let { userId } = useParams();
   const navigate = useNavigate();
  let userToken = JSON.parse(localStorage.getItem('userToken'));
  let userViewedContact= localStorage.getItem('userViewedContact');
  let userContactCount= localStorage.getItem('userContactCount');
  const { t } = useTranslation();
  const [popup,setPopup] = useState(false);
  const [user, setUser] = useState({id : "",matri_id :"", fname :"", lname : "", email :"", mobile_no:"",phone : "",gender : "",dob :"", 
  marital_status :"",profile_created_by:"",toungue:"",age:"",height:"",physical_status:"",complexion_status :"",body_type:"",weight:"",
  eating_habits:"",drinking_habits:"",smoking_habits :"",child:"",child_status :"",religion:"",caste:"",subcaste:"",paidmember:"",
  country:"",state:"",city:"",image:"",gallery:"",education:"",additional :"",occupation:"",employee:"",income:"",about:"",
  shortlist :"",blocklist:"",horoscope_pic:false}); 


  useEffect(() => { 
    Axios.post(Constants.API_URL+"profiledetailpage",{
      api_token : userToken,profile_id : userId
    }).then((response)=>{
      setUser(response.data.data);  
    });
    
    Axios.post(Constants.API_URL+"addviewedprofiles",{
      api_token : userToken,profile_id : userId
    }).then((response)=>{
    });
  },[userToken,userId]);

  const addDefaultSrc = (e,gender) => {
  	e.target.src = Constants.IMAGE_URL+"no_image_groom.png";
  	if(gender==="Female"){
		  e.target.src = Constants.IMAGE_URL+"no_image_bride.png";
  	}
	}

	const getHoroscopeDownload = () => {
  	Axios({
		  url: Constants.API_URL+"horoscoprofiledownload/"+userToken+"/"+userId,
		  method: 'GET',
		  responseType: 'blob', 
		}).then((response) => {
			if(typeof(response)==="object"){
				if(response.data.size===1){
					toast.error(t('file_not_exist'));
				}else{
			   	const url = window.URL.createObjectURL(new Blob([response.data]));
				 	const ext = response.data.type.split('/');
			   	const link = document.createElement('a');
			   	link.href = url;
			   	link.setAttribute('download',user.matri_id+'.'+ext[1]); 
			   	document.body.appendChild(link);
			   	link.click();
				}
			}
		});
  }

  
  const showDetail = (value) => {
    if(value){ 
	  	Axios.post(Constants.API_URL+"showmobileno",{
	      api_token : userToken,
	      profile_id : userId
	    }).then((response)=>{
			if(response.data.error===false){
				  setPopup(true);
				  toast.success(response.data.message);
			}else{
        if(response.data.error){
        console.log('error',response.data.error);
        toast.error(response.data.message);
        }
      }
	    });
  }else{
	setPopup(false);
  }
	}

  useEffect(() => {
  },[popup]);

	if(popup===0){
		$("#collapse").removeClass("show");
		var element = document.getElementById("collapse");
      if(element!==null)element.classList.remove("show");
	}

  const printProfile = () => {
    navigate('/printprofile',{state:{data:user}});
  }

	
  return (
    	<div>
    		<ToastContainer />
    	  <div className="container mt-5">
            <div className="mt-4 mb-4 ml-2 row">
    	       <h3 className="gtPageTitletext-left col-6">{user.fname  +  user.lname}<small>({user.matri_id})</small></h3>
              <div className="col-6 text-right">
              <button className="btn btn-green d-none">Download as PDF</button>&nbsp;
              <button className="btn btn-green"  onClick={printProfile}>Print</button>
              </div>
          </div>
    	    <div className="row">
				    <div className="col-xl-10 col-12">
				      <div className="gtProBoxT card">
				        <div className="gtProBoxTBody gtXSP15">
				          <div className="row">
				            <div className="col-xl-3 col-sm-4 photo">
						          {user.gender==="Male" && <img alt={user.matri_id} onError={(e) => addDefaultSrc(e,user.gender)} src={user.image!=="" ? Constants.IMAGE_URL+"pictures/"+user.image : require('../assets/images/no_image_groom.png')} className="img-fluid img-shadow" style={{'height':'190px!important'}} />}
		  			          {user.gender==="Female" && <img alt={user.matri_id} onError={(e) => addDefaultSrc(e,user.gender)} src={user.image!=="" ? Constants.IMAGE_URL+"pictures/"+user.image : require('../assets/images/no_image_bride.png')} className="img-fluid img-shadow" style={{'height':'190px!important'}} />}
				            </div>
				            <div className="col-xl-9 col-sm-8 pt-4">
				              <div className="row">
				                <div className="col-xl-6 mb-3">
				                  <div className="row">
				                    <div className="col-5 gtProTTag">{t('name')}</div>
				                    <div className="col-7 gtProTDet">{user.fname  +  user.lname}</div>
				                  </div>
				                </div>
				                <div className="col-xl-6 mb-3">
				                  <div className="row">
				                    <div className="col-5 gtProTTag">{t('mobile_no')}</div>
				                    <div className="col-7 gtProTDet">{user.mobile_no}</div>
				                  </div>
				                </div>
				              </div>
				              <div className="row">
				                <div className="col-xl-6 mb-3">
				                  <div className="row">
				                    <div className="col-5 gtProTTag">{t('email')}</div>
				                    <div className="col-7 gtProTDet">{user.email}</div>
				                  </div>
				                </div>
				                <div className="col-xl-6 mb-3">
				                  <div className="row">
				                    <div className="col-5 gtProTTag">{t('gender')}</div>
				                    <div className="col-7 gtProTDet">{user.gender}</div>
				                  </div>
				                </div>
				              </div>
				              <div className="row">
				                <div className="col-xl-6 mb-3">
				                  <div className="row">
				                    <div className="col-5 gtProTTag">{t('date_of_birth')}</div>
				                    <div className="col-7 gtProTDet">{user.dob}</div>
				                  </div>
				                </div>
				                <div className="col-xl-6 mb-3">
				                  <div className="row">
				                    <div className="col-5 gtProTTag">{t('martial_status')}</div>
				                    <div className="col-7 gtProTDet">{user.marital_status}</div>
				                  </div>
				                </div>
				              </div>
				              <div className="row">
				                <div className="col-xl-6 mb-3">
				                  <div className="row">
				                    <div className="col-5 gtProTTag">{t('profile_created_for')} </div>
				                    <div className="col-7 gtProTDet">{user.profile_created_by}</div>
				                  </div>
				                </div>
				                <div className="col-xl-6 mb-3">
				                  <div className="row">
				                    <div className="col-5 gtProTTag">{t('mother_toungue')}</div>
				                    <div className="col-7 gtProTDet">{user.toungue}</div>
				                  </div>
				                </div>
				              </div>
				            </div>
				          </div>
				        </div>
				      </div>
				      <h3 className="gtPageTitle text-left mt-4 ml-2">{t('personal_information')}</h3>
	      			<p className="gtPageSubTitle text-left mb-4  ml-2">{t('you_will_find')}</p>
		  			  <div className="gtProBox card mb-3">
				        <div className="gtProBoxHead">
				          <div className="row">
				            <div className="col-5"><h4>{t('basic_information')}</h4></div>
				          </div>
				        </div>
				        <div className="gtProBoxBody">
				          <div className="row">
				            <div className="col-xl-6 col-lg-6 mb-3">
				              <div className="row">
				                <div className="col-5 gtProTxt">{t('profile_created_for')}</div>
				                <div className="col-7 gtProTxtDet">{user.profile_created_by}</div>
				              </div>
				            </div>
				            <div className="col-xl-6 col-lg-6 mb-3">
				              <div className="row">
				                <div className="col-5 gtProTxt">{t('name')}</div>
				                <div className="col-7 gtProTxtDet">{user.fname} {user.lname}</div>
				              </div>
				            </div>
				          </div>
				          <div className="row">
				            <div className="col-xl-6 col-lg-6 mb-3">
				              <div className="row">
				                <div className="col-5 gtProTxt">{t('age')}</div>
				                <div className="col-7 gtProTxtDet">{user.age}</div>
				              </div>
				            </div>
				            <div className="col-xl-6 col-lg-6 mb-3">
				              <div className="row">
				                <div className="col-5 gtProTxt">{t('height')}</div>
				                <div className="col-7 gtProTxtDet">{user.height}</div>
				              </div>
				            </div>
				          </div>
				          <div className="row">
				            <div className="col-xl-6 col-lg-6 mb-3">
				              <div className="row">
				                <div className="col-5 gtProTxt">{t('physical_status')}</div>
				                <div className="col-7 gtProTxtDet">{user.physical_status}</div>
				              </div>
				            </div>
				            <div className="col-xl-6 col-lg-6 mb-3">
				              <div className="row">
				                <div className="col-5 gtProTxt">{t('complexion')}</div>
				                <div className="col-7 gtProTxtDet">{user.complexion_status}</div>
				              </div>
				            </div>
				          </div>
				          <div className="row">
				            <div className="col-xl-6 col-lg-6 mb-3">
				              <div className="row">
				                <div className="col-5 gtProTxt">{t('body_type')}</div>
				                <div className="col-7 gtProTxtDet">{user.body_type}</div>
				              </div>
				            </div>
				            <div className="col-xl-6 col-lg-6 mb-3">
				              <div className="row">
				                <div className="col-5 gtProTxt">{t('martial_status')}</div>
				                <div className="col-7 gtProTxtDet">{user.marital_status}</div>
				              </div>
				            </div>
				          </div>
				          <div className="row">
				            <div className="col-xl-6 col-lg-6 mb-3">
				              <div className="row">
				                <div className="col-5 gtProTxt">{t('weight')}</div>
				                <div className="col-7 gtProTxtDet">{user.weight}</div>
				              </div>
				            </div>
				            <div className="col-xl-6 col-lg-6 mb-3">
				              <div className="row">
				                <div className="col-5 gtProTxt">{t('mother_toungue')}</div>
				                <div className="col-7 gtProTxtDet">{user.toungue}</div>
				              </div>
				            </div>
				          </div>
				          <div className="row">
				            <div className="col-xl-6 col-lg-6 mb-3">
				              <div className="row">
				                <div className="col-5 gtProTxt">{t('eating_habits')}</div>
				                <div className="col-7 gtProTxtDet">{user.eating_habits}</div>
				              </div>
				            </div>
				            <div className="col-xl-6 col-lg-6 mb-3">
				              <div className="row">
				                <div className="col-5 gtProTxt">{t('drinking_habits')}</div>
				                <div className="col-7 gtProTxtDet">{user.drinking_habits}</div>
				              </div>
				            </div>
				          </div>
				          <div className="row">
				            <div className="col-xl-6 col-lg-6 mb-3">
				              <div className="row">
				                <div className="col-5 gtProTxt">{t('smoking_habits')}</div>
				                <div className="col-7 gtProTxtDet">{user.smoking_habits}</div>
				              </div>
				            </div>
				            <div className="col-xl-6 col-lg-6 mb-3">
				              <div className="row">
				                <div className="col-5 gtProTxt">{t('no_of_children')}</div>
				                <div className="col-7 gtProTxtDet">{user.child}</div>
				              </div>
				            </div>
				          </div>
                  <div className="row">
                    <div className="col-xl-6 col-lg-6 mb-3">
                      <div className="row">
                        <div className="col-5 gtProTxt">{t('children_living_status')}</div>
                        <div className="col-7 gtProTxtDet">{user.child_status}</div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 mb-3">
                      <div className="row">
                        <div className="col-5 gtProTxt">{t('address')}</div>
                        <div className="col-7 gtProTxtDet">{user.ud_address}</div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-6 col-lg-6 mb-3">
                      <div className="row">
                        <div className="col-5 gtProTxt">{t('blood_group')}</div>
                        <div className="col-7 gtProTxtDet">{user.ud_blood_group}</div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 mb-3">
                      <div className="row">
                        <div className="col-5 gtProTxt">{t('properties')}</div>
                        <div className="col-7 gtProTxtDet">{user.ud_properties}</div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-6 col-lg-6 mb-3">
                      <div className="row">
                        <div className="col-5 gtProTxt">{t('sisters')}</div>
                        <div className="col-7 gtProTxtDet">{user.ud_sis}</div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 mb-3">
                      <div className="row">
                        <div className="col-5 gtProTxt">{t('married_sis')}</div>
                        <div className="col-7 gtProTxtDet">{user.ud_married_sis}</div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-6 col-lg-6 mb-3">
                      <div className="row">
                        <div className="col-5 gtProTxt">{t('brothers')}</div>
                        <div className="col-7 gtProTxtDet">{user.ud_bro}</div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 mb-3">
                      <div className="row">
                        <div className="col-5 gtProTxt">{t('married_brothers')}</div>
                        <div className="col-7 gtProTxtDet">{user.ud_married_bro}</div>
                      </div>
                    </div>
                  </div>
				        </div>
							</div>
							<div className="mt-4"></div>
				      <div className="gtProBox card mb-3">
				        <div className="gtProBoxHead">
				          <div className="row">
				            <div className="col-7"><h4>Religion Information</h4></div>
				          </div>
				        </div>
				        <div className="gtProBoxBody">
				          <div className="row">
				            <div className="col-xl-6 col-lg-6 mb-3">
				              <div className="row">
				                <div className="col-5 gtProTxt">Religion</div>
				                <div className="col-7 gtProTxtDet">{user.religion} </div>
				              </div>
				            </div>
				            <div className="col-xl-6 col-lg-6 mb-3">
				              <div className="row">
				                <div className="col-5 gtProTxt">Caste</div>
				                <div className="col-7 gtProTxtDet">{user.caste}</div>
				              </div>
				            </div>
				          </div>
				          <div className="row">
				            <div className="col-xl-6 col-lg-6 mb-3">
				              <div className="row">
				                <div className="col-5 gtProTxt">Sub Caste</div>
				                <div className="col-7 gtProTxtDet">{user.subcaste}</div>
				              </div>
				            </div>
				          </div>
				        </div>
					    </div>
			        <div className="mt-4"></div>
			        <div className="gtProBox card mb-3">
			          <div className="gtProBoxHead">
			            <div className="row">
			              <div className="col-7"><h4>Location Information</h4></div>
			            </div>
			          </div>
			          <div className="gtProBoxBody">
			            <div className="row">
			              <div className="col-xl-6 col-lg-6 mb-3">
			                <div className="row">
			                  <div className="col-5 gtProTxt">Country</div>
			                  <div className="col-7 gtProTxtDet">{user.country}</div>
			                </div>
			              </div>
			              <div className="col-xl-6 col-lg-6 mb-3">
			                <div className="row">
			                  <div className="col-5 gtProTxt">State</div>
			                  <div className="col-7 gtProTxtDet">{user.state}</div>
			                </div>
			              </div>
			            </div>
			            <div className="row">
			              <div className="col-xl-6 col-lg-6 mb-3">
			                <div className="row">
			                  <div className="col-5 gtProTxt">City</div>
			                  <div className="col-7 gtProTxtDet">{user.city}</div>
			                </div>
			              </div>
			            </div>
			          </div>
			        </div>
			        <div className="mt-4"></div>
		          <div className="gtProBox card mb-3">
			          <div className="gtProBoxHead">
			            <div className="row">
			              <div className="col-7"><h4>Professional Information</h4></div>
			            </div>
			          </div>
			          <div className="gtProBoxBody">
			            <div className="row">
			              <div className="col-xl-6 col-lg-6 mb-3">
			                <div className="row">
			                  <div className="col-5 gtProTxt"> Professional </div>
			                  <div className="col-7 gtProTxtDet">{user.education}</div>
			                </div>
			              </div>
			              <div className="col-xl-6 col-lg-6 mb-3">
			                <div className="row">
			                  <div className="col-5 gtProTxt">Education Details</div>
			                  <div className="col-7 gtProTxtDet">{user.additional}</div>
			                </div>
			              </div>
			            </div>
			            <div className="row">
			              <div className="col-xl-6 col-lg-6 mb-3">
			                <div className="row">
			                  <div className="col-5 gtProTxt">Occupation</div>
			                  <div className="col-7 gtProTxtDet">{user.occupation}</div>
			                </div>
			              </div>
			              <div className="col-xl-6 col-lg-6 mb-3">
			                <div className="row">
			                  <div className="col-5 gtProTxt">Employed in</div>
			                  <div className="col-7 gtProTxtDet">{user.employee}</div>
			                </div>
			              </div>
			            </div>
			            <div className="row">
			              <div className="col-xl-6 col-lg-6 mb-3">
			                <div className="row">
			                  <div className="col-5 gtProTxt">Annual Income</div>
			                  <div className="col-7 gtProTxtDet">{user.income}</div>
			                </div>
			              </div>
			            </div>
			          </div>
			        </div>
	      			<div className="mb-4"></div>
		          		<p className="ml-2">Please contact us for horoscope changes</p>
				        	<div className="mt-4"></div>
				        	{(user.horoscope_pic===true) && 
				        		<div className="gtProBox card mb-3">
							        <div className="gtProBoxHead">
							          <div className="row">
							            <div className="col-7"><h4>Horoscope / Kundli Information</h4></div>
							          </div>
							        </div>
							        <div className="gtProBoxBody">
							          <div className="row mb-3">
							            <div className="col-xl-12 gtProTxtDet">
							              <button style={{'backgroundColor':'#fff','border':'1px solid #dfbb26'}} onClick={getHoroscopeDownload} className="btn btn-green gtProTxtDet" ><i className='fas fa-download'></i>  Download</button>
							            </div>
							          </div>
						        	</div>
						      	</div>}
				        <div className="gtProBox card mb-3">
					        <div className="gtProBoxHead">
					          <div className="row">
					            <div className="col-7"><h4>About Me</h4></div>
					          </div>
					        </div>
					        <div className="gtProBoxBody">
					          <div className="row mb-3">
					            <div className="col-xl-12 gtProTxtDet">
					              <p>{user.about}</p>
					            </div>
					          </div>
				        	</div>
				      	</div>
								<p className="gtPageSubTitle text-left mb-4 ml-2">All profile result was based on partner preference.Fill it carefully to get accurate results.</p>
		      			{(parseInt(userViewedContact)<parseInt(userContactCount)) &&
		      			<div className="gtProBox card mb-3">
									<div className="gtProBoxHead">
										<div className="row">
										<div className="col-10 " data-toggle="modal" data-target="#exampleModal">
											<div>
												<h4 style={{"color":"black"}}>Contact Information</h4>
											</div>
										</div>
										<div className="col-2 text-right" style={{'display' : `${popup ? 'block' : 'none'}`}} >
												<button className="close-modal" onClick={() =>showDetail(false)}>&times;</button>
											</div>
										</div>
									</div>
									<div style={{'display' : `${popup ? 'block' : 'none'}`}}>
										<div className="gtProBoxBody" >
											<div className="row">
												<div className="col-xl-6 col-lg-6 mb-3">
													<div className="row">
														<div className="col-5 gtProTxt">
															Mobile
														</div>
														<div className="col-7 gtProTxtDet">
														{user.mobile_no}
														</div>
													</div>
												</div>
												<div className="col-xl-6 col-lg-6 mb-3">
													<div className="row">
														<div className="col-5 gtProTxt">
															Email
														</div>
														<div className="col-7 gtProTxtDet">
														{user.email}
					                  </div>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-xl-6 col-lg-6 mb-3">
													<div className="row">
														<div className="col-5 gtProTxt">
															Alternative Mobile
														</div>
														<div className="col-7 gtProTxtDet">
														{user.phone}
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									{!popup ? <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                    <div class="modal-content">
                      <div class="modal-header">
                      <h5 class="modal-title mt-2" id="exampleModalLabel">Contact Information</h5>
                      <button type="button" className="close-modal" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                      </div>
                      <div class="modal-body">
                      you will be deducted 1 contact from total contact ?
                      </div>
                      <div class="modal-footer">
                       <button type="button" class="btn btn-primary" data-dismiss="modal" onClick={() => showDetail(true)}>Yes</button>
                       <button type="button" class="btn btn-secondary" data-dismiss="modal" onClick={() =>showDetail(false)}>No</button>
                      </div>
                    </div>
                    </div>
                  </div> : ''}
								</div>}
		      		</div>
		      	</div>
      	  </div>
      	</div>
   )
}