import React, { useState } from 'react'
import { Link,useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import Constants from "../services/Constants";
import Axios from "axios"

export default function ForgotPasswordComponent() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { register,setValue, handleSubmit, formState: { errors } } = useForm();
  const [inputError, setInputError] = useState(0);

  const onChangeMobile = (e) => {
    setValue('username',e.target.value.replace(/\D/g,''));
    setInputError(0);
  }
  
  const onSubmit = (data) => {
    Axios.post(Constants.API_URL+"forgetpassword",{
      ud_phone: data.username,
    }).then((response)=>{
      if (response.data.data.id === 0) setInputError(1);
      else {
        navigate('/resetpassword' , {state: 
          {
            title:true,
            message:t('otp_sent_success'),
            id: response.data.data.id,
            otp:response.data.data.otp
          }});
      }
    }).catch((err) => {
      console.log(err);
    })
  } 

  return (
      <div className="container mt-5">
        <form onSubmit={handleSubmit(onSubmit)} id="forgetpassword-form" className="form-horizontal">
          <div className="row">
            <div className="col-xl-8 offset-xl-2 col-md-8 offset-md-2 col-lg-10 offset-lg-1">
              <div className="gtLogMain">
                <div className="row">
                  <div className="col-xl-6 col-lg-6 d-lg-block pb-4 ">
                    <div className="gtLogRegNow">
                    <img alt={"forgetpassword"} src={require('../assets/images/login-couple.png')} className="img-fluid" />
                    <h5 className="mt-2">{t('not_yet_registered')}</h5>
                    <Link to="/register" className="btn btnLogReg mt-2">{t('register_now')}</Link>
                    </div>
                    <div className="clearfix"></div>
                  </div>
                  <div className="col-xl-6 col-lg-6">
                    <h4 className="gtPageTitle">{t('forget_password')}</h4>
                    <p className="gtPageSubTitle">{t('enter_the_mobile_no_follow_instruction_for_login')}</p>
                    <div className="row mt-4">
                    <div className="form-group col-12">
                      <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text">{t('india_phonecode')}</span>
                      </div>
                      <input type="text" className="form-control" placeholder={t('enter_the_mobile_no')} 
                        {...register("username", 
                          {
                            required: true, 
                            maxLength: 10, 
                            minLength: 10, 
                            pattern:/^[0-9+-]+$/,
                            onChange: (e) => onChangeMobile(e)
                          }
                        )} />
                       <i className="fas fa-mobile-alt fa_mob1"></i>
                      </div>
                      {errors.username?.type==="pattern" && <p className='error'>{t('mobile_is_invalid')}</p>}                    
                      {errors.username?.type==="required" && <p className="error">{t('mobile_is_required')}</p>}
                      {errors.username?.type==="maxLength" && <p className="error">{t('mobile_is_maxlength')}</p>}
                      {errors.username?.type==="minLength" && <p className="error">{t('mobile_is_minlength')}</p>}
                      {(inputError===1 && !errors.username?.type) && <p className="error">{t('invalid_account_please_register_now')}</p>}
                      </div>
                      <div className="form-group col-12 text-center">
                        <button type="Submit"  className="btn btnLogMain">{t('submit')}</button>
                    </div>
                    <div className="form-group col-12 text-center mt-4">
                      <Link to="/login" state = {{title:false}} className="btn btnBackToLog"><i className="fas fa-arrow-left"></i>&nbsp;&nbsp;{t('back_to_login')}</Link>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
  )
};
