import Axios from "axios" 
import { useEffect,useState } from "react";
import { useTranslation } from 'react-i18next';
import Constants from "../services/Constants";  
import MessageComponent from '../components/MessageComponent';
import ListmessageComponent from '../components/ListmessageComponent';
import Skeleton , { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import ResultNotFoundComponent from '../components/ResultNotFoundComponent';

export default function InterestsentComponent() {
  const { t } = useTranslation();
	let userToken = JSON.parse(localStorage.getItem('userToken'));
  const [interstSentUsers, setInterestSentUsers] = useState([]);
  const [update, setUpdate] = useState(0);
  const [loading,setLoading] = useState(0);
  const [width,setWidth] = useState({size:0,data:0});

  if(window.innerWidth >= 481 && window.innerWidth <= 768 && width.size===0){
    setWidth({...width,size:1});
  }

  if(window.innerWidth >= 769 && window.innerWidth <= 1024 && width.size===0){
    setWidth({...width,size:2});
  }

  if(window.innerWidth >= 1050 && window.innerWidth <= 1400 && width.size===0){
    setWidth({...width,size:3});
  }


  useEffect(() => { 
      const getInterestSent =  () => {
        Axios.post(Constants.API_URL+"getinterestsendprofiles",{
            api_token : userToken
          }).then((response)=>{
            setInterestSentUsers(response.data.data);  
            setLoading(1);
          });
      }
      getInterestSent();
  },[userToken,update]);
    return (
      <>
        {loading=== 0 && 
        <SkeletonTheme baseColor="#dfbb26" height="10px" highlightColor="#fff">
          <Skeleton />
        </SkeletonTheme>}
      	<div className="container mt-5">
          <h3 className="gtPageTitle">{t('interest_sent')}</h3>
          <p className="gtPageSubTitle">{t('interest_sent_description')}</p>
      		<div className="row">
  					<div className="col-md-4">
  						<MessageComponent />
  					</div>
  					<div className="col-md-8">
              {interstSentUsers.length===0 &&  <ResultNotFoundComponent data={width.size} />}
  						{interstSentUsers.length > 0 && <ListmessageComponent data={interstSentUsers} update={update} setUpdate={setUpdate} />}
  					</div>
  				</div>
  				<div className="col-md-1"></div>
  			</div>	
      </>
   	)
}