import Axios from "axios" 
import { useEffect,useState } from "react";
import { useTranslation } from 'react-i18next';
import Constants from "../services/Constants";  
import ListUserComponent from '../components/ListUserComponent'
import Skeleton , { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import ResultNotFoundComponent from '../components/ResultNotFoundComponent';

export default function ProfileviewComponent() {
	let userToken = JSON.parse(localStorage.getItem('userToken'));
  const [items, setItems] = useState([]);
  const { t } = useTranslation();
  const [update, setUpdate] = useState(0);
  const [loading,setLoading] = useState(0);


  useEffect(() => { 
    const getProfileViewed = () => {
      Axios.post(Constants.API_URL+"getviewedprofiles",{ api_token : userToken
      }).then((response)=>{
        setItems(response.data.data);  
        setLoading(1);
      });
    }
      getProfileViewed();
  },[userToken,update]);
	
    return (
      <>
        {loading=== 0 && 
        <SkeletonTheme baseColor="#dfbb26" height="10px" highlightColor="#fff">
          <Skeleton />
        </SkeletonTheme>}
      	<div className="container mt-5">
  	  		<h3 className="gtPageTitle">{t('profile_viewed_by')}</h3>
  			  <p className="gtPageSubTitle">{t('profile_viewed_description')}</p>
  				 {items.length > 0 ? <ListUserComponent  data={items} update={update} setUpdate={setUpdate} /> : <ResultNotFoundComponent />} 
  			</div>	
      </>
   	)
}