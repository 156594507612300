import Axios from "axios"
import { Link,useNavigate } from "react-router-dom";
import { useState,useEffect } from "react";
import { useTranslation } from 'react-i18next';
import Constants from "../services/Constants";
import '../assets/css/bootstrap.css'
import '../assets/css/chosen.css'
import '../assets/css/custom.css'
import '../assets/css/ta.css'

export default function HeaderComponent(){
	const navigate = useNavigate();
	const { t } = useTranslation();
	const [count] = useState(0);
	const [logo, setLogo] = useState("");
	let user = JSON.parse(localStorage.getItem('user'));
  let userPaid = localStorage.getItem('userPaid');
  let userToken = JSON.parse(localStorage.getItem('userToken')); 
  const [data,setDatas] = useState({
    id: 0,matri_id: "",fname : "",lname : "",email : "",status : 0,plan :0,
    contact : 0,view : 0,gender: 0,paidmember : 0,plan_end_date : "",
    interestsent : 0,interestreceived : 0,messagesent : 0,messagereceived : 0,pic : ""
  });
  const [nickName,setNickName] = useState("");

  

  const logoimage = () => {
		Axios.get(Constants.API_URL+"logoimage").then((response)=>{
			if(response.data.logo){
				setLogo(response.data.logo);
				localStorage.setItem('banner',response.data.banner);
			}
		});
  }

  const setting = () => {
	Axios.get(Constants.API_URL+"setting").then((response)=>{
		if(response.data.data){
			localStorage.setItem('setting',JSON.stringify(response.data.data));
		}
	});
}
	// set click menu
	const [click, setClick] = useState(false);
	const handleClick = () => setClick(!click);
	


  useEffect(() => {
  	const getUserdetails = () => {
		if(userToken!==null){
	  	Axios.post(Constants.API_URL+"userinfo",{
		  	api_token : userToken
		 	}).then((response)=>{
    		localStorage.setItem('userPhoto',Constants.IMAGE_URL+"pictures/"+response.data.data.ud_pic);
				setDatas(response.data.data);
				if(response.data.data.fname!=="" && response.data.data.fname.length>10){
					setNickName(response.data.data.fname.substring(0,5)+'...');
				}else{
					setNickName(response.data.data.fname);
				}
		  });
		}
  }
	  getUserdetails();
	  logoimage();
	  setting();
  },[userToken,count]);



	const Logout = () => {
		localStorage.clear();
		navigate('/login',{state:{title:false}});
	}

	const func1 = () => {
    	// do something
  	};
  	const func2 = () => {
    	// do something
  	};



  return (
    	<div> 
	  	 {(user===null) ?  
	  	 	<nav className="navbar navbar-expand-lg navbar-light ">
				  <div className="container"> 
				    <button className="navbar-toggler" type="button" onClick={handleClick}><i className={click?"fa fa-times":"fa fa-bars"}></i>
				    </button>
				    <header className="main-header" id="main-header">
				      <div className="header-lower">
				        <div className="container clearfix">
				          <div className="outer-box">
				            <div className="logo">
				              <Link to="/">
				                <img className="img-responsive" src={Constants.IMAGE_URL+logo} alt="Logo" />
				              </Link>
				            </div>
				            <div onClick={e => e.stopPropagation()}>
				            <div className={click ? "collapse navbar-collapse show" : "collapse navbar-collapse"} id="navbarSupportedContent">
				              <ul className="navbar-nav flex-start-just">
				                <li className="nav-item">
				                  <Link className="nav-link" to="/" state={{state:{title:false, message:''}}} onClick={click ? handleClick : null}>{t('home')}</Link>
				                </li>
				                <li className="nav-item">
				                  <Link className="nav-link" to="/search" state={{state:{title:false,message:''}}} onClick={click ? handleClick : null}>{t('search')}</Link>
				                </li>
				                <li className="nav-item">
				                  <Link className="nav-link" to="/successstory" state={{state:{title:false, message:''}}} onClick={click ? handleClick : null}>{t('success_story')}</Link>
				                </li>
				                <li className="nav-item">
				                  <Link className="nav-link" to="/memberplan" state={{state:{title:false, message:''}}} onClick={click ? handleClick : null}>{t('membership')}</Link>
				                </li>
				                <li className="nav-item">
				                  <Link className="nav-link" to="/contact" state={{state:{title:false, message:''}}} onClick={click ? handleClick : null}>{t('contact')}</Link>
				                </li>
				                <li className="nav-item mr-2 align-cen">
				                  <Link to="/login" state={{ state: {title:false, message:''} }} className="btn btn-green" onClick={click ? handleClick : null}>
				                    <i className="fas fa-lock pr-2"></i>{t('member')} ?</Link>
				                </li>
				                <li className="nav-item align-cen">
				                  <Link to="/register" className="btn btn-green-1" state={{state:{title:false, message:''}}} onClick={click ? handleClick : null}>
				                    <i className="fas fa-user-plus pr-2"></i>{t('register')}</Link>
				                </li>
				              </ul>
				            </div>
				            </div>
				          </div>
				        </div>
				      </div>
				    </header>
				  </div>
				</nav>
        : 
				<nav className="navbar navbar-expand-lg navbar-light">
		      <div className="container" style={{'maxWidth': '1300px'}}> 
		        <button className="navbar-toggler" type="button" onClick={handleClick}><i className={click?"fa fa-times":"fa fa-bars"}></i>
				    </button>
		        <header className="main-header" id="main-header">
		          <div className="header-lower">
		            <div className="container clearfix">
		              <div className="outer-box">
		                <div className="logo" style={{'marginLeft': '2px'}}>
		                  <Link to="/" state={{state:{title:false, message:''}}}>
		                  	<img className="img-responsive" src={Constants.IMAGE_URL+logo} alt="Logo" />
		                  </Link>
		                </div>

				            <div  className={click ? "collapse navbar-collapse show" : "collapse navbar-collapse"} id="navbarSupportedContent">
		                   <ul className="navbar-nav mr-auto">
					        			<li className="nav-item">
								          <Link className="nav-link" to="/" state={{state:{title:false,message:''}}} onClick={click ? handleClick : null}><span style={{fontSize: '20px'}} className="fa fa-home"></span></Link>
								        </li>
								        <li className="nav-item">
								          <Link className="nav-link" to="/home" state={{state:{title:false,message:''}}} onClick={click ? handleClick : null}>{t('dashboard')}</Link>
								        </li>
								        <li className="nav-item dropdown">
								          <a href="/#" onClick={(e) => e.preventDefault()} className="nav-link dropdown-toggle" data-toggle="dropdown" to="#" role="button" aria-haspopup="true" aria-expanded="true">{t('my_profile')}</a>
								          <div className="dropdown-menu">
								            <Link className="dropdown-item" to="/myprofile" onClick={click ? handleClick : null}>{t('view')} / {t('edit_profile')}</Link>
								            <Link className="dropdown-item" to="/uploadphoto" onClick={click ? handleClick : null} >{t('upload_photos')}</Link>
								            <Link className="dropdown-item" to="/shortlist" onClick={click ? handleClick : null} >{t('shortlisted_profile')}</Link>
								            <Link className="dropdown-item" to="/blocklist" onClick={click ? handleClick : null} >{t('blocked_profile')}</Link>
								          </div>
								        </li>
								        <li className="nav-item dropdown">
								          <Link className="nav-link dropdown-toggle" data-toggle="dropdown" to="#" role="button" aria-haspopup="true" aria-expanded="false">Profiles</Link>
								          <div className="dropdown-menu">
								            <Link className="dropdown-item" to="/profileview" onClick={click ? handleClick : null}>{t('profile_viewed_by')}</Link>
								            {userPaid==="1" && 
								          		<>
								          		<Link className="dropdown-item" to="/paidmembers" onClick={click ? handleClick : null}>{t('paid_members')}</Link>
									            <Link className="dropdown-item" to="/recentjoin" onClick={click ? handleClick : null}>{t('recently_joined')}</Link>
									            <Link className="dropdown-item" to="/newmatches" onClick={click ? handleClick : null}>{t('new_matches')}</Link>
								          		</>
								          		}
								          </div>
								        </li>
								        <li className="nav-item">
								          <Link className="nav-link" to="/search" onClick={click ? handleClick : null}>{t('search')}</Link>
								        </li>
								        <li className="nav-item dropdown">
								          <a href="/#" onClick={(e) => e.preventDefault()} className="nav-link dropdown-toggle" data-toggle="dropdown" to="#" role="button" aria-haspopup="true" aria-expanded="false">Messages</a>
									          <div className="dropdown-menu">
									            <Link className="dropdown-item" to="/inbox" onClick={click ? handleClick : null}>{t('message')} - {t('inbox')}</Link>
									            <Link className="dropdown-item" to="/messagesent" onClick={click ? handleClick : null}>{t('message')} - {t('sent')}</Link>
									             <Link className="dropdown-item" to="/interestreceive" onClick={click ? handleClick : null}>{t('express_interest')} - {t('received')}</Link>
									            <Link className="dropdown-item" to="/interestsent" onClick={click ? handleClick : null}>{t('express_interest')} - {t('sent')}</Link>
									          </div>
								        </li>
								        <li className="nav-item">
								          <Link className="nav-link" to="/memberplan" onClick={click ? handleClick : null}>{t('membership_plan')}</Link>
								        </li> 
								        <li className="nav-item">
								          <div className="btn-group mt-2 bg-site">
								            <a href="/#" onClick={(e) => e.preventDefault()}  className="dropdown-toggle btn btn-sm gtHSetBtn btn-block" data-toggle="dropdown"  aria-expanded="false">{nickName ? nickName : ''} </a>
								            <div className="dropdown-menu logged">
								              <div className="dropdown-item gtMainDetHeader" to="#">
								                <div className="row mb-0">
								                  <div className="col-12 mb-0">
								                    <h4>{data.fname ? data.fname : ''} { data.lname ? data.lname : '' }</h4>
								                    <div>({data.matri_id ? data.matri_id : ''})</div>
								                    <p>{t('profile_status')} :<b className="pl-2">{data.status===1 ? t('approved') : t('pending')}</b></p>
								                    <p>{t('current_plan')} :<b className="pl-2">{data.plan!=="" ? data.plan  : "Free" }</b></p>
								                  </div>
								                </div>
								              </div>
								              <div className="dropdown-divider"></div>
								              <Link className="dropdown-item" to="/settings" onClick={click ? handleClick : null}><i className="fas fa-cog pr-2"></i>{t('settings')}</Link>
								              <Link className="dropdown-item" to="/deleteprofile" onClick={click ? handleClick : null}><i className="fas fa-trash pr-2"></i>{t('delete_profile')}</Link>
								              <button className="dropdown-item" onClick={click ? handleClick : null}><i className="fas fa-sign-out-alt pr-2" onClick={Logout}>{t('logout')}</i></button>
								            </div>
								          </div>
								        </li>
								      </ul>
		                </div>

		              </div>
		            </div>
		          </div>
		        </header>
		      </div>
		    </nav>
			}
		</div> 
    )
}