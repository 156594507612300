import { useEffect,useState } from 'react'
import { useNavigate,Link } from "react-router-dom";
import Axios from "axios"
import Constants from "../services/Constants";
import { useTranslation } from 'react-i18next';

export default function FooterComponent() {
	const { t,i18n } = useTranslation();
  const [pagedata,setPageData] =useState([]);
	const [setting] =useState(localStorage.getItem('setting') ? JSON.parse(localStorage.getItem('setting')) : {s_site_name:""});
	const navigate = useNavigate();

	useEffect(() => {
		const cmsData = () => {
			Axios.get(Constants.API_URL+"cms").then((response)=>{
      if(response.data.data){
        setPageData(response.data.data);
      }else{
        navigate('/error',{state:{title:true,message:t('something_went_wrong'),error:true}})
      }
    });
		}
		cmsData();
	},[i18n,navigate,t]);


	return (
 	  <footer className="footer-04">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-lg-3 mb-md-0 mb-4">
            <h2 className="footer-heading">{t('help_support')}</h2>
            <ul className="list-unstyled">
            {pagedata.slice(0,2).map((cms, i) => (
              <li key={cms.id}><Link to={cms.url}>{cms.title}</Link></li>
            ))}
            </ul>
          </div>
          <div className="col-md-6 col-lg-3 mb-md-0 mb-4">
            <h2 className="footer-heading">{t('terms_policy')}</h2>
            <ul className="list-unstyled">
              {pagedata.slice(3,5).map((cms, i) => (
              <li key={cms.id}><Link to={cms.url}>{cms.title}</Link></li>
            	))}
            </ul>
          </div>
          <div className="col-md-6 col-lg-3 mb-md-0 mb-4">
            <h2 className="footer-heading">{t('information')}</h2>
            <ul className="list-unstyled">
              {pagedata.slice(6,8).map((cms, i) => (
                <li key={cms.id}><Link to={cms.url}>{cms.title}</Link></li>
              ))}
              <li><Link to="/successstory">{t('success_stories')}</Link></li>
              <li><Link to="/contact">{t('help')}</Link></li>
            </ul>
          </div>
          <div className="col-md-6 col-lg-3 mb-md-0 mb-4">
            <h2 className="footer-heading">{t('follow_up')}</h2>
            <ul className="ftco-footer-social gtFootSocial p-0">
              <li><a href="https://www.facebook.com/"><i className="fab fa-facebook"></i></a></li>
              <li><a href="https://www.twitter.com/"><i className="fab fa-twitter-square"></i></a></li>
              <li><a href="https://www.youtube.com/"><i className="fab fa-youtube-square"></i></a></li>
              <li><a href="https://www.instagram.com/"><i className="fab fa-instagram"></i></a></li>
            </ul>
          </div>
        </div>
      </div>
      <div className="w-100 mt-4 border-top pad-top-bot">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-12 text-center">
              <p className="copyright">{t('all_rights_reserved')} {setting.s_site_name} <b></b><strong></strong></p>
            </div>
          </div>
        </div>
      </div>
    </footer> 
	)
}