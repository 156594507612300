import React, { useEffect,useState } from 'react'
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Axios from "axios"
import Constants from "../services/Constants";

export default function UploadPhotoComponent(){
  const { t } = useTranslation();
  let userID = localStorage.getItem("userId");
  let userToken = JSON.parse(localStorage.getItem('userToken'));
  const {  handleSubmit } = useForm();
  const {  handleSubmit : handleSubmit2 } = useForm({ mode: "onBlur" });
  const [picture, setPicture] = useState(null);
  const [imgData, setImgData] = useState(null);
  const [file, setFile] = useState([]);
  const [multiImages, setMultiImages] = useState([]); 
  const [galleryImages, setgalleryImages] = useState([]); 
  const [loadImages, setLoadImages] = useState(0); 
  const [inputErrors,setInputErrors]= useState({type:0,size:0});
  const [multiinputErrors,setmultiInputErrors]= useState({index:0,type:0,size:0,width:0,height:0});
  let userPhoto = localStorage.getItem('userPhoto');
  const isValidType=(file)=>{
    const validExt = ['png','jpeg','jpg']
    const fileExt = file.type.split('/')[1];
    return validExt.includes(fileExt);
  }


  const onChangePicture = (e) => {
    if (e.target.files[0]) {
      setPicture(e.target.files[0]);
      var filesValid = isValidType(e.target.files[0]);
      if(isValidType(e.target.files[0])) setInputErrors({...inputErrors,type:0}); 
      else setInputErrors({...inputErrors,type:1}); 
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgData(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = function (e) {
          var image = new Image();
          image.src = e.target.result;
          image.onload = function () {
          var height = this.height;
          var width = this.width;
          if(filesValid===true) filesValid = 0;
          else filesValid = 1;
          if (height > 400 && width > 400) setInputErrors({...inputErrors,type:filesValid,size:0}); 
          else  setInputErrors({...inputErrors,type:filesValid,size:1}); 
        }
      }
    }
  } 

  const onSubmit = () => {
    if(inputErrors.type===0 && inputErrors.size===0){
      Axios.post(Constants.API_URL+"userprofilepictureupdate",{
        user_id : userID, image : picture
      },{ headers: { "content-type": "multipart/form-data"  },
      }).then((response)=>{
        if(response.data.error===false){
          toast.success(response.data.message);
          localStorage.setItem('userPhoto',imgData);
        } 
        else toast.error(response.data.message);
        setImgData(null);
      }).catch((err) => {
          console.log(err);
      })
    }
  }


  const onSubmitMultiImages = () => {
    if(multiinputErrors.type===0){
      if(galleryImages.length < 6){
        const data = new FormData();
        for (let i = 0; i < multiImages.length; i++) {
          data.append("images[]", multiImages[i]);
        }
        Axios.post(Constants.API_URL+"addgalleryimages",data,{
           headers: {
             "Content-Type": "multipart/form-data",  
             'Authorization': userToken
           }
        }).then((response)=>{
          setLoadImages(loadImages + 1);
          if(response.data.error===false) toast.success(response.data.message);
          else toast.error(response.data.message);
        }).catch((err) => {
            console.log(err);
        })
      }else{
        toast.error(t('only_six_gallery_images'));
      }
        setMultiImages([]);
        setFile([]);
    }
  }

  function handleChangeMultiFile(e) {
    if (e.target.files[0]) {
      var imagefile = e.target.files[0];
      var filesValid = isValidType(e.target.files[0]);
      if(isValidType(e.target.files[0])) setmultiInputErrors({...multiinputErrors,type:0}); 
      else setmultiInputErrors({...multiinputErrors,type:1}); 
      const reader = new FileReader();
      reader.addEventListener("load", () => {
      });
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = function (e) {
          var image = new Image();
          image.src = e.target.result;
          image.onload = function () {
          var height = this.height;
          var width = this.width;
          if(filesValid===true) filesValid = 0;
          else filesValid = 1;
          if (height > 400 && width > 400) setmultiInputErrors({...multiinputErrors,index:multiImages.length,type:filesValid,size:0,width:width,height:height}); 
          else  setmultiInputErrors({...multiinputErrors,index:multiImages.length,type:filesValid,size:1,width:width,height:height}); 
          if(filesValid===0 && (height > 400 && width > 400)){
            setMultiImages([...multiImages, imagefile]);
            setFile([...file, URL.createObjectURL(imagefile)]);
          }else{
            if(filesValid===0)
              toast.error(t('upload_photos_size'));
            else
              toast.error(t('upload_photos_type'));
          }
        }
      }
    }
  };


  function deleteFile(e,allfiles) {
    if(allfiles===0){
      const s = file.filter((item, index) => index !== e);
      var type = multiImages[e].name;
      if(type){
        var ext = type.split('.');
        if(ext[1]==='jpg' || ext[1]==='jpeg' || ext[1]==='png'){
          setmultiInputErrors({...multiinputErrors,type:0});
        }else{
          if(e!==0){
            setmultiInputErrors({...multiinputErrors,type:1});
          }else{
            setmultiInputErrors({...multiinputErrors,type:0})
          }
        };
      }
      setFile(s);
      setMultiImages(s);
    }else{
      setFile([]);
      setMultiImages([]);
      setmultiInputErrors({...multiinputErrors,type:0});
    }
  }


  useEffect(() => {
    Axios.post(Constants.API_URL+"getgalleryimages",{
      api_token : userToken
    }).then((response)=>{
      if(response.data.data.length > 0){
        setgalleryImages(response.data.data);
      }else{
        setgalleryImages([]);
      }
    }).catch((err) => {
        console.log(err);
    })
  },[loadImages,userToken]);


  const deleteGalleryImages = (e) => {
    let deleteImage = e.target.getAttribute("data-image");
    if(deleteImage!==''){
      Axios.post(Constants.API_URL+"removegalleryimages",{
        api_token : userToken,image : deleteImage
      }).then((response)=>{
        if(response.data.error===false) toast.success(response.data.message);
        else toast.error(response.data.message);
        setLoadImages(loadImages + 1);
      }).catch((err) => {
          console.log(err);
      })
    }
  }


  return (
       <div className="container mt-5">
       <ToastContainer />
        <h3 className="gtPageTitle">{t('upload_photos')}</h3>
        <p className="gtPageSubTitle">{t('upload_photos_description')}</p>
        <div className="row mt-4  d-flex justify-content-center">
          <div className="col-xl-4 col-md-6 offset-md-3 col-lg-4 offset-lg-0">
            <div className="card gtPhotoUpload mb-3">
              <form className="form-horizontal MultiFile-intercepted" onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data" id="profilephoto-form">                                   
                <div className="card-img-top">
                  <h4>{t('profile_picture')}</h4>
                  <img alt={"profileImage"} src={imgData===null ? userPhoto : (imgData ? imgData : require('../assets/images/no_image.png'))} className="img-fluid img-fix size" />
                </div>
                <div className="card-body profile">
                  {inputErrors.type===1 && <span className="error ml-4">{t('horoscope_file_type_is_invalid')}</span>}                                     
                  <br />
                  {inputErrors.size===1 && <span className="error ml-4">{t('upload_photos_size')}</span>}                                     
                  <label htmlFor="photo1" className="btn btnSelect btn-block mt-2">{t('select_image')}</label>
                  <input id="ytphoto1" type="hidden" value="" name="Userdetails[ud_pic]" />
                  <input onChange={onChangePicture} accept="image/*" size="60" maxLength="100" className="form-control" id="photo1" name="Userdetails[ud_pic]" type="file" /> 
                  <input className="btn btnRegFsubmit btn-block text-center" disabled={imgData!=null ? false : true}  name="uploadphoto" type="submit" value={t('submit')} />              
                </div>
              </form>
            </div>
          </div>
          <div style={{'display':'none'}} className="col-xl-8 col-sm-12 col-md-12 col-lg-8">
            <div className="col-xl-12 col-md-6 offset-md-3 col-lg-12 offset-lg-0">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">{t('add_image_in_gallery')}</h4>
                  <form>
                    <div className="row">
                      {file.length > 0 &&
                        file.map((item, index) => {
                          return(
                            <div key={item} className="col-xl-4 col-12 col-md-12">
                              <div className="card gtUploadMPhotoes">
                                <div className="card-img-top">
                                  <img src={item} alt={"GalleryImage"} className="img-fluid "/>
                                </div>
                                <div className="card-body">
                                  <button className="btn btn-block" style={{'padding':'5px'}} onClick={() => deleteFile(index,0)}>{t('delete')}</button>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </form>
                  <form className="form-horizontal MultiFile-intercepted" onSubmit={handleSubmit2(onSubmitMultiImages)}  encType="multipart/form-data" id="gallery-form">                            
                    <div className="form-group custom-file">
                      <label className="col-md-12 custom-file-label" htmlFor="Userdetails_gallery_image_name">{t('gallery_image_name')}</label> 
                      <div className="MultiFile-wrap" id="gallery_image_name_wrap">
                        <input className="form-control custom-file-input MultiFile-applied" id="gallery_image_name"  name="gallery_image_name[]"
                        type="file" disabled={file.length === 6 || (galleryImages.length+file.length===6)} onChange={handleChangeMultiFile}  />
                        <div className="MultiFile-list" id="gallery_image_name_wrap_list"></div>
                      </div>
                    </div>
                    <div className="mt-20">
                      <input disabled={multiImages.length > 0 ? false : true} className="btn btn-green mr-2" type="submit" value="Upload" />
                      <span style={{'color':'#fff'}} onClick={(e) => deleteFile(e,1)} className="btn btn-danger reset" type="cancel">Cancel</span>
                      <p><span className="badge badge-secondary">{t('note')}</span>&nbsp;&nbsp;{t('gallery_images_description')}</p>
                    </div>
                  </form>
                  <div className="row">
                  {galleryImages.length > 0 && galleryImages.map((gallery,index)=> {
                    return (
                      <div key={gallery} className="col-xl-4 col-12 col-md-12 col-lg-6">
                        <div className="card gtUploadMPhotoes">
                          <div className="card-img-top">
                            <img src={Constants.IMAGE_URL+gallery} alt={"GalleryImage"} className="img-fluid "/>
                          </div>
                          <div className="card-body">
                            <button className="btn btn-block" data-image={gallery} style={{'padding':'5px'}} onClick={(e) => deleteGalleryImages(e)}>{t('delete')}</button>
                          </div>
                        </div>
                      </div>
                    );})}
                  </div>
                </div>
              </div>
            </div>
          </div>          
        </div>
      </div>
  ) 
}