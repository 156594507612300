import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import Constants from "../services/Constants";
import Axios from "axios";
import useFetch from "../hook/useFetch";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

export default function SuccessStoryComponent(){
	const { register, handleSubmit, formState: { errors } } = useForm();
  const [getSuccessData] = useFetch(Constants.API_URL+"getsuccessstoriesphotos");
	const { t } = useTranslation();
  const navigate = useNavigate();
 	const [picture, setPicture] = useState({file:"",name:""});
  const [model,setModel] = useState({image:"",name:"",description:""});
  const [startengageDate,setEnagageDate] = useState(new Date());
  const [startmarriageDate,setMarriageDate] = useState(new Date());
  const [inputErrors,setInputErrors]= useState({file:0,required:0,engagedate:0,marriagedate:0});
  const [isSubmit,setIsSubmit]= useState(0);

  const onChangePicture = (e) => {
    if (e.target.files[0]) {
      setPicture({...picture,file : e.target.files[0],name:e.target.files[0].name});
      var type = e.target.files[0].name;
      if(type){
        var ext = type.split('.');
        if(ext[1]==='jpg' || ext[1]==='jpeg' || ext[1]==='png'){
          setInputErrors({...inputErrors,file:0,required:0});
        }else setInputErrors({...inputErrors,file:1,required:0});
      } 
    }
  }

  if(isSubmit){
  	if(picture.file===""){
			setInputErrors({...inputErrors,required:1});
		}
  	setIsSubmit(0);
  }

  const onSubmit = (data) => {
		if(inputErrors.file===0 && picture.file!=="" && inputErrors.required===0 &&
			inputErrors.engagedate===0 && inputErrors.marriagedate===0){
			Axios.post(Constants.API_URL+"postSuccessStory", {
	      bride_id : data.bride_id, bride_name : data.bride_name,
	      groom_id : data.groom_id, groom_name : data.groom_name,
	      engage_date :moment(new Date(startengageDate)).format('YYYY-MM-DD'),
	      marriage_date : moment(new Date(startmarriageDate)).format('YYYY-MM-DD'),
	      marriage_photo : picture.file, success_story : data.success_story,
	    },{
	    	 headers: {
	        "Content-Type": "multipart/form-data"
	      },
	    }).then((response) => {
	    	navigate('/',{state:{title:true,message:response.data.message,error:response.data.error}});
	    }).catch((err) => {
	      console.log(err);
	    });
		}
	}

  const getModalPhoto = (event) => {
  		var getphoto = event.target.getAttribute("data-value");
  		var bride_groom_name = event.target.getAttribute("data-name");
  		var success_story_decription = event.target.getAttribute("data-description");
  		setModel({...model,image:getphoto,name:bride_groom_name,description:success_story_decription});
  }

  const addDefaultSrc = (ev) => {
	  ev.target.src = Constants.IMAGE_URL+"couple.png";
	}


		return(
	  	<div className="container mt-5">
				<div className="row">
				  <div className="col-xl-8 offset-xl-2 col-lg-8 offset-lg-2">
						<ul className="nav nav-pills mb-3  nav-fill gtSucPill" id="pills-tab" role="tablist">
						  <li className="nav-item">
								<a className="nav-link active" id="pills-success-tab" data-toggle="pill" href="#pills-success" role="tab" aria-controls="pills-success" aria-selected="true">{t('success_stories')}</a>
						  </li>
						  <li className="nav-item">
								<a className="nav-link" id="pills-success-post-tab" data-toggle="pill" href="#pills-success-post" role="tab" aria-controls="pills-success-post" aria-selected="false">{t('post_success_stories')}</a>
						  </li>
						</ul>
						<div className="clearfix"></div>
						  <div className="tab-content gtSucBody" id="pills-tabContent">
								<div className="tab-pane fade show active" id="pills-success" role="tabpanel" aria-labelledby="pills-home-tab">
							  	<h3 className="text-center">{t('success_story')}</h3>
							  		<p className="text-center">{t('success_stories_description')}</p>
							  		<div id="pagination" className="row">   
							  			{getSuccessData!==null && getSuccessData.map((getSuccess,index) => (
												<div key={getSuccess.story_id} className="col-xl-6 col-lg-6 col-md-6 gtSucDisplay mt-4 mb-3">
											  <div className="card">
													<div className="card-img-top">
												  	<img onError={addDefaultSrc} alt={"SuccessStoryImage"}  width="100" height="100" src={Constants.IMAGE_URL+"successstory/"+getSuccess.marriage_photo} className="img-fluid img-shadow" />
													</div>
													<div className="card-body text-center">
													  <p className="card-text">{getSuccess.bride_name ? getSuccess.bride_name : 'Bride'} & {getSuccess.groom_name ? getSuccess.groom_name : 'Groom'} </p>
													  <p className="card-text">{getSuccess.success_story}</p>
													  <a href="#successFullModal1" data-value={getSuccess.marriage_photo} data-name={getSuccess.bride_name +" & "+ getSuccess.groom_name} data-description={getSuccess.success_story} onClick={(event) => getModalPhoto(event)} data-toggle="modal" className="btn btnSucReMo btn-block">{t('read_more')}</a>
													</div>
								  			</div>
											  <div className="modal fade" id="successFullModal1" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
													<div className="modal-dialog" role="document">
												  	<div className="modal-content">
															<div className="modal-header">
															  <button type="button" className="close icon-alig-cor" data-dismiss="modal" aria-label="Close">
																<span aria-hidden="true">×</span>
															  </button>
															</div>
															<div className="modal-body">
															  <div className="container">
																	<div className="row">
																  	<div className="card">
																			<div className="card-img-top">
																			  <img alt={"SuccessStoryImage"} onError={addDefaultSrc} src={Constants.IMAGE_URL+"successstory/"+model.image}  className="img-fluid img-shadow" />
																			</div>
																			<div className="card-body">
																			  <h5 className="card-title">{model.name}</h5>
																			  <p className="card-text">{model.description}
																			  </p>
																			</div>
																  	</div>
																	</div>
															  </div>
															</div>
															<div className="modal-footer">
															  <button type="button" className="btn btn-secondary" data-dismiss="modal">{t('close')}</button>
															</div>
													  </div>
													</div>
											  </div>
											</div>
											))}									
										</div>
									</div>
									<div className="tab-pane fade" id="pills-success-post" role="tabpanel" aria-labelledby="pills-profile-tab">
								  	<h3 className="text-center">{t('post_success_stories')}</h3>
								   	<p className="text-center">{t('post_success_stories_description')}</p>
								   	<form className="mt-4" onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data" id="success_story-form" action="/matrimic-react/successstory" method="post">
								   		<div className="form-group row">
											  <div className="col-xl-4">
													<label className="mt-2">{t('bride_id')}<span className="required">*</span></label>	
											  </div>
											  <div className="col-xl-8">
													<input type="text" className="form-control"
													placeholder={t('enter_your_bride_id')}
														{...register("bride_id", 
			                        {
			                          required: true, 
			                          pattern:/^(?=.*\d(?=.*\d))(?=.*[a-zA-Z](?=.*[a-zA-Z])).{5,}$/,
			                          maxLength: 32, 
			                        }
			                      )}  />   
		                        {errors.bride_id?.type==="required" && <p className="error">{t('bride_id_is_required')}</p>}
                    				{errors.bride_id?.type==="maxLength" && <p className="error">{t('bride_id_is_maxlength')}</p>}                 	
                    				{errors.bride_id?.type==="pattern" && <p className="error">{t('bride_id_is_invalid')}</p>}                 	
											  </div>	
											</div>
											<div className="form-group row">
											  <div className="col-xl-4">
													<label className="mt-2">{t('bride_name')}<span className="required">*</span></label>											
											  </div>
											  <div className="col-xl-8">
												  <input type="text" className="form-control"
												  placeholder={t('enter_your_bride_name')} 
														{...register("bride_name", 
			                        {
			                          required: true, 
			                          pattern: /^[A-Za-z\s]*$/,
			                          maxLength: 32, 
			                        }
			                      )}  />  
			                      {errors.bride_name?.type==="required" && <p className="error">{t('bride_name_is_required')}</p>}
                    				{errors.bride_name?.type==="maxLength" && <p className="error">{t('bride_name_is_maxlength')}</p>}                 	
                    				{errors.bride_name?.type==="pattern" && <p className="error">{t('bride_name_is_invalid')}</p>}                 	
											  </div>
											</div>
											<div className="form-group row">
											  <div className="col-xl-4">
													<label className="mt-2 required" htmlFor="SuccessStory_groom_id">{t('groom_id')}<span className="required">*</span></label>		
											  </div>
											  <div className="col-xl-8">
												  <input type="text" className="form-control"
												  placeholder={t('enter_your_groom_id')} 
														{...register("groom_id", 
			                        {
			                          required: true, 
			                          pattern:/^(?=.*\d(?=.*\d))(?=.*[a-zA-Z](?=.*[a-zA-Z])).{5,}$/,
			                          maxLength: 32, 
			                        }
			                      )}  />  
			                      {errors.groom_id?.type==="required" && <p className="error">{t('groom_id_is_required')}</p>}
                    				{errors.groom_id?.type==="maxLength" && <p className="error">{t('groom_id_is_maxlength')}</p>}                 	
                    				{errors.groom_id?.type==="pattern" && <p className="error">{t('groom_id_is_invalid')}</p>}                 	
											  </div>
											</div>
									    <div className="form-group row">
										  	<div className="col-xl-4">
													<label className="mt-2 required" htmlFor="SuccessStory_groom_name">{t('groom_name')} <span className="required">*</span></label>			
										  	</div>
										  	<div className="col-xl-8">
													<input type="text" className="form-control"
													placeholder={t('enter_your_groom_name')}
														{...register("groom_name", 
			                        {
			                          required: true, 
			                        	pattern: /^[A-Za-z\s]*$/,
			                          maxLength: 32, 
			                        }
			                      )}  />  
			                      {errors.groom_name?.type==="required" && <p className="error">{t('groom_name_is_required')}</p>}
                    				{errors.groom_name?.type==="maxLength" && <p className="error">{t('groom_name_is_maxlength')}</p>}                 	
                    				{errors.groom_name?.type==="pattern" && <p className="error">{t('groom_name_is_invalid')}</p>}                 	
										  	</div>
											</div>
											<div className="form-group row">
											  <div className="col-xl-4">
													<label className="mt-2" htmlFor="SuccessStory_engagement_date">{t('engagement_date')}<span className="required">*</span></label>
											  </div>
											  <div className="col-xl-8">
													<DatePicker format="YYYY-MM-DD" 
													className="col-md-12 form-control" selected={startengageDate} placeholderText={t('enter_the_engagement_date')} onChange={(date) => setEnagageDate(date)} />
														{startengageDate===null && <p className="error">{t('engagement_date_cannot_be_blank')}</p>}                                     
												</div>
											</div>
											<div className="form-group row">
												<div className="col-xl-4">
												  <label className="mt-2" htmlFor="SuccessStory_marriage_date">{t('marriage_date')}<span className="required">*</span></label>
												</div>
												<div className="col-xl-8">
												  <DatePicker format="YYYY-MM-DD" 
												  className="col-md-12 form-control" placeholderText="Enter the marriage date" selected={startmarriageDate} onChange={(date) => setMarriageDate(date)} />
														{startmarriageDate===null && <p className="error">{t('marriage_date_cannot_be_blank')}</p>}                                     
												  </div>
											</div>
											<div className="form-group row">
												<div className="col-xl-4">
												  <label className="mt-2 required" htmlFor="SuccessStory_marriage_photo">Marriage Photo <span className="required">*</span></label>						
												</div>
												<div className="col-xl-8">
												  <div className="custom-file-label low offset" style={{left:"10px"}}>{picture.name!=='' ? picture.name : t('please_upload_image')}</div>
														<input 
														 type="file"
														  accept="image/*" 
														  className="form-control custom-file-input " 
														  {...register("marriage_photo",{
														  	onChange : (e) => onChangePicture(e)
														  })}  
														   />	
														  {inputErrors.required===1 && <p className="error">{t('marriage_photo_is_required')}</p>}                                     
														  {inputErrors.file===1 && <p className="error">{t('horoscope_file_type_is_invalid')}</p>}                                     
													</div>
											  </div>
											  <div className="form-group row">
													<div className="col-xl-4">
												  	<label className="mt-2">{t('your_story')}</label>
													</div>
													<div className="col-xl-8">
													  <textarea placeholder={t('enter_your_story_here')} className="form-control" {...register("success_story")}></textarea>
													</div>
											  </div>
											  <div className="form-group mt-4">
													<div className="col-xl-16 text-center">
													  <input onClick={(e) => setIsSubmit(1)}  className="btn btnSucPost" type="submit" name="yt0" value={t('submit')} />									
													</div>
											  </div>
							  			</form>
							  		</div>
								 	</div>
								</div>
							</div>
						</div>
		)
}