import React, { useEffect,useState } from 'react'
import { useNavigate,useLocation } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Axios from "axios"
import Select from 'react-select';
import Constants from "../services/Constants";


export default function RegiterTwoComponent(){
	const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [issubmit,setIsSubmit]= useState(0);
  const [isvalid,setIsValid]= useState(0);
  const {handleSubmit } = useForm();
  const [ascAge,setAscAge] = useState("");
	const [descAge,setDescAge] = useState("");
	const [ascHeight,setAscHeight] = useState(0);
	const [descHeight,setDescHeight] = useState(0);
	const [martialStatus,setMartialStatus] = useState("");
	const [getReligionData,setgetReligion] = useState([]);
	const [getCountryData,setgetCountry] = useState([]);
	const [getMotherToungueData,setgetMotherTongue] = useState([]);
	const [inputValue,setInputValues] = useState({
  		religionID : 0, casteID : 0,subCasteID: 0, countryID : 0, stateID : 0,cityID : [],motherToungue : 0
  });
  const [inputErrors,setInputErrors]= useState({
  		religion :"", caste : "",subcaste : "", country : "", state : "", motherToungue : "", city : ""
  });
  const [datas,setDatas] = useState({ caste : [], subcaste : [], state : [],  city : [] });	

  useEffect(() => {
    Axios.get(Constants.API_URL+"getdropdowndata").then((response)=>{
        if(response.data.data){
          setgetReligion(response.data.data.getReligion);
          setgetCountry(response.data.data.getCountry);
          setgetMotherTongue(response.data.data.getMotherToungue);
        }
      }).catch(errors => {
          console.log(errors);
      });
  },[])

   if(issubmit){
   	setIsValid(0);
   	if(ascAge==="") setAscAge(0);
		if(descAge==="") setDescAge(0);
		if(martialStatus==="") setMartialStatus(0);
   	if(inputValue.motherToungue===0) setInputErrors(previousState => { return {...previousState, motherToungue : 0} });
  	if(inputValue.religionID===0) setInputErrors(previousState => { return {...previousState, religion : 0} });
  	if(inputValue.casteID===0) setInputErrors(previousState => { return {...previousState, caste : 0} });
  	if(inputValue.subCasteID===0) setInputErrors(previousState => { return {...previousState, subcaste : 0} });
  	if(inputValue.countryID===0) setInputErrors(previousState => { return {...previousState, country : 0} });
  	if(inputValue.stateID===0) setInputErrors(previousState => { return {...previousState, state : 0} });
  	if(inputValue.cityID.length===0) setInputErrors(previousState => { return {...previousState, city : 0} });
  	setIsSubmit(0);
  }


  const onSubmit = (data) => {
  	if(ascAge!==0 && descAge!==0 && martialStatus!==0 
  		&& inputValue.motherToungue!==0 && inputValue.religionID!==0 && inputValue.casteID!==0 &&
  		inputValue.subCasteID!==0 && inputValue.countryID!==0 && inputValue.stateID!==0 && inputValue.cityID.length>0){
  		Axios.post(Constants.API_URL+"partnerpreference",{
        user_id: localStorage.getItem("registerid"), age: ascAge, age1: descAge,
        height: ascHeight, height1: descHeight, marital: martialStatus,
        religion: inputValue.religionID, caste: inputValue.casteID ,
        subcaste: inputValue.subCasteID , country: inputValue.countryID ,
        state: inputValue.stateID , city: inputValue.cityID , toungue: inputValue.motherToungue ,
      }).then((response)=>{
        	navigate('/registerthree',{state:{title:true,message:response.data.message,id:localStorage.getItem("registerid"),mobile:data.username}} )
      }).catch((err) => {
        console.log(err);
      })
  	}
  };

	function handleReligionChange(e) {
		if(e.value!==''){
			setDatas(previousState => { return {...previousState,caste:[],subcaste:[]}});
			setInputErrors(previousState => { return {...previousState, religion : 1} });
			setInputValues(previousState => { return {...previousState, religionID : e.value} });
			Axios.get(Constants.API_URL+"religion/"+e.value+"/0/0").then((response)=>{
				if(response.data.data){
					setDatas(previousState => { return {...previousState, caste : response.data.data} });
				}
			});
		}
	}

	function handleCasteChange(e) {
		if(e.value!==''){
			setInputErrors(previousState => { return {...previousState, caste : 1} });
			setInputValues(previousState => { return {...previousState, casteID : e.value} });
			Axios.get(Constants.API_URL+"religion/"+inputValue.religionID+"/"+e.value+"/0").then((response)=>{
					if(response.data.data){
						setDatas(previousState => { return {...previousState, subcaste : response.data.data} });
					}
			});
		}
	}

	function handleCountryChange(e) {
		if(e.value!==''){ 
			setDatas(previousState => { return {...previousState,state:[],city:[]}});
			setInputErrors(previousState => { return {...previousState, country : 1} });
			setInputValues(previousState => { return {...previousState, countryID : e.value} });
			Axios.get(Constants.API_URL+"location/"+e.value+"/0/0").then((response)=>{
				if(response.data.data){
						setDatas(previousState => { return {...previousState, state : response.data.data} });
				}
			});
		}
	}

	function handleStateChange(e) {
		if(e.value!==''){ 
			setInputErrors(previousState => { return {...previousState, state : 1} });
			setInputValues(previousState => { return {...previousState, stateID : e.value} });
			Axios.get(Constants.API_URL+"location/"+inputValue.countryID+"/"+e.value+"/0").then((response)=>{
					if(response.data.data){
						setDatas(previousState => { return {...previousState, city : response.data.data} });
					}
			});
		}
	}

	const handleMotherToungue = (e) => {
		if(e.value!==''){
			setInputValues(previousState => { return {...previousState,motherToungue:e.value}});
			setInputErrors(previousState => { return {...previousState, motherToungue : 1}});
		}
	}

	const handleSubCasteChange = (e) => {
		if(e.value!==''){
			setInputValues(previousState => { return {...previousState,subCasteID:e.value}});
			setInputErrors(previousState => { return {...previousState, subcaste : 1}});
		}
	}

	const handleCityChange = (e) => {
		if(e.value!==''){
			setInputValues(previousState => { return {...previousState,cityID:Array.isArray(e) ? e.map(x => x.value) : []}})
			setInputErrors(previousState => { return {...previousState, city : 1}});
		}
	}

  useEffect(() => {
    if(location.state!=null){
      if(location.state.title!==null){
	  		var title = location.state.title;
		    if(title===true){
		    	if(location.state.error===true) toast.error(location.state.message);
	        else toast.success(location.state.message);
	        if(location.state.title) navigate(location.pathname, { replace: true });
		  	}
			}
		}
	},[location,navigate]);

  return (
  	<div className="container mt-5">
  	<ToastContainer />
			<div className="row">
				<div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 col-12">
				  <div className="gtRegMain">
	  				<h3 className="gtPageTitle">{t('partner_preference')}</h3>
  					<p className="gtPageSubTitle">{t('partner_preference_description')}</p>
	  				<form onSubmit={handleSubmit(onSubmit)}> 
					  <p>
					    <b>
					      <span className="text-danger">*</span>{t('all_fields_are_mandatory')}</b>
					  </p>
					  <div className="form-group row">
					    <div className="col-xl-4 col-md-4">
					      <label className=" required">{t('age')} 
					        <span className="required">*</span></label>              
					    </div>
					    <div className="col-xl-8 col-md-8">
					      <div className="row">
					        <div className="col-xl-6 col-6">
					          <Select onChange={(e) => setAscAge(e.value)} options = {Constants.getAgeAsc} defaultValue={Constants.prompt}>
										</Select> 
										{(isvalid===0 && ascAge===0) && <span className="error-msg text-left">{t('please_select_any_one')}</span>}                                                        
					        </div>
					        <div className="col-xl-6 col-6">
		          			<Select onChange={(e) => setDescAge(e.value)} options = {Constants.getAgeDesc} defaultValue={Constants.prompt}>
										</Select>     
										{(isvalid===0 && descAge===0) && <span className="error-msg text-left">{t('please_select_any_one')}</span>}  
					        </div>
					      </div>
					    </div>
					  </div>
					  <div className="form-group row">
					    <div className="col-xl-4 col-md-4">
					      <label>{t('height')}</label>
					    </div>
					    <div className="col-xl-8 col-md-8">
					      <div className="row">
					        <div className="col-xl-6 col-6">
					         	<Select  onChange={(e) => setAscHeight(e.value)}  options = {Constants.getHeightAsc} defaultValue={Constants.prompt}>
										</Select>                                     
					        </div>
					        <div className="col-xl-6 col-6">
					          <Select  onChange={(e) => setDescHeight(e.value)}  options = {Constants.getHeightDesc} defaultValue={Constants.prompt}>
										</Select>                                     
					        </div>
					      </div>
					    </div>
					  </div>
					  <div className="form-group row">
					    <div className="col-xl-4 col-md-4">
					      <label className=" required">{t('martial_status')}<span className="required">*</span></label>               
					    </div>
					    <div className="col-xl-8 col-md-8">
					      <Select onChange={(e) => setMartialStatus(e.value)}  options = {Constants.getMartialStatus}  defaultValue={Constants.prompt}>
								</Select> 
								{(isvalid===0 && martialStatus===0) && <span className="error-msg text-left">{t('martialstatus_is_required')}</span>}                                                                       
					    </div>
					  </div>
					  <div className="form-group row">
					    <div className="col-xl-4 col-md-4">
					      <label className=" required">{t('mother_toungue')}
					      <span className="required">*</span></label>               
					    </div>
					    <div className="col-xl-8 col-md-8">
								<Select options={getMotherToungueData} defaultValue={Constants.prompt}
										onChange={(e) => handleMotherToungue(e)} >
								</Select>      
								{(isvalid===0 && inputErrors.motherToungue===0) && <span className="error">{t('mother_toungue_cannot_be_blank')}</span>}                                                               
					    </div>
					  </div>
					  <div className="form-group row">
					    <div className="col-xl-4 col-md-4">
					      <label className=" required">{t('religion')} <span className="required">*</span></label>                
					    </div>
					    <div className="col-xl-8 col-md-8">
					      <Select options={getReligionData} defaultValue={Constants.prompt}
								 onChange={(e) => handleReligionChange(e)} >
								</Select>      
								{(isvalid===0 && inputErrors.religion===0) && <span className="error">{t('religion_cannot_be_blank')}</span>}  
					    </div>
					  </div>
					  <div className="form-group row">
					    <div className="col-xl-4 col-md-4">
					      <label className=" required">{t('caste')} 
					      <span className="required">*</span></label>               
					    </div>
					    <div className="col-xl-8 col-md-8">
					      <Select options={datas.caste} value={inputValue.casteID!==null ? datas.caste.filter(obj => obj.value === inputValue.casteID) : ''}
								 placeholder={t('select_any_one')} defaultValue={Constants.prompt} onChange={(e) => handleCasteChange(e)} >
								</Select>      
								{(isvalid===0 && inputErrors.caste===0) && <span className="error">{t('caste_cannot_be_blank')}</span>}                             
					    </div>
					  </div>
					  <div className="form-group row">
					    <div className="col-xl-4 col-md-4">
					      <label className=" required">{t('sub_caste')} <span className="required">*</span></label>                            
					    </div>
					    <div className="col-xl-8 col-md-8">
					      <Select options={datas.subcaste} value={inputValue.subCasteID!==null ? datas.subcaste.filter(obj => obj.value === inputValue.subCasteID) : ''}
								defaultValue={Constants.prompt} placeholder={t('select_any_one')} onChange={(e) => handleSubCasteChange(e)} >
								</Select>      
								{(isvalid===0 && inputErrors.subcaste===0) && <span className="error">{t('subcaste_cannot_be_blank')}</span>}                                                       
					    </div>
					  </div>
					  <div className="form-group row">
					    <div className="col-xl-4 col-md-4">
					      <label className=" required">{t('country')} <span className="required">*</span></label>               
					    </div>
					    <div className="col-xl-8 col-md-8">
					      <Select options={getCountryData} defaultValue={Constants.prompt}
										 onChange={(e) => handleCountryChange(e)} >
								</Select>      
								{(isvalid===0 && inputErrors.country===0) && <span className="error">{t('country_cannot_be_blank')}</span>}                                                               
					    </div>
					  </div>
					  <div className="form-group row">
					    <div className="col-xl-4 col-md-4">
					      <label className=" required">{t('state')} 
					        <span className="required">*</span>
					      </label>                
					    </div>
					    <div className="col-xl-8 col-md-8">
					      <Select options={datas.state}  value={inputValue.stateID!==null ? datas.state.filter(obj => obj.value === inputValue.stateID) : ''}
									placeholder={t('select_any_one')} defaultValue={Constants.prompt}
									onChange={(e) =>  handleStateChange(e)} >
								</Select>      
								{(isvalid===0 && inputErrors.state===0) && <span className="error">{t('state_cannot_be_blank')}</span>}                                         
					    </div>
					  </div>
					  <div className="form-group row">
					    <div className="col-xl-4 col-md-4">
					      <label className=" required">{t('city')}  <span className="required">*</span></label>    
					    </div>
					    <div className="col-xl-8 col-md-8">
					      <Select  options={datas.city}   value={datas.city.length>0 ? datas.city.filter(obj => inputValue.cityID.includes(obj.value)) :[]}   
										 defaultValue={Constants.prompt} placeholder={t('select_any_one')}
								 onChange={(e) => handleCityChange(e) } 
								 isMulti 
								 isClearable />
								   
								{(isvalid===0 && inputErrors.city===0) && <span className="error">{t('city_cannot_be_blank')}</span>}      
					    </div>
					  </div>  
					  <div className="row" style={{'marginTop':'50px'}}>
					    <div className="col-xl-4 offset-xl-4">
					      <div className="row">
					        <div className="col-xl-12">
					          <input onClick={(e) => setIsSubmit(1)} style={{'backgroundColor':'#dfbb26'}} className="btn btnRegFclear btn-block" type="submit" name="yt0" value="Submit" />            
					        </div>
					      </div>
					    </div>
					  </div>                  
					</form>
        </div>
      </div>
    </div>
  </div>
		)
	}