import Axios from "axios"
import { Link,useNavigate } from "react-router-dom";
import { useEffect,useState } from "react";
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';
import Constants from "../services/Constants";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.min.css';
import 'owl.carousel/dist/assets/owl.theme.default.min.css';

export default function InnerCarouselComponent ({data,count,update,setUpdate,userPaid,userToken}){
	const [prefferedUsers,setPrefferedUsers] = useState([]);
	const navigate = useNavigate();
	const { t } = useTranslation();
	let userId = parseInt(localStorage.getItem('userID'));
	let userTransaction  = parseInt(localStorage.getItem('userTransaction'));
	let paidUser = parseInt(userPaid);
	
	useEffect(() => {
			setPrefferedUsers(data);
	},[data]);

	const getAge = (birthDate) => {
     var age = Math.floor((new Date() - new Date(birthDate).getTime()) / 3.15576e+10)
     return age;
  }

	const addDefaultSrc = (e,gender) => {
  	e.target.src = Constants.IMAGE_URL+"no_image_groom.png";
  	if(gender===2){
		  e.target.src = Constants.IMAGE_URL+"no_image_bride.png";
  	}
	}

	const sendInterest = (e,userID) => {  
    if(userID!=='' && paidUser===1){
      Axios.post(Constants.API_URL+"addsendinterest",{
        api_token : userToken,profile_id : userID
      }).then((response)=>{
        setUpdate(update + 1);
        if(response.data.error===true) toast.error(response.data.message);
        else toast.success(response.data.message);
      });
    }else{ 
    	if(paidUser===0  || userTransaction===0){
        navigate('/memberplan',{state:{title:true,error:true,message:(userTransaction!==0) ? t('please_contact_support') : (userTransaction===0 ? t('please_add_a_membership') : t('please_login_to_continue'))}})
    	}else{
        navigate('/error',{state:{title:true,error:true,message:t('something_went_wrong')}})
    	}
    }
  }

		return(
			  <div>
		      <OwlCarousel className="owl-theme" items="1" autoplay nav loop>
		        {prefferedUsers && prefferedUsers.map((users,index) => (
		          <div className='item' key={users.ud_id}>
	              <div className="profile-image" style={{'display':'flex','alignItem':'center','justifyContent':'center'}}>
	              	{(((users.ud_photo_privacy===2 && paidUser===1) || (users.ud_photo_privacy===1  && userToken!==null)) && users.ud_photo_privacy!==0) && 
               		<img alt={users.ud_matri_id} onError={(e) => addDefaultSrc(e,users.ud_gender)} src={users.ud_pic_small!=="" ? Constants.IMAGE_URL+"pictures/"+users.ud_pic_small : (users.ud_gender===1 ? require('../assets/images/no_image_groom.png') : require('../assets/images/no_image_bride.png'))} 
                  className="img-responsive" style={{'maxWidth':'150px','maxHeight':'100px'}}  />}
                    {(users.ud_photo_privacy===0 || (users.ud_photo_privacy===2 && paidUser===0)) && 
               		<img alt={users.ud_matri_id} onError={(e) => addDefaultSrc(e,users.ud_gender)} src={users.ud_gender===1 ? require('../assets/images/no_image_groom.png') : require('../assets/images/no_image_bride.png')} 
                 	className="img-responsive"  style={{'maxWidth':'100px','maxHeight':'100px',}}  />}
	                <div className="card-body" style={{'boxShadow': '1px 1px 1px #fff','padding':'10px 0px 15px 16px'}}>
          					{(paidUser===0 || userToken===null || userTransaction===0) && 
                    <Link to={userToken!==null ? "/memberplan" : "/login"}
                    state={{title:true,error:true,message:(userTransaction!==0) ? t('please_contact_support') : (userTransaction===0 ? t('please_add_a_membership') : t('please_login_to_continue'))}}>
                      <div style={{'color':'#dfbb26'}} className="gtSMresultName"> 
                      {users.ud_firstname.length>10 ? users.ud_firstname.substring(0,7)+'...' : users.ud_firstname}<br />
                      ({users.ud_matri_id})</div>
                    </Link>}
                  	{(paidUser===1 && userToken!==null) && 
                     <Link to={`/viewprofile/${users.ud_id}`}>
                      <div style={{'color':'#dfbb26'}} className="gtSMresultName">
                      {users.ud_firstname ? users.ud_firstname.substring(0,7)+'...' : users.ud_firstname}<br />
                      ({users.ud_matri_id})</div>
                    </Link>}
	                  	<ul>
	                    <li>{getAge(users.ud_dob)} {t('year')},<br /></li>
                      <li>{users.ud_marital===1 ? t('never_married') : t('divorced')},<br /></li>
                      <li>{users.rel_name},{users.caste_name},<br/></li>
                      <li>{users.state_name},{users.ctry_name}</li>                      
	                    <div className="text-center mt-2">
	                      <span style={{'color':'#fff'}}>
	                        {(users.interest_user_id!==userId || users.interest_status===null) && 
                          <button style={{'color':'#fff'}}  onClick={(e) => sendInterest(e,users.ud_id)} className="btn gtBtnExpSM mt-2"><i className="fas fa-heart mr-2"></i>{t('send_interest')}
	                        </button>}
	                        {(users.interest_user_id===userId && users.interest_status===0) && <button style={{'color':'#fff'}} className="btn gtBtnExpSM mt-2"><i className="fas fa-heart mr-2"></i>{t('request_sent')}
	                        </button>}
	                        {(users.interest_user_id===userId && users.interest_status>0) && <Link to={`/composemessage/${users.ud_id}`} style={{'color':'#fff'}} className="btn gtBtnExpSM mt-2"><i className="fas fa-envelope mr-2"></i>{t('send_message')}
	                        </Link>}
	                      </span>                         
	                    </div>
	                  </ul>
	                </div>
	              </div>
		          </div>
		       	))}
		      </OwlCarousel>
		    </div>
		)
}