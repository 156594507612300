import React, { useEffect,useState } from 'react'
import { useNavigate,useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import Constants from "../services/Constants";
import Axios from "axios"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function VerifyComponenet() {
	
  const [otp, setOtp] = useState(new Array(4).fill(""));
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const {  handleSubmit } = useForm();
  const [inputerrors,setInputerrors]  = useState(false);

	const handleChange = (element, index) => {
			setInputerrors(0);
			if (isNaN(element.target.value)) return false;
			setOtp([...otp.map((d, idx) => (idx === index ? element.target.value : d))]);
			if (element.target.nextSibling) {
					element.target.nextSibling.focus();
			}
	};
	const onSubmit = (data) => {
		var code = parseInt(otp.join(""));
		if(parseInt(localStorage.getItem("registerotp"))===code){
			setInputerrors(0);
			Axios.post(Constants.API_URL+"otpverify",{
				otp:code,
				mobile:localStorage.getItem("registermobile")
				}).then((response)=>{
					if (response.data.data) navigate('/registerone',{state:{title:true,message:t('user_verify_success')}});
				}).catch((err) => {
					console.log(err);
				})
		}else{
			setInputerrors(1);
		}
	};

	useEffect(() => {
    var title = location.state.title;
      if(title===true){
	    	if(location.state.error===true) toast.error(location.state.message);
        else toast.success(location.state.message);
       	if(location.state.title) navigate(location.pathname,{state:{}});
    	}
  },[location,navigate])

		return(
			<form onSubmit={handleSubmit(onSubmit)} className="col-xl-6 offset-xl-3 mt-5" id="verify-form" >            
			  <ToastContainer />
			  <center>
			    <div className="gtMobVerify">
			      <h4>{t('mobile_verification')}</h4>
			      <p>{t('enter_your_one_time_password')} : {localStorage.getItem("registerotp")}</p>
			      <h3><span className="">{t('mobile')} </span> : {localStorage.getItem("registermobile")}</h3>
			      <div className="form-group">
			        <div className="pincode-input-container">
								{otp.map((data, index) => {
								 var	addClass;
							   addClass = 'form-control pincode-input-text mid';
								 var	orgClass = 'form-control pincode-input-text';
								 addClass  = (index===0) ? orgClass+" first" : addClass;
							   addClass  = (index===3) ? orgClass+" last" : addClass;
										return (
												<input
													className={addClass}
													required={true}
													type="text"
													name="otp"
													maxLength="1"
													key={index}
													value={data}
													onChange={e => handleChange(e, index)}
												/>
										);
								})}
			          {inputerrors===1 && <p className="error">{t('otp_is_invalid')}</p>}
			        </div>
			      </div>
			      <div className="form-group">
			        <input type="submit" name="submit" className="btn btnRegFsubmit" value={t('submit')} />
			        <br />
			        <br />
			        <input type="submit" onClick={() => setOtp(new Array(4).fill(""))} className="btn btn-small" style={{'backgroundColor':'#dfbb26','color':'#fff'}} value={t('clear')} />
			      </div>
			    </div>
			  </center>
			</form>
		)
}