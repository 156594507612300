import React , {Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import './i18next';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
	  <Suspense  fallback={<div style={{'width':'100%','backgroundSize':'cover','display':'flex','justifyContent':'center'}}><img src={require('./assets/images/loading.gif')} alt={"Loading...."} /></div>}>
	    <BrowserRouter>
	      <App />
	    </BrowserRouter>
	  </Suspense>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
