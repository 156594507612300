const APP_URL = 'https://www.leomatrimony.com/';
const API_URL = 'https://www.leomatrimony.com/api/v1/';
const ADMIN_URL = 'https://www.leomatrimony.com/admin/';
const IMAGE_URL = 'https://www.leomatrimony.com/admin/storage/app/public/';

const VERIFY = false;

const prompt = { "value": "","label": "Select any one" };

const prompt_code = { "value": "","label": "Select code" };

const zodiacprompt = { "value": "","label": "V ▼" };

const rasi12_Url= { 'one' : require('../assets/images/numbers/12.jpg')};
const rasi11_Url= { 'one' : require('../assets/images/numbers/11.jpg')};
const rasi10_Url= { 'one' : require('../assets/images/numbers/10.jpg')};
const rasi9_Url= { 'one' : require('../assets/images/numbers/9.jpg')};
const rasi8_Url= { 'one' : require('../assets/images/numbers/8.jpg')};
const rasi7_Url= { 'one' : require('../assets/images/numbers/7.jpg')};
const rasi6_Url= { 'one' : require('../assets/images/numbers/6.jpg')};
const rasi5_Url= { 'one' : require('../assets/images/numbers/5.jpg')};
const rasi4_Url= { 'one' : require('../assets/images/numbers/4.jpg')};
const rasi3_Url= { 'one' : require('../assets/images/numbers/3.jpg')};
const rasi2_Url= { 'one' : require('../assets/images/numbers/2.jpg')};
const rasi1_Url= { 'one' : require('../assets/images/numbers/1.jpg')};


const getAgeAsc = [
    // { "value":"","label":"Select any one"},
    { "value": 18, "label": "18 years" },
    { "value": 19, "label": "19 years" },
    { "value": 20, "label": "20 years" },
    { "value": 21, "label": "21 years" },
    { "value": 22, "label": "22 years" },
    { "value": 23, "label": "23 years" },
    { "value": 24, "label": "24 years" },
    { "value": 25, "label": "25 years" },
    { "value": 26, "label": "26 years" },
    { "value": 27, "label": "27 years" },
    { "value": 28, "label": "28 years" },
    { "value": 29, "label": "29 years" },
    { "value": 30, "label": "30 years" },
    { "value": 31, "label": "31 years" },
    { "value": 32, "label": "32 years" },
    { "value": 33, "label": "33 years" },
    { "value": 34, "label": "34 years" },
    { "value": 35, "label": "35 years" },
    { "value": 36, "label": "36 years" },
    { "value": 37, "label": "37 years" },
    { "value": 38, "label": "38 years" },
    { "value": 39, "label": "39 years" },
    { "value": 40, "label": "40 years" },
    { "value": 41, "label": "41 years" },
    { "value": 42, "label": "42 years" },
    { "value": 43, "label": "43 years" },
    { "value": 44, "label": "44 years" },
    { "value": 45, "label": "45 years" },
    { "value": 46, "label": "46 years" },
    { "value": 47, "label": "47 years" },
    { "value": 48, "label": "48 years" },
    { "value": 49, "label": "49 years" },
    { "value": 50, "label": "50 years" },
    { "value": 51, "label": "51 years" },
    { "value": 52, "label": "52 years" },
    { "value": 53, "label": "53 years" },
    { "value": 54, "label": "54 years" },
    { "value": 55, "label": "55 years" },
    { "value": 56, "label": "56 years" },
    { "value": 57, "label": "57 years" },
    { "value": 58, "label": "58 years" },
    { "value": 59, "label": "59 years" },
    { "value": 60, "label": "60 years" }
  ];

	const getAgeDesc = [
    // {"value":"","label":"Select any one"},
    {"value":60,"label":"60 years"},
    {"value":59,"label":"59 years"},
    {"value":58,"label":"58 years"},
    {"value":57,"label":"57 years"},
    {"value":56,"label":"56 years"},
    {"value":55,"label":"55 years"},
    {"value":54,"label":"54 years"},
    {"value":53,"label":"53 years"},
    {"value":52,"label":"52 years"},
    {"value":51,"label":"51 years"},
    {"value":50,"label":"50 years"},
    {"value":49,"label":"49 years"},
    {"value":48,"label":"48 years"},
    {"value":47,"label":"47 years"},
    {"value":46,"label":"46 years"},
    {"value":45,"label":"45 years"},
    {"value":44,"label":"44 years"},
    {"value":43,"label":"43 years"},
    {"value":42,"label":"42 years"},
    {"value":41,"label":"41 years"},
    {"value":40,"label":"40 years"},
    {"value":39,"label":"39 years"},
    {"value":38,"label":"38 years"},
    {"value":37,"label":"37 years"},
    {"value":36,"label":"36 years"},
    {"value":35,"label":"35 years"},
    {"value":34,"label":"34 years"},
    {"value":33,"label":"33 years"},
    {"value":32,"label":"32 years"},
    {"value":31,"label":"31 years"},
    {"value":30,"label":"30 years"},
    {"value":29,"label":"29 years"},
    {"value":28,"label":"28 years"},
    {"value":27,"label":"27 years"},
    {"value":26,"label":"26 years"},
    {"value":25,"label":"25 years"},
    {"value":24,"label":"24 years"},
    {"value":23,"label":"23 years"},
    {"value":22,"label":"22 years"},
    {"value":21,"label":"21 years"},
    {"value":20,"label":"20 years"},
    {"value":19,"label":"19 years"},
    {"value":18,"label":"18 years"}
  ];

 	const getUserType = [
	 	// {"value":"","label":"Select any one"},
	 	{"value":1,"label":"Groom"},
	 	{"value":2,"label":"Bride"}
 	];

    const getUserReason = [
        {"value":"","label":"Select any one"},
        {"value":1,"label":"I found my life partner"},
        {"value":2,"label":"Dont find any match on website"},
        {"value":3,"label":"Service related problem"},
        {"value":4,"label":"Dont want to mention"}
    ];

    const getUserEmployeeDetails = [
        {"value":1,"label":"Government"},
        {"value":2,"label":"Private"},
        {"value":3,"label":"Business"},
        {"value":4,"label":"Defence"},
        {"value":5,"label":"Self Employee"},
        {"value":6,"label":"Not Working"}
    ];

    const getUserPhysicalStatus= [
        {"value":"","label":"Select any one"},
        {"value":1,"label":"Normal"},
        {"value":2,"label":"Physically Challenged"}
    ];

    const getUserComplexion= [
        {"value":"","label":"Select any one"},
        {"value":1,"label":"Very Fair"},
        {"value":2,"label":"Fair"},
        {"value":3,"label":"Wheatish"},
        {"value":4,"label":"Wheatish Brown"},
        {"value":5,"label":"Dark"}
    ];

    const getUserBodyType= [
        {"value":"","label":"Select any one"},
        {"value":1,"label":"Slim"},
        {"value":2,"label":"Average"},
        {"value":3,"label":"Athletic"},
        {"value":4,"label":"Heavy"}
    ];

    const getChildrenCount = [
        {"value":"","label":"Select any one"},
        {"value":1,"label":"No Children"},
        {"value":2,"label":"1"},
        {"value":3,"label":"2"},
        {"value":4,"label":"3"},
        {"value":5,"label":"4+"},
    ];

    const getChildrenStatus = [
        {"value":"","label":"Select any one"},
        {"value":1,"label":"Living with Me"},
        {"value":2,"label":"Not Living with Me"}
    ];
    
 	const getHeightAsc = [
 		{"value":"","label":"Select any one"},
 		{"value":1,"label":"Below 4ft 6in -137cm"},
 		{"value":2,"label":"4ft 6in -137cm"},
 		{"value":3,"label":"4ft 7in -139cm"},
 		{"value":4,"label":"4ft 8in - 142cm"},
 		{"value":5,"label":"4ft 9in - 144cm"},
 		{"value":6,"label":"4ft 10in - 147cm"},
 		{"value":7,"label":"4ft 11in - 149cm"},
 		{"value":8,"label":"5ft - 152cm"},
 		{"value":9,"label":"5ft 1in - 154cm"},
 		{"value":10,"label":"5ft 2in - 157cm"},
 		{"value":11,"label":"5ft 3in - 160cm"},
 		{"value":12,"label":"5ft 4in - 162cm"},
 		{"value":13,"label":"5ft 5in - 165cm"},
 		{"value":14,"label":"5ft 6in - 167cm"},
 		{"value":15,"label":"5ft 7in - 170cm"},
 		{"value":16,"label":"5ft 8in - 172cm"},
 		{"value":17,"label":"5ft 9in - 175cm"},
 		{"value":18,"label":"5ft 10in - 177cm"},
 		{"value":19,"label":"5ft 11in - 180cm"},
 		{"value":20,"label":"6ft - 182cm"},
 		{"value":21,"label":"6ft 1in - 185cm"},
 		{"value":22,"label":"6ft 3in - 190cm"},
 		{"value":23,"label":"6ft 5in - 195cm"},
 		{"value":24,"label":"6ft 7in - 200cm"},
 		{"value":25,"label":"6ft 9in - 205cm"},
 		{"value":26,"label":"Above 7ft - 213cm"}
 	]

 	const getHeightDesc  = [
 		{"value":"","label":"Select any one"},
 		{"value":26,"label":"Above 7ft - 213cm"},
 		{"value":25,"label":"6ft 9in - 205cm"},
 		{"value":24,"label":"6ft 7in - 200cm"},
 		{"value":23,"label":"6ft 5in - 195cm"},
 		{"value":22,"label":"6ft 3in - 190cm"},
 		{"value":21,"label":"6ft 1in - 185cm"},
 		{"value":20,"label":"6ft - 182cm"},
 		{"value":19,"label":"5ft 11in - 180cm"},
 		{"value":18,"label":"5ft 10in - 177cm"},
 		{"value":17,"label":"5ft 9in - 175cm"},
 		{"value":16,"label":"5ft 8in - 172cm"},
 		{"value":15,"label":"5ft 7in - 170cm"},
 		{"value":14,"label":"5ft 6in - 167cm"},
 		{"value":13,"label":"5ft 5in - 165cm"},
 		{"value":12,"label":"5ft 4in - 162cm"},
 		{"value":11,"label":"5ft 3in - 160cm"},
 		{"value":10,"label":"5ft 2in - 157cm"},
 		{"value":9,"label":"5ft 1in - 154cm"},
 		{"value":8,"label":"5ft - 152cm"},
 		{"value":7,"label":"4ft 11in - 149cm"},
 		{"value":6,"label":"4ft 10in - 147cm"},
 		{"value":5,"label":"4ft 9in - 144cm"},
 		{"value":4,"label":"4ft 8in - 142cm"},
 		{"value":3,"label":"4ft 7in -139cm"},
 		{"value":2,"label":"4ft 6in -137cm"},
 		{"value":1,"label":"Below 4ft 6in -137cm"}
 	]

 	const getMartialStatus = [
	 	// {"value":"","label":"Select any one"},
	 	{"value":1,"label":"Never Married"},
	 	{"value":2,"label":"Divorced"}
 	];


    const getDietStatus = [
        {"value":"","label":"Select any one"},
        {"value":1,"label":"Vegetarion"},
        {"value":2,"label":"Non Vegetarion"},
        {"value":3,"label":"Eggetarion"},
    ];

    const getUserGender = [
        // {"value":"","label":"Select any one"},
        {"value":1,"label":"Male"},
        {"value":2,"label":"Female"}
    ];

     const getUserNoofChildren = [
        {"value":"","label":"Select any one"},
        {"value":1,"label":"No Children"},
        {"value":2,"label":"1"},
        {"value":3,"label":"2"},
        {"value":4,"label":"3"},
        {"value":5,"label":"4+"}
    ];

    const getUserChildrenStatus = [
        {"value":"","label":"Select any one"},
        {"value":1,"label":"Living with Me"},
        {"value":2,"label":"Not Living with Me"}
    ];

    const getUserStarNakshatra = [
        {"value":"","label":"Select any one"},
        {"value":1,"label":"Anusham"},
        {"value":2,"label":"Aswathi"},
        {"value":3,"label":"Aswini"},
        {"value":4,"label":"Avittam"},
        {"value":5,"label":"Ayilyam"},
        {"value":6,"label":"Bharani"},
        {"value":7,"label":"Chitirai"},
        {"value":8,"label":"Hastham"},
        {"value":9,"label":"Kettai"},
        {"value":10,"label":"Krithigai"},
        {"value":11,"label":"Maham"},
        {"value":12,"label":"Moolam"},
        {"value":13,"label":"Mrigashirisham"},
        {"value":14,"label":"Poosam"},
        {"value":15,"label":"Punarpoosam"},
        {"value":16,"label":"Puradam"},
        {"value":17,"label":"Puram"},
        {"value":18,"label":"Puratathi"},
        {"value":19,"label":"Revathi"},
        {"value":20,"label":"Rohini"},
        {"value":21,"label":"Sathayam"},
        {"value":22,"label":"Swathi"},
        {"value":23,"label":"Thiruvathirai"},
        {"value":24,"label":"Thiruvonam"},
        {"value":25,"label":"Uthradam"},
        {"value":26,"label":"Uthram"},
        {"value":27,"label":"Uthratathi"},
        {"value":28,"label":"Visakam"}
    ];

    const getUserRasiMoonSign = [
        {"value":"","label":"Select any one"},
        {"value":1,"label":"Mesham (Aries)"},
        {"value":2,"label":"Rishabam (Taurus)"},
        {"value":3,"label":"Mithunam (Gemini)"},
        {"value":4,"label":"Katagam (Cancer)"},
        {"value":5,"label":"Simmam (Leo)"},
        {"value":6,"label":"Kanni (Virgo)"},
        {"value":7,"label":"Tula (Libra)"},
        {"value":8,"label":"Viruchigam (Scorpio)"},
        {"value":9,"label":"Dhanus (Sagittarius)"},
        {"value":10,"label":"Maharam (Capricon)"},
        {"value":11,"label":"Kumbam (Aquarius)"},
        {"value":12,"label":"Meenam (Pisces)"},
        {"value":13,"label":"Does not matter"}
    ];

    const getManglikDhosam = [
        {"value":"","label":"Select any one"},
        {"value":1,"label":"Mangal Dosh"},
        {"value":2,"label":"Kaal Sarp Dosh"},
        {"value":3,"label":"Pitru Dosh"},
        {"value":4,"label":"Nadi Dosh"},
        {"value":5,"label":"Guru Chandal Dosh"},
        {"value":6,"label":"Grahan Dosh"},
        {"value":7,"label":"Gandamool Dosh"},
        {"value":8,"label":"Shani Dosh"},
        {"value":9,"label":"Shrapit Dosh"},
        {"value":10,"label":"Chandra Dosh"},
        {"value":11,"label":"Kemadruma Dosh"},
    ];

    const getUserDhosa = [
        {"value":"","label":"Select any one"},
        {"value":1,"label":"No"},
        {"value":2,"label":"Yes"},
        {"value":3,"label":"Does not matter"}
    ];

    const getThisaiIruppu = [
        {"value":"","label":"Select any one"},
        {"value":1,"label":"Kedhu"},
        {"value":2,"label":"Puthan"},
        {"value":3,"label":"Raagu"},
        {"value":4,"label":"Sani"},
        {"value":5,"label":"Santiran"},
        {"value":6,"label":"Sevvai"},
        {"value":7,"label":"Sukiran"},
        {"value":8,"label":"Suriyan"},
        {"value":9,"label":"Viyalan"},
        {"value":10,"label":"Guru"},
        {"value":11,"label":"Does not matter"},
        {"value":12,"label":"Any"},
        {"value":13,"label":"Others"}
    ];


    const getUserZodiacNO = [
        {"value":"","label":"V"},
        {"value":1,"label":"1"},
        {"value":2,"label":"2"},
        {"value":3,"label":"3"},
        {"value":4,"label":"4"},
        {"value":5,"label":"5"},
        {"value":6,"label":"6"},
        {"value":7,"label":"7"},
        {"value":8,"label":"8"},
        {"value":9,"label":"9"},
        {"value":10,"label":"10"},
        {"value":11,"label":"11"},
        {"value":12,"label":"12"}
    ];

    const getProfileCreatedFor = [
        {"value":1,"label":"Myself"},
        {"value":2,"label":"Son"},
        {"value":3,"label":"Daughter"},
        {"value":4,"label":"Brother"},
        {"value":5,"label":"Sister"},
        {"value":6,"label":"Relative"},
        {"value":7,"label":"Friend"}
    ]

    const getWeight = [
        {"value":"","label":"Select any one"},
        {"value":1,"label":"40kg-60kg"},
        {"value":2,"label":"60kg-80kg"},
        {"value":3,"label":"80kg-100kg"},
        {"value":4,"label":"100kg-120kg"},
        {"value":5,"label":"120kg-140kg"}
    ];

    const getIncome = [
        {"value":"","label":"Select any one"},
        {"value":1,"label":"Below Rs.10,000"},
        {"value":2,"label":"Rs.10,001 - 20,000"},
        {"value":3,"label":"Rs.20,001 - 30,000"},
        {"value":4,"label":"Rs.30,001 - 40,000"},
        {"value":5,"label":"Rs.40,001 - 50,000"},
        {"value":6,"label":"Rs.50,001 - 60,000"},
        {"value":7,"label":"Rs.60,001 - 70,000"},
        {"value":8,"label":"Rs.70,001 - 80,000"},
        {"value":9,"label":"Rs.80,001 - 90,000"},
        {"value":10,"label":"Rs.90,001 - 1,00,000"},
        {"value":11,"label":"Rs.1,00,001 - 1,50,000"},
        {"value":12,"label":"Rs.1,50,001 - 2,00,000"},
        {"value":13,"label":"Above Rs.2,00,000"}
    ]

    const getDay = [
        {"value":1,"label":1},
        {"value":2,"label":2},
        {"value":3,"label":3},
        {"value":4,"label":4},
        {"value":5,"label":5},
        {"value":6,"label":6},
        {"value":7,"label":7},
        {"value":8,"label":8},
        {"value":9,"label":9},
        {"value":10,"label":10},
        {"value":11,"label":11},
        {"value":12,"label":12},
        {"value":13,"label":13},
        {"value":14,"label":14},
        {"value":15,"label":15},
        {"value":16,"label":16},
        {"value":17,"label":17},
        {"value":18,"label":18},
        {"value":19,"label":19},
        {"value":20,"label":20},
        {"value":21,"label":21},
        {"value":22,"label":22},
        {"value":23,"label":23},
        {"value":24,"label":24},
        {"value":25,"label":25},
        {"value":26,"label":26},
        {"value":27,"label":27},
        {"value":28,"label":28},
        {"value":29,"label":29},
        {"value":30,"label":30},
        {"value":31,"label":31}
    ]

    const getMonth = [
        {"value":1,"label":1},
        {"value":2,"label":2},
        {"value":3,"label":3},
        {"value":4,"label":4},
        {"value":5,"label":5},
        {"value":6,"label":6},
        {"value":7,"label":7},
        {"value":8,"label":8},
        {"value":9,"label":9},
        {"value":10,"label":10},
        {"value":11,"label":11},
        {"value":12,"label":12}
    ]

    const getBloodGroups = [
        {"value":"","label":"Select any one"},
        {"value":1,'label':'A-positive (A+)'},
        {"value":2,'label':'A-negative (A-)'},
        {"value":3,'label':'B-positive (B+)'},
        {"value":4,'label':'B-negative (B-)'},
        {"value":5,'label':'AB-positive (AB+)'},
        {"value":6,'label':'AB-negative (AB-)'},
        {"value":7,'label':'O-positive (O+)'},
        {"value":8,'label':'O-negative (O-)'}
    ]

    const getFamilyValues = [
        {"value":"","label":"Select any one"},
        {"value":1,'label':'Rich'},
        {"value":2,'label':'High Class'},
        {"value":3,'label':'Upper Middle Class'},
        {"value":4,'label':'Middle Class'},
        {"value":5,'label':'Others'},
    ]


    const getYear = [
        {"value":0,"label":0},
        {"value":1,"label":1},
        {"value":2,"label":2},
        {"value":3,"label":3},
        {"value":4,"label":4},
        {"value":5,"label":5},
        {"value":6,"label":6},
        {"value":7,"label":7},
        {"value":8,"label":8},
        {"value":9,"label":9},
        {"value":10,"label":10},
        {"value":11,"label":11},
        {"value":12,"label":12},
        {"value":13,"label":13},
        {"value":14,"label":14},
        {"value":15,"label":15},
        {"value":16,"label":16},
        {"value":17,"label":17},
        {"value":18,"label":18},
        {"value":19,"label":19},
        {"value":20,"label":20}
    ]

    const getUserProperties = [
        {"value":"","label":"Select any one"},
        {"value":1,"label":"Yes"},
        {"value":2,"label":"No"}
    ];

     const getUserNoofSiblings = [
        {"value":"","label":"Select any one"},
        {"value":1,"label":"1"},
        {"value":2,"label":"2"},
        {"value":3,"label":"3"},
        {"value":4,"label":"4"},
        {"value":5,"label":"5+"}
    ];

    const Constants = {
        APP_URL,
        API_URL,
        ADMIN_URL,
        IMAGE_URL,
        prompt,
        getAgeAsc,
        getAgeDesc,
        getWeight,
        prompt_code,
        zodiacprompt,
        getIncome,
        getUserZodiacNO,
        getUserType,
        getUserDhosa,
        getHeightAsc,
        getHeightDesc,
        getUserGender,
        getThisaiIruppu,
        getMartialStatus,
        getUserNoofChildren,
        getUserRasiMoonSign,
        getUserStarNakshatra,
        getUserChildrenStatus,
        getUserPhysicalStatus,
        getUserComplexion,
        getUserBodyType,
        getDietStatus,
        getChildrenCount,
        getChildrenStatus,
        getUserReason,
        getManglikDhosam,
        getProfileCreatedFor,
        getUserEmployeeDetails,
        rasi12_Url,
        rasi11_Url,
        rasi10_Url,
        rasi9_Url,
        rasi8_Url,
        rasi7_Url,
        rasi6_Url,
        rasi5_Url,
        rasi4_Url,
        rasi3_Url,
        rasi2_Url,
        rasi1_Url,
        getDay,
        getMonth,
        getYear,
        getBloodGroups,
        getFamilyValues,
        getUserProperties,
        getUserNoofSiblings,
        VERIFY
	}


export default Constants;