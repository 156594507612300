import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Axios from "axios"
import Select from 'react-select';
import Constants from "../services/Constants";

export default function DeleteprofileComponent() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  let userToken = JSON.parse(localStorage.getItem('userToken'));
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [userreason,setUserreason]  = useState("");
  const [inputError,setInputError]  = useState(0);
  const onSubmit = (data) => {
    if(inputError===0){
       Axios.post(Constants.API_URL+"deleteprofile",
      {
        api_token : userToken,del_name : data.del_name,del_reason : userreason,
        del_description : data.del_description
      }).then((response)=>{
        if(response) 
          navigate('/home',{state:{title:true,message:response.data.message,error:response.data.error}});
      }).catch((err) => {
        console.log(err);
      })
    }
  };

  const setIsSubmit = () => {
    if(userreason==="") setInputError(1);
    else setInputError(0);
  }

  const handleReason = (e) => {
    setUserreason(e.value);
    if(e.value==="") setInputError(1);
    else setInputError(0); 
  }

    return (
        <div className="container mt-5">
          <div className="row">
            <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 col-12">
              <div className="gtRegMain">
                <h3 className="gtPageTitle">{t('delete_profile_description')}</h3>
                  <form onSubmit={handleSubmit(onSubmit)} className="mt-4" id="regfirst-form">   
                  <p><b><span className="text-danger">* </span>{t('all_fields_are_mandatory')}</b></p>
                  <div className="form-group row">
                    <div className="col-xl-4">
                      <label className="mt-2 required">{t('name')} 
                        <span className="required">*</span></label>                        
                    </div>
                    <div className="col-xl-8">
                      <input type="text" className="form-control" placeholder={t('enter_your_name')} 
                        {...register("del_name", 
                          {
                            required: true, 
                            pattern: /^[a-zA-Z]+$/,
                            maxLength: 32, 
                          }
                        )} />
                        {errors.del_name?.type==="required" && <p className="error">{t('del_name_is_required')}</p>}
                        {errors.del_name?.type==="maxLength" && <p className="error">{t('del_name_is_maxlength')}</p>}                  
                        {errors.del_name?.type==="pattern" && <p className="error">{t('del_name_is_invalid')}</p>}                  
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-xl-4">
                      <label className="mt-2 required">{t('reason')} 
                        <span className="required">*</span></label>          
                    </div>
                    <div className="col-xl-8">
                      <Select className="" options={Constants.getUserReason} onChange={(e) => handleReason(e)} 
                      placeholder={t('select_any_one')}></Select>                                         
                      {inputError===1 && <p className="error">{t('please_select_any_one')}</p>}
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-xl-4">
                      <label className="mt-2 required">{t('description')}<span className="required">*</span></label>                 
                    </div>
                    <div className="col-xl-8">
                      <input type="text" className="form-control" placeholder={t('enter_your_description')} 
                        {...register("del_description", 
                          {
                            required: true, 
                            pattern: /^[A-Za-z\s]*$/,
                            maxLength: 64, 
                          }
                        )} />
                        {errors.del_description?.type==="pattern" && <p className="error">{t('del_description_is_invalid')}</p>}                  
                        {errors.del_description?.type==="required" && <p className="error">{t('del_description_is_required')}</p>}
                        {errors.del_description?.type==="maxLength" && <p className="error">{t('del_description_is_maxlength')}</p>}                  
                    </div>
                  </div>  
                  <div className="form-group row mt-4">
                    <div className="col-xl-12 text-center">
                      <input onClick={setIsSubmit} className="btn btn-lg btnRegFsubmit"  type="submit" value={t('submit')} />                    
                    </div>
                  </div>
                </form>       
              </div>
            </div>
          </div>
        </div>
      )
}