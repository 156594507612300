import { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import Axios from "axios"
import { useForm,Controller } from 'react-hook-form';
import Constants from "../services/Constants"; 
import Select from 'react-select';
import $ from "jquery";

export default function MobileModalComponent({CountryCode,userMobiledata,issubmit,setIsSubmit}){
  let userToken = JSON.parse(localStorage.getItem('userToken'));
  const { t } = useTranslation(); 
  const {handleSubmit,getValues ,setValue, control, formState: { errors } } = useForm({
    defaultValue:{ud_phonecode :0,ud_phone:'' }});

  const onSubmit = (data) => {
    Axios.post(Constants.API_URL+"postmyprofile",{
      api_token : userToken,ud_phonecode : data.ud_phonecode,ud_phone : data.ud_phone,
    }).then((response)=>{
      setIsSubmit('');
      setIsSubmit(response.data.error+'/'+response.data.message);
      $(".closeMobModel").click();
    }).catch((err) => {
      console.log(err);
    })
  }; 

  useEffect(() => {
  },[CountryCode])

  useEffect(()=>{
    if(userMobiledata!==null){
      setValue('ud_phonecode',userMobiledata.ud_phonecode);
      setValue('ud_phone',userMobiledata.ud_phone);
    }
  },[userMobiledata,getValues,setValue]);


  return(
      <div className="modal fade gtEditForm" id="mobileModal" tabIndex="-1" role="dialog" aria-labelledby="aboutInfoModal" aria-hidden="true" data-backdrop="static">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <div className="col-12">
                <h5 className="modal-title" id="exampleModalLabel">Mobile No
                <button type="button" className="closeMobModel close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
                </h5>
              </div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className="form-horizontal">      
              <div className="modal-body">
                <div className="row">
                  <div className="container"> 
                    <div className="row">
                      <div className="form-group col-5" style={{'paddingRight':'0px'}}>
                        {(CountryCode!=null && CountryCode.length > 0) && (
                          <Controller
                           name="ud_phonecode"
                           control={control}
                           render={({ field: { onChange, value, name, ref } }) => (
                             <Select
                              inputRef={ref}
                              className="mb-4"
                              value={CountryCode.find((c) => c.value === value) || ''}
                              options={CountryCode}
                              onChange={(e) => setValue('ud_phonecode',e.value)}
                             />
                           )}
                        />)}
                        {errors.ud_phonecode && <span className="error">{t('country_code_cannot_be_blank')}</span>}
                      </div>
                      <div className="form-group col-7" style={{'paddingLeft':'0px'}}>
                          <Controller
                            name="ud_phone"
                            control={control}
                            render={({ field }) => (
                            <input  {...field}
                            className="form-control"
                             />
                            )}
                             rules={{ 
                              required: true,
                              maxLength: 10, 
                              minLength: 10, 
                              pattern:/^[0-9+-]+$/ ,
                              onChange: (e) => setValue('ud_phone',e.target.value.replace(/\D/g,''))
                            }}
                          />
                        {errors.ud_phone?.type==="required" && <p className="error">{t('mobile_is_required')}</p>}
                        {errors.ud_phone?.type==="maxLength" && <p className='error'>{t('mobile_is_maxlength')}</p>}                     
                        {errors.ud_phone?.type==="minLength" && <p className='error'>{t('mobile_is_minlength')}</p>}                     
                        {errors.ud_phone?.type==="pattern" && <p className='error'>{t('mobile_is_invalid')}</p>}                     
                        <i className="fas fa-mobile-alt fa_mob fa_alt" id="fab-mob"></i>
                      </div>
                      <div className="col-12">
                        <div className="form-group text-center">
                          <input className="btn btnRegFsubmit" name="mobile" type="submit" value={t('update')} />            
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>    
          </div>
        </div>
      </div>
    )
}