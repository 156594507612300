import Axios from "axios" 
import { useEffect,useState } from "react";
import { useTranslation } from 'react-i18next';
import Constants from "../services/Constants";  
import ListUserComponent from '../components/ListUserComponent';
import Skeleton , { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import ResultNotFoundComponent from '../components/ResultNotFoundComponent';

export default function NewsmatchesComponent() {
	let userToken = JSON.parse(localStorage.getItem('userToken'));
  const { t } = useTranslation();
  const [newMatchesUsers, setnewMatchesUsers] = useState([]);
  const [update, setUpdate] = useState(0);
  const [loading,setLoading] = useState(0);


  useEffect(() => { 
    const getRecentJoin = () => {
      Axios.post(Constants.API_URL+"getrecentjoin",{
          api_token : userToken
        }).then((response)=>{
          setnewMatchesUsers(response.data.data);  
          setLoading(1);
        });
    }
      getRecentJoin();
  },[userToken,update]);

	  return (
      <>
        {loading=== 0 && 
        <SkeletonTheme baseColor="#dfbb26" height="10px" highlightColor="#fff">
          <Skeleton />
        </SkeletonTheme>}
  	  	<div className="container mt-5">
  			  <h3 className="gtPageTitle">{t('new_matches_description')}</h3>
  			  <p className="gtPageSubTitle">{t('all_joined_members_are_register_here')}</p>
  				{newMatchesUsers.length > 0 ? <ListUserComponent  data={setnewMatchesUsers} update={update} setUpdate={setUpdate} /> : <ResultNotFoundComponent />} 
  			</div>	
      </>
	 	)
}