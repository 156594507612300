import { useEffect,useState } from "react";
import { useTranslation } from 'react-i18next';
import Axios from "axios"
import { useForm,Controller } from 'react-hook-form';
import Constants from "../services/Constants"; 
import useMotherToungue from "../hook/useMotherToungue";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import moment from 'moment';
import $ from "jquery";


export default function BasicInfoModalComponent({userBasicInfo,dateDOB,issubmit,setIsSubmit}){
	let userToken = JSON.parse(localStorage.getItem('userToken'));
  const { t } = useTranslation();
  const [getMotherToungueData] = useMotherToungue("mothertongue");
  const { register,handleSubmit : handleSubmit1,getValues,resetField,setValue, control, formState: { errors } } = useForm({
    defaultValue : {ud_firstname:"",ud_lastname:"",ud_email:"",ud_profile:"",ud_height:"",ud_dob : new Date(),
    ud_weight: "",ud_physical:"",ud_complexion:"",ud_bodytype:"",ud_marital:"",ud_child:"",
    ud_childstatus:"",ud_toungue:"",ud_diet:"",ud_smoke:"",ud_drink:""}
  });
  const [dateOfBirth,setDateOfBirth]= useState(new Date());
  const [isDate,setIsDate] = useState({value:true,error:false});
  const onBasicSubmit = (data) => {
  	if(isDate.value===false){
  		setIsDate({...isDate,error:true});
  	}
  	let dob = moment(new Date(dateOfBirth)).format('YYYY/MM/DD');
  	if(isDate.value){
	  	Axios.post(Constants.API_URL+"postmyprofile",{
	      api_token : userToken,ud_firstname : data.ud_firstname,ud_lastname : data.ud_lastname,
	      ud_email : data.ud_email,	ud_profile : data.ud_profile, ud_dob :dob, ud_height : data.ud_height,
	      ud_weight : data.ud_weight,ud_physical : data.ud_physical,ud_complexion : data.ud_complexion,
	      ud_bodytype : data.ud_bodytype,ud_marital : data.ud_marital,ud_child : data.ud_child,
	      ud_childstatus : data.ud_childstatus,ud_toungue : data.ud_toungue,ud_diet : data.ud_diet,
	      ud_smoke : data.ud_smoke,ud_drink : data.ud_drink,ud_blood_group : data.ud_blood_group,
        ud_properties : data.ud_properties,ud_bro : data.ud_bro,ud_married_bro : data.ud_married_bro,
        ud_sis : data.ud_married_sis,ud_married_sis : data.ud_married_sis,ud_address : data.ud_address,
        ud_fname :data.ud_fname,ud_mname:data.ud_mname
	    }).then((response)=>{
	        setIsSubmit(response.data.error+'/'+response.data.message);
           $(".closeBasicModal").click();
	    }).catch((err) => {
	    	  console.log(err);
	    })
  	}
  }

  useEffect(() => {
  	if(userBasicInfo!==null){
  		setValue('ud_firstname',userBasicInfo.ud_firstname);
  		setValue('ud_lastname',userBasicInfo.ud_lastname);
  		setValue('ud_email',userBasicInfo.ud_email);
  		setValue('ud_profile',userBasicInfo.ud_profile);
  		setValue('ud_height',userBasicInfo.ud_height);
  		setValue('ud_weight',userBasicInfo.ud_weight);
  		setValue('ud_physical',userBasicInfo.ud_physical);
  		setValue('ud_complexion',userBasicInfo.ud_complexion);
  		setValue('ud_bodytype',userBasicInfo.ud_bodytype);
  		setValue('ud_marital',userBasicInfo.ud_marital);
  		setValue('ud_child',userBasicInfo.ud_child);
  		setValue('ud_childstatus',userBasicInfo.ud_childstatus);
      setValue('ud_toungue',userBasicInfo.ud_toungue);
      setValue('ud_blood_group',userBasicInfo.ud_blood_group);
      setValue('ud_sis',userBasicInfo.ud_sis);
      setValue('ud_married_sis',userBasicInfo.ud_married_sis);
      setValue('ud_bro',userBasicInfo.ud_bro);
      setValue('ud_married_bro',userBasicInfo.ud_married_bro);
      setValue('ud_address',userBasicInfo.ud_address);
  		setValue('ud_properties',String(userBasicInfo.ud_properties));
  		setValue('ud_diet',String(userBasicInfo.ud_diet));
  		setValue('ud_smoke',String(userBasicInfo.ud_smoke));
      setValue('ud_drink',String(userBasicInfo.ud_drink));
      setValue('ud_fname',String(userBasicInfo.ud_fname));
  		setValue('ud_mname',String(userBasicInfo.ud_mname));
  		setDateOfBirth(dateDOB);
  	}
  },[userBasicInfo,dateDOB,setValue]);


  const handleChangeDate = (date) => {
  	if(date==null){
  		setIsDate({...isDate,value:false});
  	}else{
  		setIsDate({...isDate,value:true,error:false});
  	}
  	setDateOfBirth(date);
  }

	const handleDietChange = (val) => {
    resetField('ud_diet');
   	setValue('ud_diet',val);
  }

  const handleSmokeChange = (val) => {
  	resetField('ud_smoke');
  	setValue('ud_smoke',val);
  }
  const handleDrinkChange = (val) => {
  	resetField('ud_drink');
  	setValue('ud_drink',val);
  }

  const handleProperties = (val) => {
    resetField('ud_properties');
    setValue('ud_properties',val);
  }


	return (
		<div className="modal fade gtEditForm" id="basicInfoModal" tabIndex="-1" role="dialog" aria-labelledby="basicInfoModal" aria-hidden="true" data-backdrop="static">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header text-center">
            <div className="col-12">
              <h5 className="modal-title" id="exampleModalLabel">{t('basic_information')}
	              <button type="button" className="close closeBasicModal" data-dismiss="modal" aria-label="Close">
	                  <span aria-hidden="true">×</span>
	              </button>
              </h5>
            </div>
          </div>
          <div className="modal-body modalBodyHeight">
            <div className="row">
              <form className="form-horizontal" onSubmit={handleSubmit1(onBasicSubmit)}>         
                <div className="container">
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="form-group">
                        <label className=" required" htmlFor="Userdetails_ud_firstname">First Name 
                          <span className="required">*</span>
                        </label> 
                        <Controller
                            name="ud_firstname"
                            control={control}
                            render={({ field }) => (
                            <input  {...field}
                            className="form-control"
                             />
                            )}
                             rules={{ 
                              required: true,
                              maxLength: 32,
                              pattern: /^[a-zA-Z]+$/
                            }}
                          />  
                          {errors.ud_firstname?.type==="required" && <p className="error">{t('ud_firstname_is_required')}</p>}
			            				{errors.ud_firstname?.type==="maxLength" && <p className="error">{t('ud_firstname_is_maxlength')}</p>}                 	
			            				{errors.ud_firstname?.type==="pattern" && <p className="error">{t('ud_firstname_is_invalid')}</p>}                 	           
                      </div>
                    </div>
                    <div className="col-xl-12">
                      <div className="form-group">
                        <label htmlFor="Userdetails_ud_lastname">
                        Last Name<span className="required">*</span></label> 
                        <Controller
                            name="ud_lastname"
                            control={control}
                            render={({ field }) => (
                            <input  {...field}
                            className="form-control"
                             />
                            )}
                             rules={{ 
                              required: true,
                              maxLength: 32,
                              pattern: /^[a-zA-Z]+$/
                            }}
                          />                                
			                  {errors.ud_lastname?.type==="required" && <p className="error">{t('ud_lastname_is_required')}</p>}
			          				{errors.ud_lastname?.type==="maxLength" && <p className="error">{t('ud_lastname_is_maxlength')}</p>}                 	
			          				{errors.ud_lastname?.type==="pattern" && <p className="error">{t('ud_lastname_is_invalid')}</p>}     
                      </div>
                    </div>
                    <div className="col-xl-12">
                      <div className="form-group">
                        <label htmlFor="Userdetails_ud_lastname">
                        {t('f_name')}<span className="required">*</span></label> 
                        <Controller
                            name="ud_fname"
                            control={control}
                            render={({ field }) => (
                            <input  {...field}
                            className="form-control"
                             />
                            )}
                             rules={{ 
                              required: true,
                              maxLength: 32,
                              pattern: /^[a-zA-Z]+$/
                            }}
                          />                                
                        {errors.ud_fname?.type==="required" && <p className="error">{t('ud_fname_is_required')}</p>}
                        {errors.ud_fname?.type==="maxLength" && <p className="error">{t('ud_fname_is_maxlength')}</p>}                  
                        {errors.ud_fname?.type==="pattern" && <p className="error">{t('ud_fname_is_invalid')}</p>}     
                      </div>
                    </div>
                    <div className="col-xl-12">
                      <div className="form-group">
                        <label htmlFor="Userdetails_ud_lastname">
                       {t('m_name')}<span className="required">*</span></label> 
                        <Controller
                            name="ud_mname"
                            control={control}
                            render={({ field }) => (
                            <input  {...field}
                            className="form-control"
                             />
                            )}
                             rules={{ 
                              required: true,
                              maxLength: 32,
                              pattern: /^[a-zA-Z]+$/
                            }}
                          />                                
                        {errors.ud_mname?.type==="required" && <p className="error">{t('ud_mname_is_required')}</p>}
                        {errors.ud_mname?.type==="maxLength" && <p className="error">{t('ud_mname_is_maxlength')}</p>}                  
                        {errors.ud_mname?.type==="pattern" && <p className="error">{t('ud_mname_is_invalid')}</p>}     
                      </div>
                    </div>
                    <div className="col-xl-12">
                      <div className="form-group">
                        <label className=" required" htmlFor="Userdetails_ud_email">Email <span className="required">*</span></label>
                        	<Controller
                            name="ud_email"
                            control={control}
                            render={({ field }) => (
                            <input  {...field}
                            type="text"
                            className="form-control"
                             />
                            )}
                             rules={{ 
                              required: true,
                              pattern: {
																value : /^\S+@\S+$/i,
															}
                            }}
                          />     
                        <i className="fa fa-envelope fa_eml1 fa-email-alig"></i>
                        {errors.ud_email?.type==="required" && <p className="error">{t('email_is_required')}</p>}
			          				{errors.ud_email?.type==="pattern" && <p className="error">{t('email_is_invalid')}</p>}                 	          
			          				{errors.ud_email?.type==="validate" && <p className="error">{t('email_is_validate')}</p>}                 	          
                      </div>
                    </div>
                    <div className="col-xl-12">
                      <div className="form-group">
                        <label htmlFor="Userdetails_ud_profile" className="required">Profile Created For 
                          <span className="required">*</span>
                        </label>              
                        {Constants.getProfileCreatedFor && (
                        	<Controller
				                   name="ud_profile"
				                   control={control}
				                   render={({ field: { onChange, value, name, ref } }) => (
				                     <Select
				                      inputRef={ref}
				                      value={Constants.getProfileCreatedFor.find((c) => c.value === value) || ''}
				                      options={Constants.getProfileCreatedFor}
				                      onChange={(val: ICategory) => {
				                       onChange(val.value);
				                      }}
				                     />
				                   )}
				                />)}
                      </div>
                    </div>
                    <div className="col-xl-12">
                      <div className="form-group">
                        <label htmlFor="Userdetails_ud_date" className="required">Date Of Birth 
                          <span className="required">*</span>
                        </label>             
                        <div className="row">
                          <div className="col-xl-12 col-12">
														<DatePicker format="YYYY-MM-DD" className="col-md-12 form-control" selected={dateOfBirth} placeholderText={t('enter_the_dob')} onChange={(date) => handleChangeDate(date)}  /> 
														<p style={{'marginTop':'5px','position':'absolute','right':'10px','fontSize':'14px'}} >eg: 11/21/2000</p> 
														{isDate.error===true && <p className="error">{t('dob_is_required')}</p>}      
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-12">
                      <div className="form-group">
                        <label className=" required" htmlFor="Userdetails_ud_height">Height
                        </label>
                        {Constants.getHeightAsc && (
                        	<Controller
				                   name="ud_height"
				                   control={control}
				                   render={({ field: { onChange, value, name, ref } }) => (
				                     <Select
				                      inputRef={ref}
				                      value={Constants.getHeightAsc.find((c) => c.value === value) || ''}
				                      options={Constants.getHeightAsc}
				                      defaultValue={Constants.prompt}
				                      onChange={(val: ICategory) => {
				                       onChange(val.value);
				                      }}
				                     />
				                   )}
				                />)}             
                      </div>
                    </div>
                    <div className="col-xl-12">
                      <div className="form-group">
                        <label htmlFor="Userdetails_ud_weight">Weight</label>              
                        {Constants.getWeight && (
                        	<Controller
				                   name="ud_weight"
				                   control={control}
				                   render={({ field: { onChange, value, name, ref } }) => (
				                     <Select
				                      inputRef={ref}
				                      value={Constants.getWeight.find((c) => c.value === value) || ''}
				                      options={Constants.getWeight}
				                      defaultValue={Constants.prompt}
				                      onChange={(val: ICategory) => {
				                       onChange(val.value);
				                      }}
				                     />
				                   )}
				                />)}                              
                      </div>
                    </div>
                    <div className="col-xl-12">
                      <div className="form-group">
                        <label htmlFor="Userdetails_ud_physical">Physical Status</label> 
                        {Constants.getUserPhysicalStatus && (
                        	<Controller
				                   name="ud_physical"
				                   control={control}
				                   render={({ field: { onChange, value, name, ref } }) => (
				                     <Select
				                      inputRef={ref}
				                      value={Constants.getUserPhysicalStatus.find((c) => c.value === value) || ''}
				                      options={Constants.getUserPhysicalStatus}
				                      defaultValue={Constants.prompt}
				                      onChange={(val: ICategory) => {
				                       onChange(val.value);
				                      }}
				                     />
				                   )}
				                />)}    
                      </div>
                    </div>
                    <div className="col-xl-12">
                      <div className="form-group">
                        <label htmlFor="Userdetails_ud_complexion">Complexion</label>             
                        <div className="row">
                          <div className="col-12">
                            {Constants.getUserComplexion && (
		                        	<Controller
						                   name="ud_complexion"
						                   control={control}
						                   render={({ field: { onChange, value, name, ref } }) => (
						                     <Select
						                      inputRef={ref}
						                      value={Constants.getUserComplexion.find((c) => c.value === value) || ''}
						                      options={Constants.getUserComplexion}
						                      defaultValue={Constants.prompt}
						                      onChange={(val: ICategory) => {
						                       onChange(val.value);
						                      }}
						                     />
						                   )}
						                />)}                                   
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-12">
                      <div className="form-group">
                        <label htmlFor="Userdetails_ud_bodytype">Body Type</label>
                        <div className="row">
                          <div className="col-12">
                            {Constants.getUserBodyType && (
		                        	<Controller
						                   name="ud_bodytype"
						                   control={control}
						                   render={({ field: { onChange, value, name, ref } }) => (
						                     <Select
						                      inputRef={ref}
						                      value={Constants.getUserBodyType.find((c) => c.value === value) || ''}
						                      options={Constants.getUserBodyType}
						                      defaultValue={Constants.prompt}
						                      onChange={(val: ICategory) => {
						                       onChange(val.value);
						                      }}
						                     />
						                   )}
						                />)}                                     
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-12">
                      <div className="form-group">
                        <label htmlFor="Userdetails_ud_marital" className="required">Marital Status <span className="required">*</span></label>
                        {Constants.getMartialStatus && (
                        	<Controller
				                   name="ud_marital"
				                   control={control}
				                   render={({ field: { onChange, value, name, ref } }) => (
				                     <Select
				                      inputRef={ref}
				                      value={Constants.getMartialStatus.find((c) => c.value === value) || ''}
				                      options={Constants.getMartialStatus}
				                      defaultValue={Constants.prompt}
				                      onChange={(val: ICategory) => {
				                       onChange(val.value);
				                      }}
				                     />
				                   )} 
				                 />)}                         
                      </div>
                    </div>
                    <div className="col-xl-12" id="dis_child" style={{'display': 'block'}}>
                      <div className="form-group">
                        <label htmlFor="Userdetails_ud_child">No of Children</label>              
                         {Constants.getUserNoofChildren && (
                        	<Controller
				                   name="ud_child"
				                   control={control}
				                   render={({ field: { onChange, value, name, ref } }) => (
				                     <Select
				                      inputRef={ref}
				                      value={Constants.getUserNoofChildren.find((c) => c.value === value) || ''}
				                      options={Constants.getUserNoofChildren}
				                      defaultValue={Constants.prompt}
				                      onChange={(val: ICategory) => {
				                       onChange(val.value);
				                      }}
				                     />
				                   )} 
				                 />)}                              
                      </div>
                    </div>
                    <div className="col-xl-12" id="dis_child_status" style={{'display': 'block'}}>
                      <div className="form-group">
                        <label htmlFor="Userdetails_ud_childstatus">Children Status</label>             
                         {Constants.getUserChildrenStatus && (
                        	<Controller
				                   name="ud_childstatus"
				                   control={control}
				                   render={({ field: { onChange, value, name, ref } }) => (
				                     <Select
				                      inputRef={ref}
				                      value={Constants.getUserChildrenStatus.find((c) => c.value === value) || ''}
				                      options={Constants.getUserChildrenStatus}
				                      defaultValue={Constants.prompt}
				                      onChange={(val: ICategory) => {
				                       onChange(val.value);
				                      }}
				                     />
				                   )} 
				                 />)}                              
                      </div>
                    </div>
                    <div className="col-xl-12">
                      <div className="form-group">
                        <label className=" required" htmlFor="Userdetails_ud_toungue">Mother Tongue <span className="required">*</span>
                        </label>             
                        {getMotherToungueData && (
                        	<Controller
				                   name="ud_toungue"
				                   control={control}
				                   render={({ field: { onChange, value, name, ref } }) => (
				                     <Select
				                      inputRef={ref}
				                      value={getMotherToungueData.find((c) => c.value === value) || ''}
				                      options={getMotherToungueData}
				                      defaultValue={Constants.prompt}
				                      onChange={(val: ICategory) => {
				                       onChange(val.value);
				                      }}
				                     />
				                   )} 
				                 />)}                           
                        </div>
                      </div>
                      <div className="col-xl-12">
                        <div className="form-group">
                          <label className=" required" htmlFor="Userdetails_ud_blood_group">Blood Group</label>             
                          {getMotherToungueData && (
                            <Controller
                             name="ud_blood_group"
                             control={control}
                             render={({ field: { onChange, value, name, ref } }) => (
                               <Select
                                inputRef={ref}
                                value={Constants.getBloodGroups.find((c) => c.value === value) || ''}
                                options={Constants.getBloodGroups}
                                defaultValue={Constants.prompt}
                                onChange={(val: ICategory) => {
                                 onChange(val.value);
                                }}
                               />
                             )} 
                           />)}                           
                          </div>
                        </div>
                        <div className="col-xl-12">
                      <div className="form-group">
                        <label className=" required" htmlFor="Userdetails_ud_bro">Brothers</label>             
                        {Constants.getUserNoofSiblings && (
                          <Controller
                           name="ud_bro"
                           control={control}
                           render={({ field: { onChange, value, name, ref } }) => (
                             <Select
                              inputRef={ref}
                              value={Constants.getUserNoofSiblings.find((c) => c.value === value) || ''}
                              options={Constants.getUserNoofSiblings}
                              defaultValue={Constants.prompt}
                              onChange={(val: ICategory) => {
                               onChange(val.value);
                              }}
                             />
                           )} 
                         />)}                           
                        </div>
                      </div>
                      <div className="col-xl-12">
                      <div className="form-group">
                        <label className=" required" htmlFor="Userdetails_ud_married_bro">Married Brothers</label>             
                        {Constants.getUserNoofSiblings && (
                          <Controller
                           name="ud_married_bro"
                           control={control}
                           render={({ field: { onChange, value, name, ref } }) => (
                             <Select
                              inputRef={ref}
                              value={Constants.getUserNoofSiblings.find((c) => c.value === value) || ''}
                              options={Constants.getUserNoofSiblings}
                              defaultValue={Constants.prompt}
                              onChange={(val: ICategory) => {
                               onChange(val.value);
                              }}
                             />
                           )} 
                         />)}                           
                        </div>
                      </div>
                      <div className="col-xl-12">
                      <div className="form-group">
                        <label className=" required" htmlFor="Userdetails_ud_sis">Sisters</label>             
                        {Constants.getUserNoofSiblings && (
                          <Controller
                           name="ud_sis"
                           control={control}
                           render={({ field: { onChange, value, name, ref } }) => (
                             <Select
                              inputRef={ref}
                              value={Constants.getUserNoofSiblings.find((c) => c.value === value) || ''}
                              options={Constants.getUserNoofSiblings}
                              defaultValue={Constants.prompt}
                              onChange={(val: ICategory) => {
                               onChange(val.value);
                              }}
                             />
                           )} 
                         />)}                           
                        </div>
                      </div>
                      <div className="col-xl-12">
                      <div className="form-group">
                        <label className=" required" htmlFor="Userdetails_ud_married_sis">Married Sisters</label>             
                        {Constants.getUserNoofSiblings && (
                          <Controller
                           name="ud_married_sis"
                           control={control}
                           render={({ field: { onChange, value, name, ref } }) => (
                             <Select
                              inputRef={ref}
                              value={Constants.getUserNoofSiblings.find((c) => c.value === value) || ''}
                              options={Constants.getUserNoofSiblings}
                              defaultValue={Constants.prompt}
                              onChange={(val: ICategory) => {
                               onChange(val.value);
                              }}
                             />
                           )} 
                         />)}                           
                        </div>
                      </div>
                      <div className="col-12">
                      <div className="form-group">
                        <label htmlFor="Userdetails_ud_about">{t('address')}</label>             
                        <textarea className="form-control" {...register('ud_address')} defaultValue={getValues('ud_about') || ''} />     
                      </div>
                    </div>
                        <div className="col-xl-12">
                        <div className="form-group">
                          <label htmlFor="Userdetails_ud_properties">Properties</label>  
                          <div className="row">
                            <div className="col-12">
                              <span id="Userdetails_ud_properties">
                                <input type="radio" value="1" onClick={(e) => handleProperties(e.target.value)}
                                checked={getValues('ud_properties')==="1"}
                                  {...register('ud_properties')}
                                  />
                                <label htmlFor="Userdetails_ud_properties_1"> Yes</label>
                                <br />
                                <input type="radio" value="2" onClick={(e) => handleProperties(e.target.value)}
                                checked={getValues('ud_properties')==="2"} 
                                  {...register('ud_properties')}
                                  />
                                <label htmlFor="Userdetails_ud_properties_2"> No</label><br />
                              </span>                
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-12">
                        <div className="form-group">
                          <label htmlFor="Userdetails_ud_diet">Diet</label>
                          <div className="row">
                            <div className="col-12">
                              <span id="Userdetails_ud_diet">
									              <input type="radio" value="1" onClick={(e) => handleDietChange(e.target.value)}
									              checked={getValues('ud_diet') === "1"}
									              {...register('ud_diet')}
									           		/>
                                <label htmlFor="Userdetails_ud_diet_0"> Vegetarion</label>
                                <br />
                                <input type="radio" value="2"  onClick={(e) => handleDietChange(e.target.value)}
                                checked={getValues('ud_diet') === "2"}
									              {...register('ud_diet')}
									           		/>
                                <label htmlFor="Userdetails_ud_diet_1"> Non Vegetarion</label>
                                <br />
                                <input type="radio" value="3" onClick={(e) => handleDietChange(e.target.value)}
                                checked={getValues('ud_diet') === "3"}
									              {...register('ud_diet')}
									           		/>
                                <label htmlFor="Userdetails_ud_diet_2"> Eggetarion</label>
                              </span>                
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-12">
                        <div className="form-group">
                          <label htmlFor="Userdetails_ud_smoke">Smoke</label>
                          <div className="row">
                            <div className="col-12">
                              <span id="Userdetails_ud_smoke">
                              	<input type="radio" value="1" onClick={(e) => handleSmokeChange(e.target.value)}
                              	checked={getValues('ud_smoke')=== "1"}
									              {...register('ud_smoke')}
									           		/>
                                <label htmlFor="Userdetails_ud_smoke_0"> No</label>
                                <br />
                                <input type="radio" value="2" onClick={(e) => handleSmokeChange(e.target.value)}
                                checked={getValues('ud_smoke')=== "2"}
									              {...register('ud_smoke')}
									           		/>
                                <label htmlFor="Userdetails_ud_smoke_1"> Yes</label><br />
                                <input type="radio" value="3" onClick={(e) => handleSmokeChange(e.target.value)}
                                checked={getValues('ud_smoke')=== "3"}
									              {...register('ud_smoke')}
									           		/>
                                <label htmlFor="Userdetails_ud_smoke_2"> Occasionally</label>
                              </span>                
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-12">
                        <div className="form-group">
                          <label htmlFor="Userdetails_ud_drink">Drinking</label>  
                          <div className="row">
                            <div className="col-12">
                              <span id="Userdetails_ud_drink">
	                              <input type="radio" value="1" onClick={(e) => handleDrinkChange(e.target.value)}
	                              checked={getValues('ud_drink')==="1"}
										              {...register('ud_drink')}
										           		/>
                                <label htmlFor="Userdetails_ud_drink_0"> No</label>
                                <br />
                                <input type="radio" value="2" onClick={(e) => handleDrinkChange(e.target.value)}
                                checked={getValues('ud_drink')==="2"} 
										              {...register('ud_drink')}
										           		/>
                                <label htmlFor="Userdetails_ud_drink_1"> Yes</label><br />
                                <input type="radio" value="3" onClick={(e) => handleDrinkChange(e.target.value)}
                                checked={getValues('ud_drink')==="3"}
										              {...register('ud_drink')}
										           		/>
                                <label htmlFor="Userdetails_ud_drink_2"> Drinks Socially</label>
                              </span>                
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-12">
                        <div className="form-group text-center">
                          <input className="btn btnRegFsubmit" name="details" type="submit" value="Update" />           
                        </div>
                      </div>
                    </div>
                  </div>
                </form>        
              </div>
            </div>
          </div>
        </div>
      </div>
	)
}