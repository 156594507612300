import { Link } from "react-router-dom";
import { useEffect,useState } from "react";
import Axios from "axios";
import { useTranslation } from 'react-i18next';
import Constants from "../services/Constants"; 
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactPaginate from 'react-paginate';
import ResultNotFoundComponent from '../components/ResultNotFoundComponent';

export default function MessageComponent({data,update,setUpdate}){
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 10;
  const { t } = useTranslation();
  const [users,setUsers] = useState([]);
  let userToken = JSON.parse(localStorage.getItem('userToken'));
  let userPaid =  localStorage.getItem('userPaid');
  let userID =  localStorage.getItem('userID');

  useEffect(() => {
    setUsers(data);
  },[data]);


  const deleteMessage = (e,mesID) => {
    Axios.post(Constants.API_URL+"deletemessages",{
          api_token : userToken,id : mesID
    }).then((response)=>{
      if(response.data.error===true) toast.error(response.data.message);
      else toast.success(response.data.message);
      setTimeout(() => setUpdate(update + 1),2000);
    });
    
  }

  const requestStatus = (e,profID,InterestSent,add) => {
    if(profID && userToken!==null){
      var url
      if(add===0) url = "rejectsendrequest";
      else  url = "acceptsendrequest";
        Axios.post(Constants.API_URL+url,{
          api_token : userToken,
          profile_id : (InterestSent===0) ? profID : userID,
          user_id :(InterestSent===0) ? userID : profID,
        }).then((response)=>{
          if(response.data.error===true) toast.error(response.data.message);
          else toast.success(response.data.message);
          setTimeout(() => setUpdate(update + 1),2000);
        });
    }else{
      toast.error(t('please_login_to_continue'));
    }
  }

  useEffect(() => { 
    if(users.length > 0){
      const endOffset = itemOffset + itemsPerPage;
      setCurrentItems(users.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(users.length / itemsPerPage));
    }
  }, [itemOffset, itemsPerPage,users]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % users.length;
    setItemOffset(newOffset);
  };

  return (
      <div>
        <ToastContainer/>
        {currentItems===null &&  <ResultNotFoundComponent />}
        {(currentItems!==null && currentItems.length > 0) && currentItems.map((user,index) => (
        <div className="card gtMsgRes mb-3" key={(user.show_message===1 || user.accept===1) ? user.message_id : user.id}>
          <div className="row">
            <div className="col-xl-2 col-12 col-lg-2 col-md-4">
              {(((user.photoprivacy===2 && userPaid==="1") || (user.photoprivacy===1 && userToken!==null)) && user.photoprivacy!==0) && 
                <img alt={user.matri_id} style={{'minHeight':'100px','minWidth':'100%'}}   
                src={user.image!=="" ? Constants.IMAGE_URL+user.image : (user.gender===1 ? require('../assets/images/no_image_groom.png') : require('../assets/images/no_image_bride.png'))} />
              }
              {((user.photoprivacy===0 && userToken!==null) || (user.photoprivacy===2 && userPaid==="0")) && 
                <img alt={user.matri_id} style={{'minHeight':'100px','minWidth':'100%'}} 
                src={user.gender===1 ? require('../assets/images/no_image_groom.png') : require('../assets/images/no_image_bride.png')} />
              }
            </div>
            <div className="col-xl-7 col-12 col-lg-7 col-md-8" >
              <div className="row mt-2 pll-480">
                <div className="col-xl-12 col-md-5 col-12 gtMsgResDet" style={{'display': 'contents'}}>
                  <div className="col-12 col-md-6">
                    <h4>{user.fname} {user.lname}</h4>
                    {user.matri_id}
                  </div>
                  <div className="col-12 col-md-6">
                    <p><b>{(user.reply===1 || user.accept===1) ? t('received_on') : t('sent_on')}:&nbsp;&nbsp;</b>{user.date}</p>
                  </div>
                  <div className="col-12">
                  {user.show_message===1 && <p>{user.message}</p>}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-12 gtMsgAct">
              {user.accept===1 && 
                <button className="btn" style={{'width':'100%'}} onClick={(e) => requestStatus(e,user.id,user.accept,1)}>
                <i className="fas fa-check pr-2"></i>{t('accept')}
              </button>}
              {user.message_delete===1 ? 
                <button className="btn" style={{'width':'100%'}} onClick={(e) => deleteMessage(e,user.message_id)}>
                <i className="fas fa-trash pr-2"></i>{t('delete')}
              </button> :
              <button className="btn" style={{'width':'100%'}} onClick={(e) => requestStatus(e,user.id,user.accept,0)}>
                <i className="fas fa-trash pr-2"></i>{t('delete')}
              </button> }
              {user.reply===1 && 
                <button className="btn" style={{'width':'100%'}}><Link style={{'background' : 'none'}} to={`/composemessage/${user.id}`} >
                <i className="fas fa-reply pr-2"></i>{t('reply')}
                </Link></button>
              }
            </div>
          </div>
        </div>
        ))}
        {users.length > 9 && 
          <div style={{'float':'right'}}>
          <ReactPaginate
          previousLabel={"<< Previous"}
          nextLabel={"Next >>"}
          pageCount={pageCount}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          previousLinkClassName={"pagination__link"}
          nextLinkClassName={"pagination__link"}
          disabledClassName={"pagination__link--disabled"}
          activeClassName={"pagination__link--active"}
        /> </div>
        }
      </div>
  )
};


