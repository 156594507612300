import { useEffect,useState,useCallback } from 'react'
import { useNavigate,useParams } from "react-router-dom";
import Axios from "axios"
import Constants from "../services/Constants";
import Skeleton , { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useTranslation } from 'react-i18next';

export default function InfoComponent(props){
  let { pageUrl } = useParams();
  const navigate = useNavigate();
  const [pagedata,setPageData] =useState([]);
  const [loading,setLoading]= useState(0);
  const { t } = useTranslation();

  const getCmsData = useCallback(() => {
    Axios.get(Constants.API_URL+"cms/"+pageUrl).then((response)=>{
      if(response.data.data){
        setPageData(response.data.data);
        setLoading(1);
      }else{
        navigate('/error',{state:{title:true,message:t('something_went_wrong'),error:true}})
      }
    });
  },[pageUrl,navigate,t])

  useEffect(()=>{
    getCmsData()
  },[getCmsData]);

  return (
    <>
    {loading===0 && 
    <SkeletonTheme baseColor="#dfbb26" height="10px" highlightColor="#fff">
      <Skeleton />
    </SkeletonTheme>}
  	<div className="container mt-5">
  		{pagedata.map((user) => (
  			<div key="{user.id}" className="mt-4">
     	    <h1>{user.title}</h1>
     	    <div dangerouslySetInnerHTML={ { __html: user.content } }></div>
  			</div>
  		))}
    </div>
    </>
    );
}

