import Axios from "axios" 
import { useForm } from 'react-hook-form';
import { useNavigate,useLocation } from "react-router-dom";
import { useEffect,useState } from "react";
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Constants from "../services/Constants";  

export default function SettingComponent(){
	const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const state = location.state;
  let userPaid = parseInt(localStorage.getItem('userPaid'));
  let userTransaction  = parseInt(localStorage.getItem('userTransaction'));
  let userToken = JSON.parse(localStorage.getItem('userToken'));
  const [passwordType, setPasswordType] = useState("password");
  const [newpasswordType, setNewPasswordType] = useState("password");
	const [confirmpasswordType, setConfirmpasswordType] = useState("password");
  const { register, handleSubmit,getValues, formState: { errors } } = useForm();
	const { register: register2,getValues : getValues1,resetField ,setValue, handleSubmit : handleSubmit2, formState: { errors: errors2 } } = useForm();

  useEffect(() => {
  	setValue('ud_photo_privacy',localStorage.getItem('userPhotoPrivacy'));
		if(state!==null){
			var title = state.title;
      if(title===true){
        if(location.state.error===true) toast.error(location.state.message);
        else toast.success(location.state.message);
        if(location.state.title) navigate(location.pathname, { replace: true });
      }
		}
  },[location,navigate,setValue,state]);

  const onSubmit = (data) => {
  	 Axios.post(Constants.API_URL+"changeuserpassword",{
        api_token : userToken,
        ud_password : data.ud_password,
        new_password : data.new_password,
        confirm_password : data.confirm_password
      }).then((response)=>{
      	if(response.data.error===true){
      		toast.error(response.data.message);
      	}else{
      		navigate('/home',{state:{title:true,message:response.data.message}});
      	}
      });
  }

  const onSubmitPhoto = (data) => {
  	if(userPaid===0 || userTransaction===0){
	      navigate('/memberplan', {state:{title:true,message:((userTransaction!==0) ? t('please_contact_support') : (userTransaction===0 ? t('please_add_a_membership') : t('please_login_to_continue'))),error:true}})
	  }else{
	  	Axios.post(Constants.API_URL+"addphotoprivacy",{
	        api_token : userToken,ud_photo_privacy : data.ud_photo_privacy,
	      }).then((response)=>{
	      	if(response.data.error===true) toast.error(response.data.message);
	      	else 	navigate('/home',{state:{title:true,message:response.data.message}});
	      }); 
	  }
	};

   const togglePassword = (e,name) => {
   	if(name==="password"){
	    if(passwordType==="password") setPasswordType("text");
	    else  setPasswordType("password")
   	}

   if(name==="new_password"){
	    if(newpasswordType==="password") setNewPasswordType("text");
	    else  setNewPasswordType("password")
   	}

   if(name==="confirm_password"){
	    if(confirmpasswordType==="password") setConfirmpasswordType("text");
	    else  setConfirmpasswordType("password")
   	}
  }


  const handlePhotoPrivacy = (val) => {
    resetField('ud_photo_privacy');
   	setValue('ud_photo_privacy',val);
  }

		return (
			<div className="container mt-5">
				<ToastContainer />
			  <div className="row">
			    <div className="col-xl-8 offset-xl-2 col-lg-8 offset-lg-2">
			      <ul className="nav nav-pills mb-3 nav-fill gtSerPill" id="pills-tab" role="tablist">
			        <li className="nav-item">
			          <a className="nav-link active show" id="pills-password-tab" data-toggle="pill" href="#pills-password" role="tab" aria-controls="pills-password" aria-selected="true">
			          {t('change_password')}</a>
			        </li>
			        <li className="nav-item ">
			          <a className="nav-link"  id="pills-photo-tab" data-toggle="pill" href="#pills-photo" role="tab" aria-controls="pills-photo" aria-selected="false">{t('photo_privacy')}</a>
			        </li>
			      </ul>
			      <div className="clearfix"></div>
			      <div className="tab-content gtSerBody" id="pills-tabContent">
			        <div className="tab-pane fade active show" id="pills-password" role="tabpanel" aria-labelledby="pills-password-tab">
			          <h3>{t('change_password')}</h3>
			          <p>{t('change_password_description')}</p>
			          <form className="col-xl-8 offset-xl-2 mt-2" onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data" id="changepassword-form">    
			            <div className="form-group">
			              <div className="label-cont-1">
			                <label>{t('current_password')}</label>
			              </div>
			              <input type={passwordType} className="form-control width" placeholder={t('enter_your_password')} 
                    {...register("ud_password", 
                      {
                        required: true, 
                      	minLength: 3, 
                      	maxLength: 15, 
                      }
                    )} />
                    <span onClick={(e) => togglePassword(e,'password')}>
                    	{passwordType==="password" ? 
                    	<span className="eyeslash">
                    		<i className="fa fa-eye-slash alig-dow" id="eye-slash-1"></i>
                    		</span> : 
                    		<span style={{'fontSize': '1.3125rem'}} className="eye">
                    		<i className="fa fa-eye alig-dow" id="eye-slash-1"></i>
                    		</span> 
                    	}
                  	</span>
                    {errors.ud_password?.type==="required" && <p className="error text-left">{t('password_is_required')}</p>}              
                  	{errors.ud_password?.type==="minLength" && <p className="error text-left">{t('ud_password_is_minlength')}</p>}
                  	{errors.ud_password?.type==="maxLength" && <p className="error text-left">{t('ud_password_is_maxlength')}</p>}
			            </div> 
			            <div className="form-group">
			              <div className="label-cont-2">
			                <label>{t('new_password')}</label>
			              </div>
			              <input type={newpasswordType} className="form-control width" placeholder={t('enter_your__new_password')} 
                    {...register("new_password", 
                      {
                        required: true, 
                        minLength: 3, 
                      	maxLength: 15, 
                      }
                    )} />
                    <span onClick={(e) => togglePassword(e,'new_password')}>
                    {newpasswordType==="password" ? 
				              <span className="2ndfaeyeslash">
				              	<i className="fa fa-eye-slash alig-dow" id="eye-slash-2"></i>
				              </span> : 
				              <span className="2ndfaeye" style={{'fontSize': '1.3125rem'}} >
				                <i className="fa fa-eye alig-dow" id="eye-slash-2"></i>
				              </span>
				            }
			              </span>
			              {errors.new_password?.type==="required" && <p className="error text-left">{t('new_password_is_required')}</p>}
                  	{errors.new_password?.type==="minLength" && <p className="error text-left">{t('password_is_minlength')}</p>}
                  	{errors.new_password?.type==="maxLength" && <p className="error text-left">{t('password_is_maxlength')}</p>}
			            </div>
			            <div className="form-group">
			              <label>{t('confirm_password')}</label>
			              <input type={confirmpasswordType} className="form-control width" placeholder={t('enter_your_confirm_password')} 
                    {...register("confirm_password", 
                      {
                        required: true, 
                        validate: value => value === getValues("new_password") || 'Confirm password must be same',
                        minLength: 3, 
                      	maxLength: 15,  
                      }
                    )} />
                    <span onClick={(e)=>togglePassword(e,'confirm_password')}>
                    {confirmpasswordType==="password" ? 
                  	<span className="fa3rdeyeslash">
			                <i className="fa fa-eye-slash alig-dow" id="eye-slash-3" ></i>
			              </span> : 
			              <span className="fa3rdeye" style={{'fontSize': '1.3125rem'}} >
			                <i className="fa fa-eye alig-dow" id="eye-slash-3">
			                </i>
			              </span>
			            	}
			              </span>
			              {errors.confirm_password?.type==="required" && <p className="error text-left">{t('confirm_password_is_required')}</p>}
                  	{errors.confirm_password?.type==="minLength" && <p className="error text-left">{t('confirm_password_is_minlength')}</p>}
                  	{errors.confirm_password?.type==="maxLength" && <p className="error text-left">{t('confirm_password_is_maxlength')}</p>}
                    {errors.confirm_password?.type==="validate" && <p className="error text-left">Confirm password must be same</p>}              
			            </div>
			            <div className="form-group text-center">
			              <input className="btn gtSetEditPass" type="submit" name="yt0" value={t('update')} />              
			            </div>
			          </form> 
			        </div>
			        <div className="tab-pane fade" id="pills-photo" role="tabpanel" aria-labelledby="pills-photo-tab">
			          <h3>{t('photo_privacy_setting')}</h3>
			          <p>{t('photo_privacy_description')}</p>
			          <div className="row gtSetStat mt-4">
			          <form key={2} id="settings-form" onSubmit={handleSubmit2(onSubmitPhoto)} className="col-xl-6 offset-xl-3" > 
			              <div className="form-group">
			                <div className="custom-control custom-radio custom-control-inline">
			                  <input id="ytUserdetails_ud_photo_privacy" type="hidden" value="" name="Userdetails[ud_photo_privacy]" />
			                  <span id="Userdetails_ud_photo_privacy">
			                    <input  value="0" type="radio" 
			                    checked={getValues1('ud_photo_privacy') === "0"}
			                     onClick = {(e) => handlePhotoPrivacy(e.target.value)}
			                    {...register2("ud_photo_privacy", 
			                      {
			                        required: true,
			                      }
			                    )} />&nbsp;&nbsp; 
			                    <label>{t('dont_show')}</label>
			                    <br />
			                    <input value="1" type="radio"
			                    checked={getValues1('ud_photo_privacy') === "1"}
													 onClick = {(e) => handlePhotoPrivacy(e.target.value)}	
													{...register2("ud_photo_privacy", 
			                      {
			                        required: true, 
			                      }
			                    )}  /> &nbsp;
			                    <label>{t('show_to_all')}</label>
			                    <br />
			                    <input value="2" type="radio" 
			                    checked={getValues1('ud_photo_privacy') === "2"}
			                    onClick = {(e) => handlePhotoPrivacy(e.target.value)}
			                    {...register2("ud_photo_privacy", 
			                      {
			                        required: true,
			                      }
			                    )}  /> &nbsp;
			                    <label>{t('show_to_paid_ony')}</label>
			                    <br />
			                    {errors2.ud_photo_privacy?.type==="required" && <span className="error">{t('photo_privacy_is_required')}</span>}
			                  </span>                     
			                </div>
			              </div>
			              <div className="form-group row mt-4">
			                <div className="col-xl-12 text-center">
			                  <input className="btn btn-lg btnRegFsubmit" name="idsearch" type="submit" value={t('submit')} />                               
			                </div>
			              </div>
			            </form>             
			          </div>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>	
		)
}