import React, { useEffect,useState } from 'react'
import { Link,useNavigate,useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import Constants from "../services/Constants";
import Axios from "axios"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function LoginComponent() {
  
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [passwordType, setPasswordType] = useState("password");
  const [passwordInput, setPasswordInput] = useState("");
  const [inputErrors, setinputErrors] = useState(0);
  
  const handlePasswordChange = (e) => {
      setPasswordInput(e.target.value);
      setinputErrors(0);
  }

  const handleEmailChange = (e) => {
      setinputErrors(0);
  }

  const togglePassword = () => {
    if(passwordType==="password") setPasswordType("text");
    else  setPasswordType("password")
  }

   const onSubmit = (data) => {
      Axios.post(Constants.API_URL+"auth/login",{
        email: data.username,password: data.password,device: '',device_code: ''
      }).then((response)=>{
        if(response.data.data.user_id=== 0) setinputErrors(1);
        else {
          localStorage.setItem("user", JSON.stringify(response.data));
          localStorage.setItem("userToken", JSON.stringify(response.data.data.access_token));
          localStorage.setItem("userId", JSON.stringify(response.data.data.user_id));
          navigate('/home',{state:{title:true,message:t('logged_in_successfully')}});
        }
      }).catch((err) => {
        console.log(err);
      })
  }   


  useEffect(() => {
    if(state!==null){
      var title = location.state.title;
      if(title===true){
        if(location.state.message!==""){
          if(location.state.error===true) toast.error(location.state.message);
          else toast.success(location.state.message);
        }
        navigate(location.pathname, { replace: true });
      } 
    }
  },[location,navigate,state])

  return (
    <div className="container mt-5">
      <ToastContainer />
        <div className="row">
          <div className="col-xl-8 offset-xl-2 col-lg-8 offset-lg-2">
            <div className="gtLogMain">
              <h4 className="gtPageTitle">{t('login_to_your_account')}</h4>
              <p className="gtPageSubTitle">{t('login_and_start_your_journey')}</p>
              <div className="col-xl-8 col-lg-8 col-md-12 offset-lg-2">
                <form onSubmit={handleSubmit(onSubmit)} id="login-form" 
                className="form-horizontal" encType="multipart/form-data">
                  <div className="form-group">
                    <div className="input-group">
                      <input type="text" className="form-control" placeholder={t('enter_the_mobile_no_or_profile_id')} 
                      {...register("username", 
                        {
                          required: true, 
                          maxLength: 10, 
                          onChange: (e) => handleEmailChange(e),
                        }
                      )} />
                      <i className="fas fa-mobile-alt fa_mob2" id="mob-ic"></i>
                    </div> 
                    {errors.username?.type==="required" && <p className="error">{t('username_login_is_required')}</p>}
                    {errors.username?.type==="maxLength" && <p className="error">{t('mobile_is_maxlength')}</p>}
                    {errors.username?.type==="pattern" && <p className="error">{t('mobile_is_invalid')}</p>}
                  </div>
                  <div className="form-group mb-5">
                    <input type={passwordType} className='form-control' value={passwordInput} placeholder={t('enter_the_password')} 
                      {...register("password", 
                        {required: true,
                          onChange: (e) => handlePasswordChange(e)
                         })} />
                      <span className="eyeslash" onClick={togglePassword}>
                        {passwordType==="password"? <i className="fa fa-eye-slash fa_eyeslash alig-left" id="eye-cl"></i> :<i className="fa fa-eye fa_eyeopen alig-top"></i> }
                      </span>
                     {errors.password?.type==="required" && <p className="error">{t('password_is_required')}</p>}
                     {inputErrors!==0 && <p className="error">{t('invalid_username_or_password')}</p>}
                  </div>
                  <div className="form-group text-center">
                    <button type="Submit" className="btn btnLogModal btn-block">{t('submit')}</button>
                  </div>
                  <div className="gtBorderOr"><span>{t('or')}</span></div>
                  <div className="form-group text-center gtSignModalTxt mt-4">
                    <Link to="/forgetpassword" state={{state:{title:false,message:''}}} className="btn btnSignUpCall mt-2 btn-block">{t('forget_password')}</Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}