import React, { useEffect,useState } from 'react'
import { useNavigate,useLocation } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Axios from "axios"
import Constants from "../services/Constants";

export default function RegiterThreeComponent() {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [picture, setPicture] = useState(null);
  const [imgData, setImgData] = useState(null);
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [inputErrors,setInputErrors]= useState({type:0,size:0});

  const isValidType=(file)=>{
    const validExt = ['png','jpeg','jpg']
    const fileExt = file.type.split('/')[1];
    return validExt.includes(fileExt);
  }
  
  const onChangePicture = (e) => {
    if (e.target.files[0]) {
      setPicture(e.target.files[0]);
      var filesValid = isValidType(e.target.files[0]);
      if(isValidType(e.target.files[0])) setInputErrors({...inputErrors,type:0}); 
      else setInputErrors({...inputErrors,type:1});
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgData(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = function (e) {
          var image = new Image();
          image.src = e.target.result;
          image.onload = function () {
          var height = this.height;
          var width = this.width;
          if(filesValid===true) filesValid = 0;
          else filesValid = 1;
          if (height > 400 && width > 400) setInputErrors({...inputErrors,type:filesValid,size:0});
          else setInputErrors({...inputErrors,type:filesValid,size:1});
        }
      }
    }
  };


  const onSubmit = (data) => {
    if(inputErrors.type===0 && inputErrors.size===0){
      Axios.post(Constants.API_URL+"userprofilepictureupdate",{
        user_id : localStorage.getItem("registerid"), image : picture
      },{ headers: { "content-type": "multipart/form-data" },
      }).then((response)=>{
         localStorage.clear();
         navigate('/login',{state:{title:true,message:response.data.message}});
      }).catch((err) => {
          navigate('/login',{state:{title:true,message:t('please_try_again_later')}});
          console.log(err);
      })
    }
  }
  
  useEffect(() => {
    if(location.state!=null){
      if(location.state.title!==null){
        var title = location.state.title;
          if(title===true){
            if(location.state.error===true) toast.error(location.state.message);
            else toast.success(location.state.message);
            if(location.state.title) navigate(location.pathname, { replace: true });
          }
        }
      }
  },[location,navigate]);

  return (
    <div className="container mt-5">
    <ToastContainer />
      <div className="row">
        <div className="col-xl-8  bg-white offset-xl-2 col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-sm-8 offset-sm-2 col-12">
          <h3 className="gtPageTitle mt-4">{t('upload_profile_picture')}</h3>
          <p className="gtPageSubTitle">{t('upload_profile_picture_description')}</p>
          <form className="mt-4" onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">             
            <div className="form-group row">
              <p className="col-12 text-center">{t('click_on')}<b>{t('select_image')}</b>{t('and_then')}<b>{t('upload')}</b>{t('to_upload_image')}</p>
              <div className="col-xl-4 offset-xl-4 col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-sm-10 offset-sm-1 text-center">
                <img
                  alt={"ProfileImage"}
                  className="img-fluid img-thumbnail"
                  id="photo1_prev"
                  src={imgData ? imgData : require('../assets/images/no_image.png')}
                />
                {errors.image_pic?.type==="required" && <p className="error-msg">{t('image_is_required')}</p>}
                <label htmlFor="photo1_image" className="btn btnSelect btn-block mt-2">{t('select_image')}
                </label>
                <input
                  id="photo1_image"
                  accept="image/*"
                  type="file"
                  style={{'display':'none'}}
                  {...register("image_pic", {
                    required: true,
                    onChange: (e) => onChangePicture(e)
                  })}
                />
                {(inputErrors.type===1 && inputErrors.size===0) && <p className="error" >{t('upload_photos_type')}</p>}                                     
                {(inputErrors.size===1 && inputErrors.type===0) && <p className="error" >{t('upload_photos_size')}</p>} 
                {(inputErrors.type===1 && inputErrors.size===1) && <p className="error" >{t('invalid_image_type_and_size')}</p>}                                    
              </div>
            </div>
            <div className="row mb-4">
              <div className="text-center col-xl-12">
                <p className="col-12 mt-4">
                  <span className="badge badge-secondary">{t('note')}</span>&nbsp;&nbsp;{t('note_images_description')}.
                </p>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-xl-4 offset-xl-4 col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-sm-10 offset-sm-1 mb-5">
                <div className="row">
                  <div className="col-xl-12">
                    <input className="btn btnRegFsubmit btn-block" type="submit" name="yt0" value={t('submit')} />                  
                  </div>
                </div>
              </div>
            </div>
          </form>           
        </div>
      </div>
    </div>
  )
}