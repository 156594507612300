import { useEffect,useState,useCallback } from "react";
import { useTranslation } from 'react-i18next';
import Axios from "axios"
import { useForm,Controller } from 'react-hook-form';
import Constants from "../services/Constants"; 
import Select from 'react-select';
import $ from "jquery";
import useMotherToungue from "../hook/useMotherToungue";
import useReligion from "../hook/useReligion";

export default function UserPartnerBasicInfoComponent({userBasicInfo,dateDOB,issubmit,setIsSubmit}){
  let userToken = JSON.parse(localStorage.getItem('userToken'));
  const { t } = useTranslation();
  const { handleSubmit : handleSubmit1,getValues ,setValue,clearErrors, control, formState: { errors } } = useForm({defaultValue:{
    partner_religion:"",partner_caste:"",partner_subcaste:""
  }});
  const [getReligionData] = useReligion("religion/0/0/0");
  const [getMotherToungueData] = useMotherToungue("mothertongue");
  const [getCaste,setgetCaste] = useState([]);
  const [getSubCaste,setgetSubCaste] = useState([]); 

  const onSubmit = (data) => {
      Axios.post(Constants.API_URL+"postmyprofile",{
        api_token : userToken,partner : 1, partner_age : data.partner_age,
        partner_age1 :data.partner_age1,partner_height : data.partner_height,
        partner_height1 : data.partner_height1,partner_marital : data.partner_marital,
        partner_religion : data.partner_religion,partner_caste : data.partner_caste,
        partner_subcaste : data.partner_subcaste,partner_toungue : data.partner_toungue
      }).then((response)=>{
        setIsSubmit('');
        setIsSubmit(response.data.error+'/'+response.data.message);
        $(".closePartnerBasicModal").click();
      }).catch((err) => {
        console.log(err);
      })
  }

  const handleReligionChange = useCallback((val) => {
    if(val!==''){
      if(val!==getValues('partner_religion')){
        setValue('partner_caste','');
        setValue('partner_subcaste','');
      }
      setValue('partner_religion',val);
      Axios.get(Constants.API_URL+"religion/"+val+"/0/0").then((response)=>{
        if(response.data.data){
          setgetCaste(response.data.data);
        }
      });
    }
  },[getValues,setValue]);

  const handleCasteChange = useCallback((val) => {
    if(val!==''){
      clearErrors("partner_caste");
      setValue('partner_caste',val);
      Axios.get(Constants.API_URL+"religion/"+getValues('partner_religion')+"/"+val+"/0").then((response)=>{
        if(response.data.data){
          setgetSubCaste(response.data.data);
        }
      });
    }
  },[clearErrors,setValue,getValues])

  function handleSubCasteChange(e){
    setValue('partner_subcaste',e.value);
    clearErrors("partner_subcaste");
  }

  useEffect(() => {
    if(userBasicInfo!==null){
      setValue('partner_age',userBasicInfo.partner_age);
      setValue('partner_age1',userBasicInfo.partner_age1);
      setValue('partner_height',userBasicInfo.partner_height);
      setValue('partner_height1',userBasicInfo.partner_height1);
      setValue('partner_marital',userBasicInfo.partner_marital); 
      setValue('partner_religion',userBasicInfo.partner_religion);
      setValue('partner_caste',userBasicInfo.partner_caste);
      setValue('partner_subcaste',userBasicInfo.partner_subcaste); 
      setValue('partner_toungue',userBasicInfo.partner_toungue);
      handleReligionChange(userBasicInfo.partner_religion);
      handleCasteChange(userBasicInfo.partner_caste);
    }
  },[userBasicInfo,setValue,handleReligionChange,handleCasteChange]);


	return(
		<div className="modal fade gtEditForm" id="basicPInfoModal" tabIndex="-1" role="dialog" aria-labelledby="basicPInfoModal" aria-hidden="true" data-backdrop="static">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header text-center">
            <div className="col-12">
              <h5 className="modal-title" id="exampleModalLabel">Basic &amp; Religion Preferences
              <button type="button" className="close closePartnerBasicModal" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
              </h5>
            </div>
          </div>
          <div className="modal-body modalBodyHeight">
            <form className="form-horizontal" onSubmit={handleSubmit1(onSubmit)}> 
              <div className="row">
                <div className="container">
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="form-group">
                        <label htmlFor="Partner_partner_age" className="required">Age <span className="required">*</span>
                        </label>             
                        <div className="row">
                          <div className="col-xl-6 col-12">
                            {Constants.getAgeAsc && (
                              <Controller
                               name="partner_age"
                               control={control}
                               render={({ field: { onChange, value, name, ref } }) => (
                                 <Select
                                  inputRef={ref}
                                  className="mb-4"
                                  value={Constants.getAgeAsc.find((c) => c.value === value) || ''}
                                  options={Constants.getAgeAsc}
                                  onChange={(val: ICategory) => {
                                   onChange(val.value);
                                  }}
                                 />
                               )}
                            />)}        
                          </div>
                          <div className="col-xl-6 col-12">
                             {Constants.getAgeDesc && (
                                <Controller
                                 name="partner_age1"
                                 control={control}
                                 render={({ field: { onChange, value, name, ref } }) => (
                                   <Select
                                    inputRef={ref}
                                    className="mb-4"
                                    value={Constants.getAgeDesc.find((c) => c.value === value) || ''}
                                    options={Constants.getAgeDesc}
                                    onChange={(val: ICategory) => {
                                     onChange(val.value);
                                    }}
                                   />
                                 )}
                              />)}                                 
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-12">
                      <div className="form-group">
                        <label htmlFor="Partner_partner_height">Height</label>
                        <div className="row">
                          <div className="col-xl-6 col-12">
                            {Constants.getHeightAsc && (
                                <Controller
                                 name="partner_height"
                                 control={control}
                                 render={({ field: { onChange, value, name, ref } }) => (
                                   <Select
                                    inputRef={ref}
                                    className="mb-4"
                                    value={Constants.getHeightAsc.find((c) => c.value === value) || ''}
                                    options={Constants.getHeightAsc}
                                    onChange={(val: ICategory) => {
                                     onChange(val.value);
                                    }}
                                   />
                                 )}
                              />)}                   
                          </div>
                          <div className="col-xl-6 col-12">
                            {Constants.getHeightDesc && (
                                <Controller
                                 name="partner_height1"
                                 control={control}
                                 render={({ field: { onChange, value, name, ref } }) => (
                                   <Select
                                    inputRef={ref}
                                    className="mb-4"
                                    value={Constants.getHeightDesc.find((c) => c.value === value) || ''}
                                    options={Constants.getHeightDesc}
                                    onChange={(val: ICategory) => {
                                     onChange(val.value);
                                    }}
                                   />
                                 )}
                              />)}                                      
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-12">
                      <div className="form-group">
                        <label htmlFor="Partner_partner_marital" className="required">Marital Status 
                          <span className="required">*</span>
                        </label>              
                        {Constants.getMartialStatus && (
                          <Controller
                           name="partner_marital"
                           control={control}
                           render={({ field: { onChange, value, name, ref } }) => (
                             <Select
                              inputRef={ref}
                              className="mb-4"
                              value={Constants.getMartialStatus.find((c) => c.value === value) || ''}
                              options={Constants.getMartialStatus}
                              onChange={(val: ICategory) => {
                               onChange(val.value);
                              }}
                             />
                           )}
                        />)}                             
                      </div>
                    </div>
                    <div className="col-xl-12">
                      <div className="form-group">
                        <label htmlFor="Partner_partner_religion" className="required">Religion <span className="required">*</span></label>             
                        {getReligionData && (
                          <Controller
                           name="partner_religion"
                           control={control}
                           render={({ field: { onChange, value, name, ref } }) => (
                             <Select
                              inputRef={ref}
                              placeholder ={t('select_any_one')}
                              className="mb-4"
                              value={getReligionData.find((c) => c.value === value) || ''}
                              options={getReligionData}
                              defaultValue={Constants.prompt}
                              onChange={(e) => handleReligionChange(e.value)} 
                             />
                           )} 
                        />)}    
                      </div>
                    </div>
                    <div className="col-xl-12">
                      <div className="form-group">
                        <label htmlFor="Partner_partner_caste" className="required">Caste <span className="required">*</span></label> 
                         {getCaste && (
                          <Controller
                           name="partner_caste"
                           control={control}
                           rules = {{required:true}}
                           render={({ field: { onChange, value, name, ref } }) => (
                             <Select
                              inputRef={ref}
                              placeholder ={t('select_any_one')}
                              className="mb-4"
                              value={getCaste.find((c) => c.value === value) || ''}
                              options={getCaste}
                              defaultValue={Constants.prompt}
                              onChange={(e) => handleCasteChange(e.value)} 
                             />
                           )} 
                         />)}   
                         {errors.partner_caste?.type==="required" && <p className="error">{t('caste_cannot_be_blank')}</p>}                                      
                      </div>
                    </div>
                    <div className="col-xl-12">
                      <div className="form-group">
                        <label htmlFor="Partner_partner_subcaste" className="required">Subcaste 
                          <span className="required">*</span>
                        </label>             
                         {getSubCaste && (
                          <Controller
                           name="partner_subcaste"
                           control={control}
                           rules = {{required:true}}
                           render={({ field: { onChange, value, name, ref } }) => (
                             <Select
                              inputRef={ref}
                              placeholder ={t('select_any_one')}
                              className="mb-4"
                              value={getSubCaste.find((c) => c.value === value) || ''}
                              options={getSubCaste}
                              defaultValue={Constants.prompt}
                              onChange={(e) => handleSubCasteChange(e)} 
                             />
                           )} 
                         />)} 
                      {errors.partner_subcaste?.type==="required" && <p className="error">{t('subcaste_cannot_be_blank')}</p>}                                                                       
                      </div>
                    </div>
                    <div className="col-xl-12">
                      <div className="form-group">
                        <label htmlFor="Partner_partner_toungue" className="required">Mother Tongue <span className="required">*</span></label>             
                        {getMotherToungueData && (
                          <Controller
                           name="partner_toungue"
                           control={control}
                           render={({ field: { onChange, value, name, ref } }) => (
                             <Select
                              inputRef={ref}
                              className="mb-4"
                              value={getMotherToungueData.find((c) => c.value === value) || ''}
                              options={getMotherToungueData}
                              defaultValue={Constants.prompt}
                              onChange={(val: ICategory) => {
                               onChange(val.value);
                              }}
                             />
                           )} 
                         />)}                              
                      </div>
                    </div>              
                    <div className="col-xl-12">
                      <div className="form-group text-center">
                        <input className="btn btnRegFsubmit" name="partner" type="submit" value="Update" />           
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>      
          </div>
        </div>
      </div>
    </div>
  )
}