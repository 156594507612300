import { useState, useEffect } from "react";
import Axios from "axios";
import Constants from "../services/Constants";

const useCountry = (url) => {
  const [data, setData] = useState(null);

  useEffect(() => {
  	Axios.get(Constants.API_URL+url).then((response)=>{
		setData(response.data.data);
	});
  }, [url]);

  return [data];
};

export default useCountry;
