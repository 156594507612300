import React from 'react'
import { Link } from "react-router-dom"
import { useTranslation } from 'react-i18next';

export default function MessageComponent() {
	const { t } = useTranslation();
    return (
    	<div className="col-xl-12">
		  <div className="w-100 gtNewMsg">
			<Link to="/composemessage" >
				<i className="fas fa-envelope mr-2"></i>{t('compose_message')}</Link>
		  </div>
		  <div className="w-100 gtMsgNav mt-3 mb-4">
				<Link to="/inbox"  state={{title:false}} >{t('message')} - {t('inbox')}</Link>
        <Link to="/messagesent" state={{title:false}} >{t('message')} - {t('sent')}</Link>
        <Link to="/interestreceive"  state={{title:false}} >{t('interest')} - {t('received')}</Link>
        <Link to="/interestsent"  state={{title:false}} >{t('interest')} - {t('sent')}</Link>
		  </div>    			
		</div>
    )
}

