import { Link } from "react-router-dom";
import {useEffect,useState} from 'react';

export default function ResultNotFoundComponent({data}){
	const [style,setStyle] = useState({});
	useEffect(() =>{
		if(data===1) setStyle({'top': '12%','left': '11%'});
		if(data===2) setStyle({'top':'14%','left':'135px'});
		if(data===3) setStyle({'top':'10%','left':'25%'});
	},[data]);

	return(
		<section className="search_201">
		  <div className="container">
		    <div className="row"> 
		    	<div className="col-sm-12 ">
		    		<div className="col-sm-12  text-center">
		    			<div className="search_not_found serch-msg-found" >
					      <img alt={"Search not found"} src={require('../assets/images/her.gif')} style={style} className="search_not" />
		    			</div>
					    <div className="contant_box_search">
		    				<h3 className="h2">
		    					Sorry! No Result Found : (
		    				</h3>
		    				<p>We're sorry what you were looking for.please try another way</p>
		    				<Link to="/" className="link_404">Go to Home</Link>
				  		</div>
				    </div>
				  </div>
				</div>
		  </div>
		</section>
	)
}

