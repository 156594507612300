import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Axios from "axios"
import Constants from "../services/Constants";
import $ from "jquery";

export default function UserAboutComponent({userBasicInfo,issubmit,setIsSubmit}){
  let userToken = JSON.parse(localStorage.getItem('userToken'));
  const { t } = useTranslation();
  const { register,handleSubmit,getValues ,setValue } = useForm({mode : "onChange",
  defaultValue:{ud_about:""}});

  const onAboutSubmit = (data) => {
    Axios.post(Constants.API_URL+"postmyprofile",{
        api_token : userToken,ud_about:data.ud_about
    }).then((response)=>{
      setIsSubmit('');
      setIsSubmit(JSON.parse(response.data.error)+'/'+response.data.message);
      $(".closeAboutModal").click();
    }).catch((err) => {
    })
  }

  useEffect(() => {
    if(userBasicInfo!=null){
      setValue('ud_about',userBasicInfo.ud_about); 
    }
  },[userBasicInfo,setValue]);

	return (
		<div className="modal fade gtEditForm" id="aboutInfoModal" tabIndex="-1" role="dialog" aria-labelledby="aboutInfoModal" aria-hidden="true">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header text-center">
            <div className="col-12">
              <h5 className="modal-title" id="exampleModalLabel">About Me
              <button type="button" className="close closeAboutModal" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
              </button>
              </h5>
            </div>
          </div>
          <form className="form-horizontal" onSubmit={handleSubmit(onAboutSubmit)}>      
            <div className="modal-body">
              <div className="row">
                <div className="container">
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <label htmlFor="Userdetails_ud_about">{t('about')}</label>             
                        <textarea className="form-control" {...register('ud_about')} defaultValue={getValues('ud_about') || ''} />     
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group text-center">
                        <input className="btn btnRegFsubmit" name="about" type="submit" value="Update" />    
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>    
        </div>
      </div>
    </div>
  )
}