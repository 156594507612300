import Axios from "axios" 
import { useEffect,useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Constants from "../services/Constants";  
import ListUserComponent from '../components/ListUserComponent'
import Skeleton , { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import ResultNotFoundComponent from '../components/ResultNotFoundComponent';

export default function RecentjoinComponent() {
  const navigate = useNavigate();
	let userToken = JSON.parse(localStorage.getItem('userToken'));
  const [items, setItems] = useState([]);
  const { t } = useTranslation();
  const [update, setUpdate] = useState(0);
  const [loading,setLoading] = useState(0);
  let userPaid = parseInt(localStorage.getItem('userPaid'));
  let userTransaction = parseInt(localStorage.getItem('userTransaction'));


  useEffect(() => { 
    const getRecentJoin = () => {
      Axios.post(Constants.API_URL+"getrecentjoin",{ api_token : userToken
      }).then((response)=>{
        setItems(response.data.data);  
        setLoading(1);
      });
    }
    if(userPaid===1){
      getRecentJoin();
    }else{
      navigate('/home',{state:{title:true,error:true,message:(userTransaction!==0) ? t('please_contact_support') : (userTransaction===0 ? t('please_add_a_membership') : t('please_login_to_continue'))}})
    }
  },[userToken,update,navigate,t,userPaid,userTransaction]);

  
  return (
    <>
      {loading===0 && 
      <SkeletonTheme baseColor="#dfbb26" height="10px" highlightColor="#fff">
        <Skeleton />
      </SkeletonTheme>}
    	<div className="container mt-5">
    	  <h3 className="gtPageTitle">{t('recently_joined_profile')}</h3>
    	  <p className="gtPageSubTitle">{t('all_joined_members_are_register_here')}</p>
    	  {items.length > 0 ? <ListUserComponent  data={items} update={update} setUpdate={setUpdate} /> : <ResultNotFoundComponent />} 
    	</div>
    </>	
 	)
}