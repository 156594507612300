import React, { useEffect } from 'react'
import { Link,useNavigate,useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import Constants from "../services/Constants";
import Axios from "axios"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function ResetPasswordComponent(){
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { register,setValue, handleSubmit,getValues, formState: { errors } } = useForm();

  const onSubmit = (data) => {
      Axios.post(Constants.API_URL+"resetpassword",{
          id: location.state.id, password: data.password,
        }).then((response)=>{
          if (response.data.data.user_id===0)  toast.warning(t('invalid_code_or_password'));
          else  navigate('/login',{state:{title:true,message:response.data.message}});
        }).catch((err) => {
          console.log(err);
        })
  }

  useEffect(() => {
      if(location.state!==null){
        if(location.state.title===true) toast.success(location.state.message);
        if(location.state.title)  navigate(location.pathname,{state:{title:false,message :'',id:location.state.id,otp:location.state.otp}});
      }
  },[location,navigate])


  return (
      <div className="container mt-5">
      <ToastContainer />
        <form onSubmit={handleSubmit(onSubmit)} id="forgetpassword-form" className="form-horizontal" encType="multipart/form-data">
          <div className="row">
            <div className="col-xl-8 offset-xl-2 col-md-8 offset-md-2 col-lg-10 offset-lg-1">
              <div className="gtLogMain">
                <div className="row">
                  <div className="col-xl-6 col-lg-6 d-lg-block pb-4 ">
                    <div className="gtLogRegNow">
                    <img alt={"resetpassword"} src={require('../assets/images/login-couple.png')} className="img-fluid" />
                    <h5 className="mt-2">{t('not_yet_registered')}</h5>
                    <Link to="/register" className="btn btnLogReg mt-2">{t('register_now')}</Link>
                    </div>
                    <div className="clearfix"></div>
                  </div>
                  <div className="col-xl-6 col-lg-6">
                    <h4 className="gtPageTitle">{t('reset_your_password')}</h4>
                    <p className="gtPageSubTitle">{t('enter_your_code_and_follow_to_login')}</p>
                    <p className="gtPageSubTitle">{t('your_code_is')} : {location.state.otp}</p>
                    <div className="row mt-4">
                    <div className="form-group col-12 forget-msg">
                      <label>{t('otp')}</label>
                      <div className="input-group mb-3">
                        <input type="text" className="form-control" placeholder={t('enter_your_code')}
                        {...register("code", 
                          {
                            required: true, 
                            maxLength: 4,  
                            minLength: 4,  
                            onChange:(e) => setValue('code',e.target.value.replace(/\D/g,'')),
                            validate: value => value === String(location.state.otp) || t('otp_is_invalid'),
                          }
                        )} />
                      </div>
                        {errors.code?.type==="required" && <p className="error">{t('otp_is_required')}</p>}
                        {errors.code?.type==="maxLength" && <p className="error">{t('otp_is_maxlength')}</p>}
                        {errors.code?.type==="minLength" && <p className="error">{t('otp_is_minlength')}</p>}
                        {errors.code?.type==="validate" && <p className="error">{t('otp_is_invalid')}</p>}
                        <label>{t('new_password')}</label>
                      <div className="input-group mb-3">
                        <input type="text"  className="form-control" placeholder={t('enter_your__new_password')}
                        {...register("password", 
                          {
                            required: true, 
                             minLength: 3, 
                             maxLength: 15, 
                          }
                        )}
                         />
                      </div>
                        {errors.password?.type==="required" && <p className="error">{t('password_is_required')}</p>}
                        {errors.password?.type==="minLength" && <p className="error">{t('old_password_is_minLength')}</p>}
                        {errors.password?.type==="maxLength" && <p className="error">{t('old_password_is_maxlength')}</p>}
                        <label>{t('confirm_password')}</label>
                      <div className="input-group mb-3">
                        <input type="text"  className="form-control" placeholder={t('enter_your_confirm_password')} 
                        {...register("confirm_password", 
                          {
                            required: true, 
                            minLength: 3, 
                            maxLength: 15,  
                            validate: value => value === getValues("password") || 'Confirm password must be same',
                          }
                        )}
                         />
                      </div>
                        {errors.confirm_password?.type==="required" && <p className="error">{t('confirm_password_is_required')}</p>}
                        {errors.confirm_password?.type==="minLength" && <p className="error">{t('confirm_password_is_minlength')}</p>}
                        {errors.confirm_password?.type==="maxLength" && <p className="error">{t('confirm_password_is_maxlength')}</p>}
                        {errors.confirm_password?.type==="validate" && <p className="error">{t('confirm_password_must_be_same')}</p>}
                    </div>
                      <div className="form-group col-12 text-center">
                        <button type="Submit"  className="btn btnLogMain">{t('submit')}</button>
                    </div>
                    <div className="form-group col-12 text-center mt-4">
                      <Link to="/login" state={{title:false}} className="btn btnBackToLog"><i className="fas fa-arrow-left"></i>&nbsp;&nbsp;{t('back_to_login')}</Link>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
  )
}