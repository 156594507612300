import HeaderComponent from './components/HeaderComponent';
import FooterComponent from './components/FooterComponent';
import IndexComponent from './views/index';
import RegisterComponent from './views/register';
import RegiterOneComponent from './views/registerone';
import RegiterTwoComponent from './views/registertwo';
import RegiterThreeComponent from './views/registerthree';
import HomeComponent from './views/home';
import LoginComponent from './views/login';
import ForgotPasswordComponent from './views/forgetpassword';
import ResetPasswordComponent from './views/resetpassword';
import SearchComponent from './views/search';
import SuccessStoryComponent from './views/successstory';
import MemberPlanComponent from './views/memberplan';
import ContactComponent from './views/contact';
import VerifyComponenet from './views/verify';
import ProfileComponent from './views/profile';
import UploadPhotoComponent from './views/uploadphoto';
import ShortListComponent from './views/shortlist';
import RecentjoinComponent from './views/recentjoin';
import BlocklistComponent from './views/blocklist';
import ProfileviewComponent from './views/profileview';
import ViewProfile from './views/viewprofile';
import PaidmemberComponent from './views/paidmembers';
import NewsmatchesComponent from './views/newmatches';
import InboxComponent from './views/inbox';
import MessagesentComponent from './views/messagesent';
import InterestreceiveComponent from './views/interestreceive';
import InterestsentComponent from './views/interestsent';
import ComposemessageComponent from './views/composemessage';
import SettingComponent from './views/settings';
import DeleteprofileComponent from './views/deleteprofile';
import SearchbyidComponent from './views/searchbyid';
import InfoComponent from './views/info';
import ErrorComponent from './views/error';
import SuccessComponent from './views/success';
import PrintProfileComponent from './views/printprofile';
import { Routes, Route, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import Axios from "axios"
import Constants from './services/Constants';


export default function App() {
  const { i18n } = useTranslation("translation");

  useEffect(() => {
      Axios.get(Constants.API_URL+"getlang").then((response)=>{
          if(response.data.data.name!=='en'){
              var language = response.data.data.name;
              i18n.changeLanguage(language);
          }
      });
  },[i18n]);
  

  const location = useLocation();
  const validPages =  ['/printprofile'];

  return (
    <div className="App bg-white">  
    {!validPages.includes(location.pathname) ? <HeaderComponent /> : ''}
      <Routes>
        <Route path="/" element={<IndexComponent />} />
        <Route path="/error" element={<ErrorComponent />} />
        <Route path="/success" element={<SuccessComponent />} />
        <Route path="/register" element={<RegisterComponent />} />
        <Route path="/login" element={<LoginComponent />} />
        <Route path="/forgetpassword" element={<ForgotPasswordComponent />} />
        <Route path="/resetpassword" element={<ResetPasswordComponent />} />
        <Route path="/search" element={<SearchComponent />} />
        <Route path="/successstory" element={<SuccessStoryComponent />} />
        <Route path="/memberplan" element={<MemberPlanComponent />} />
        <Route path="/contact" element={<ContactComponent />} />
        <Route path="/verify" element={<VerifyComponenet />} />
        <Route path="/registerone" element={<RegiterOneComponent />} />
        <Route path="/registertwo" element={<RegiterTwoComponent />} />
        <Route path="/registerthree" element={<RegiterThreeComponent />} />
        <Route path="/home" element={<HomeComponent  />} />
        <Route path="/myprofile" element={<ProfileComponent />} />
        <Route path="/viewprofile/:userId" element={<ViewProfile />} />
        <Route path="/composemessage/:profileId" element={<ComposemessageComponent />} />
        <Route path="/composemessage" element={<ComposemessageComponent />} />
        <Route path="/uploadphoto" element={<UploadPhotoComponent />} />
        <Route path="/shortlist" element={<ShortListComponent />} />
        <Route path="/recentjoin" element={<RecentjoinComponent />} />
        <Route path="/blocklist" element={<BlocklistComponent />} />
        <Route path="/profileview" element={<ProfileviewComponent />} />
        <Route path="/paidmembers" element={<PaidmemberComponent />} />
        <Route path="/newmatches" element={<NewsmatchesComponent />} />
        <Route path="/inbox" element={<InboxComponent />} />
        <Route path="/messagesent" element={<MessagesentComponent />} />
        <Route path="/interestreceive" element={<InterestreceiveComponent />} />
        <Route path="/interestsent" element={<InterestsentComponent />} />
        <Route path="/settings" element={<SettingComponent />} />
        <Route path="/deleteprofile" element={<DeleteprofileComponent />} />
        <Route path="/searchbyid" element={<SearchbyidComponent />} />
        <Route path="/:pageUrl" element={<InfoComponent />} />
        <Route path="/printprofile" element={<PrintProfileComponent />} />
      </Routes>
     {!validPages.includes(location.pathname) ? <FooterComponent /> : ''}
    </div>
  );
}

