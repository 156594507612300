import Axios from "axios" 
import { useNavigate,useLocation } from "react-router-dom";
import { useEffect,useState } from "react";
import { useTranslation } from 'react-i18next';
import Constants from "../services/Constants";  
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MessageComponent from '../components/MessageComponent';
import ListmessageComponent from '../components/ListmessageComponent';
import Skeleton , { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import ResultNotFoundComponent from '../components/ResultNotFoundComponent';

export default function InboxComponent() {
	const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  let userPaid = parseInt(localStorage.getItem('userPaid'));
  let userLiveChat = parseInt(localStorage.getItem('userLiveChat'));
  let userTransaction = parseInt(localStorage.getItem('userTransaction'));
  let userToken = JSON.parse(localStorage.getItem('userToken'));
  const [inboxmessages, setInboxmessages] = useState([]);
  const [update, setUpdate] = useState(0);
  const [loading,setLoading] = useState(0);
  const [width,setWidth] = useState({size:0,data:0});

  if(window.innerWidth >= 481 && window.innerWidth <= 768 && width.size===0){
    setWidth({...width,size:1});
  }

  if(window.innerWidth >= 769 && window.innerWidth <= 1024 && width.size===0){
    setWidth({...width,size:2});
  }

  if(window.innerWidth >= 1050 && window.innerWidth <= 1400 && width.size===0){
    setWidth({...width,size:3});
  }

  

  useEffect(() => {
  	if(userPaid===0 || userLiveChat===0 || userTransaction===0){
      navigate('/memberplan', {state:{title:true,message:(userTransaction!==0) ? ((userPaid===1 && userLiveChat===0) ? t('please_buy_a_eligible_plan') : t('please_contact_support')) : (userTransaction===0 ? t('please_add_a_membership') : ''),error:true}})
    }
    if(location.state!==null){
      var title = location.state.title;
      if(title===true){
        if(location.state.error===true) toast.error(location.state.message);
        else toast.success(location.state.message);
        if(location.state.title) navigate(location.pathname, { replace: true });
      }
    }
  },[location,navigate,t,userLiveChat,userPaid,userTransaction]);

   useEffect(() => { 
      const getInboxMessages =  () => {
        Axios.post(Constants.API_URL+"getinboxmessages",{
            api_token : userToken
          }).then((response)=>{
            setInboxmessages(response.data.data); 
            setLoading(1);
          });
      }
      getInboxMessages();
  },[userToken,update]);
	
  return (
    <>
      {loading=== 0 && 
      <SkeletonTheme baseColor="#dfbb26" height="10px" highlightColor="#fff">
        <Skeleton />
      </SkeletonTheme>}
    	<div className="container mt-5">
      <h3 className="gtPageTitle">{t('message_inbox')}</h3>
        <p className="gtPageSubTitle">{t('message_inbox_description')}</p>
    		<div className="row">
  				<div className="col-md-4">
  					<MessageComponent />
  				</div>
  				<div className="col-md-8">
            {inboxmessages.length===0 &&  <ResultNotFoundComponent data={width.size} />}
  					{inboxmessages.length > 0 && <ListmessageComponent data={inboxmessages} update={update} setUpdate={setUpdate} />}
  				</div>
  			</div>
  			<div className="col-md-1"></div>
  		</div>	
    </>
 	)
}