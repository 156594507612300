import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Axios from "axios"
import Constants from "../services/Constants";
import $ from "jquery";

export default function UserPartnerPrefComponent({userBasicInfo,issubmit,setIsSubmit}){
	let userToken = JSON.parse(localStorage.getItem('userToken'));
  const { t } = useTranslation();
  const { register,handleSubmit,getValues ,setValue } = useForm({mode : "onChange",defaultValue:{
  	partner_expectation:""
  }});

  const onPartnerAboutSubmit = (data) => {
    Axios.post(Constants.API_URL+"postmyprofile",{
        api_token : userToken,partner: 1,partner_expectation:data.partner_expectation
    }).then((response)=>{
     	setIsSubmit('');
      setIsSubmit(response.data.error+'/'+response.data.message);
      $(".closePartnerAboutModal").click();
    }).catch((err) => {
      console.log(err);
    })
  }

  useEffect(() => {
    if(userBasicInfo!=null){
      setValue('partner_expectation',userBasicInfo.partner_expectation); 
    }
  },[userBasicInfo,setValue]);

	return (
		<div className="modal fade gtEditForm" id="partnerPreferenceModal" tabIndex="-1" role="dialog" aria-labelledby="partnerPreferenceModal" aria-hidden="true">
		  <div className="modal-dialog" role="document">
		    <div className="modal-content">
		      <div className="modal-header text-center">
		        <div className="col-12">
		          <h5 className="modal-title" id="exampleModalLabel">{t('partner_preference')}
		          <button type="button" className="close closePartnerAboutModal" data-dismiss="modal" aria-label="Close">
		              <span aria-hidden="true">×</span>
		          </button>
		          </h5>
		        </div>
		      </div>
		      <form className="form-horizontal" onSubmit={handleSubmit(onPartnerAboutSubmit)}>      
		        <div className="modal-body">
		          <div className="row">
		            <div className="container">
		              <div className="row">
		                <div className="col-12">
		                  <div className="form-group">
		                    <label htmlFor="Userdetails_partner_expectation">Partner Expectation</label>             
		                    <textarea className="form-control" {...register('partner_expectation')}  defaultValue={getValues('partner_expectation') || ''} />     
		                  </div>
		                </div>
		                <div className="col-12">
		                  <div className="form-group text-center">
		                    <input className="btn btnRegFsubmit" name="about" type="submit" value="Update" />    
		                  </div>
		                </div>
		              </div>
		            </div>
		          </div>
		        </div>
		      </form>    
		    </div>
		  </div>
		</div>
		)
}