import Axios from "axios"
import { useNavigate,useParams } from "react-router-dom";
import { useEffect,useState } from "react";
import { useForm } from 'react-hook-form';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import Constants from "../services/Constants";
import MessageComponent from '../components/MessageComponent'

export default function ComposemessageComponent(props) {
  let { profileId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [profID,setProfID] = useState(0);
  const [matriID,setmatriID] = useState([]);
  const [initial,setInitial] = useState(0);
  let userPaid = parseInt(localStorage.getItem('userPaid'));
  let userId = localStorage.getItem("userId");
  let userLiveChat = parseInt(localStorage.getItem('userLiveChat'));
  let userTransaction = parseInt(localStorage.getItem('userTransaction'));
  let userToken = JSON.parse(localStorage.getItem('userToken'));
  const {register, handleSubmit, formState: { errors } } = useForm();
  const [inputErrors,setInputErrors]= useState({ud_matriid :profID});
  const [issubmit,setIsSubmit]= useState(0);

  useEffect(() => {
    const getMatriId = () => {
      Axios.post(Constants.API_URL+"getmessageid",{
        api_token : userToken,
        id : userId
      }).then((response)=>{
          setmatriID(response.data.data);
      }).catch((err) => {

      })
    }
    getMatriId();
  },[userId,userToken]);


  if(issubmit){
    if(typeof(profID)==="undefined") setInputErrors({...inputErrors,ud_matriid:1}); 
    setIsSubmit(0);
  }

  useEffect(() => {
    if(profileId!=='' && initial===0){
      setInitial(1);
      setProfID(profileId);
    }
    if(userPaid===0 || userLiveChat===0 || userTransaction===0){
      navigate('/memberplan', {state:{title:true,message:(userTransaction!==0) ? ((userPaid===1 && userLiveChat===0) ? t('please_buy_a_eligible_plan') : t('please_contact_support')) : (userTransaction===0 ? t('please_add_a_membership') : ''),error:true}})
    }
  },[profileId,initial,userPaid,userLiveChat,userTransaction,navigate,t]);


   const onSubmit = (data) => {
     Axios.post(Constants.API_URL+"sendmessage",{
      api_token : userToken,mes_profile_id : profID,mes_subject : data.mes_subject,mes_message : data.mes_message
    }).then((response)=>{
      navigate('/messagesent',{state:{title:true,message:response.data.message,error:false}})
    }).catch((err) => {
        console.log(err);
    })
   } 

   const setMatriID = (e) => {
      if(e.value==='') setInputErrors({...inputErrors,ud_matriid:1});
      else setInputErrors({...inputErrors,ud_matriid:0});
      setProfID(e.value);
   }


  return (
    <div className="container mt-5">
      <ToastContainer />
      <h3 className="gtPageTitle">{t('compose_message')}</h3>
      <div className="row">
        <div className="col-md-3">
          <MessageComponent />
        </div>
        <div className="col-md-9">
          <div className="col-xl-10 col-md-12" id="content">
            <div id="pagination align-frm">
              <div className="col-xxl-13 col-xl-12 col-xs-16 col-sm-16 col-md-16 gt-msg-board" id="test-list">
                <form onSubmit={handleSubmit(onSubmit)} className="form-horizontal">
                  <div className="col-xxl-16 col-xl-16 gt-msg-top-strip gt-margin-top-10">
                    <div className="form-group">
                      <label><h4>{t('to')},</h4></label>
                       <Select className="" options={matriID} placeholder={t('select_any_one')}
                       value={matriID!==null ? matriID.filter(obj => obj.value === parseInt(profID)) : Constants.prompt} 
                        onChange={setMatriID}></Select>  
                        {inputErrors.ud_matriid===1 && <p className="error">{t('please_select_any_one')}</p>}           
                    </div>
                    <div className="form-group">
                      <input type="text" className="form-control flat" placeholder={t('enter_the_subject')} 
                        {...register("mes_subject", 
                          {
                            required: true, 
                          }
                        )} />
                      {errors.mes_subject?.type==="required" && <p className="error">{t('subject_is_required')}</p>}
                    </div>
                    <div className="form-group">
                      <textarea type="text" className="form-control cms_details" placeholder={t('enter_message_here')}
                        {...register("mes_message", 
                          {
                            required: true, 
                          }
                        )} ></textarea> 
                        {errors.mes_message?.type==="required" && <p className="error">{t('message_is_required')}</p>} 
                    </div>
                    <button onClick={(e) => setIsSubmit(1)} className="btn btnRegFsubmit" type="submit" >{t('send')}</button>               
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>  
  )
}