import React, { useEffect } from 'react'
import { useForm,Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Axios from "axios"
import Select from 'react-select';
import Constants from "../services/Constants";
import useEducation from "../hook/useEducation";
import useOccupation from "../hook/useOccupation";
import $ from "jquery";

export default function  UserProfInfoComponent({userBasicInfo,issubmit,setIsSubmit}){
  let userToken = JSON.parse(localStorage.getItem('userToken'));
  const { t } = useTranslation();
  const [getEducationData] = useEducation("education");
  const [getOccupationData] = useOccupation("occupation");
  const { register,handleSubmit,resetField,getValues ,setValue , control } = useForm({mode : "onChange",defaultValue:{
    ud_employee:"",ud_education:"",ud_additional:"",ud_occupation:"",ud_income:"",ud_father_occupation:"",ud_mother_occupation:"",
    ud_familyvalue: ""
  }});

  const onBasicSubmit = (data) => {
    Axios.post(Constants.API_URL+"postmyprofile",{
        api_token : userToken,ud_education:data.ud_education,
        ud_additional :data.ud_additional,ud_occupation : data.ud_occupation,
        ud_income : data.ud_income,ud_employee : data.ud_employee,ud_familyvalue : data.ud_familyvalue,
        ud_father_occupation : data.ud_father_occupation,ud_mother_occupation : data.ud_mother_occupation
      }).then((response)=>{
        setIsSubmit('');
        setIsSubmit(response.data.error+'/'+response.data.message);
        $(".closeProfModal").click();
      }).catch((err) => {
        console.log(err);
      })
  }

  useEffect(() => {
    if(userBasicInfo!=null){
      setValue('ud_employee',String(userBasicInfo.ud_employee));
      setValue('ud_education',userBasicInfo.ud_education);
      setValue('ud_additional',userBasicInfo.ud_additional);
      setValue('ud_occupation',userBasicInfo.ud_occupation);
      setValue('ud_income',userBasicInfo.ud_income);
      setValue('ud_father_occupation',parseInt(userBasicInfo.ud_father_occupation));
      setValue('ud_mother_occupation',parseInt(userBasicInfo.ud_mother_occupation));
      setValue('ud_familyvalue',userBasicInfo.ud_familyvalue);
    }
  },[userBasicInfo,setValue]);

  const handleEmployeeChange = (val) => {
    resetField('ud_employee');
    setValue('ud_employee',val);
  }


  return (
      <div className="modal fade gtEditForm" id="professionalInfoModal" tabIndex="-1" role="dialog" aria-labelledby="professionalInfoModal" aria-hidden="true" data-backdrop="static">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <div className="col-12">
                <h5 className="modal-title" id="exampleModalLabel">Professional Information
                <button type="button" className="close closeProfModal" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
                </h5>
              </div>
            </div>
            <div className="modal-body modalBodyHeight">
              <div className="row">
                 <form className="form-horizontal" onSubmit={handleSubmit(onBasicSubmit)}>  
                  <div className="container">
                    <div className="row">
                      <div className="col-xl-12">
                        <div className="form-group">
                          <label htmlFor="Userdetails_ud_education">Education</label>             
                          {getEducationData && (
                            <Controller
                             name="ud_education"
                             control={control}
                             render={({ field: { onChange, value, name, ref } }) => (
                               <Select
                                inputRef={ref}
                                placeholder ={t('select_any_one')}
                                className="mb-4"
                                value={getEducationData.find((c) => c.value === value) || ''}
                                options={getEducationData}
                                defaultValue={Constants.prompt}
                                onChange={(val) => {
                                 onChange(val.value);
                                }} 
                               />
                             )} 
                          />)}                              
                        </div>
                      </div>
                      <div className="col-xl-12">
                        <div className="form-group">
                          <label htmlFor="Userdetails_ud_additional">Additional</label>
                          <Controller
                            name="ud_additional"
                            control={control}
                            render={({ field }) => (
                            <input  {...field}
                            className="form-control"
                            placeholder={t('additional_education')}
                             />
                            )} 
                          />                    
                        </div>
                      </div>
                      <div className="col-xl-12">
                        <div className="form-group">
                          <label htmlFor="Userdetails_ud_occupation">Occupation</label>             
                          {getOccupationData && (
                            <Controller
                             name="ud_occupation"
                             control={control}
                             render={({ field: { onChange, value, name, ref } }) => (
                               <Select
                                inputRef={ref}
                                placeholder ={t('select_any_one')}
                                className="mb-4"
                                value={getOccupationData.find((c) => c.value === value) || ''}
                                options={getOccupationData}
                                defaultValue={Constants.prompt}
                                onChange={(val) => {
                                 onChange(val.value);
                                }} 
                               />
                             )} 
                          />)}           
                        </div>
                      </div>
                      <div className="col-xl-12">
                        <div className="form-group">
                          <label htmlFor="Userdetails_ud_occupation">Occupation</label>             
                          {getOccupationData && (
                            <Controller
                             name="ud_occupation"
                             control={control}
                             render={({ field: { onChange, value, name, ref } }) => (
                               <Select
                                inputRef={ref}
                                placeholder ={t('select_any_one')}
                                className="mb-4"
                                value={getOccupationData.find((c) => c.value === value) || ''}
                                options={getOccupationData}
                                defaultValue={Constants.prompt}
                                onChange={(val) => {
                                 onChange(val.value);
                                }} 
                               />
                             )} 
                          />)}           
                        </div>
                      </div>
                      <div className="col-xl-12">
                        <div className="form-group">
                          <label htmlFor="Userdetails_ud_occupation">Father Occupation</label>             
                          {getOccupationData && (
                            <Controller
                             name="ud_father_occupation"
                             control={control}
                             render={({ field: { onChange, value, name, ref } }) => (
                               <Select
                                inputRef={ref}
                                placeholder ={t('select_any_one')}
                                className="mb-4"
                                value={getOccupationData.find((c) => c.value === value) || ''}
                                options={getOccupationData}
                                defaultValue={Constants.prompt}
                                onChange={(val) => {
                                 onChange(val.value);
                                }} 
                               />
                             )} 
                          />)}           
                        </div>
                      </div>
                      <div className="col-xl-12">
                        <div className="form-group">
                          <label htmlFor="Userdetails_ud_occupation">Mother Occupation</label>             
                          {getOccupationData && (
                            <Controller
                             name="ud_mother_occupation"
                             control={control}
                             render={({ field: { onChange, value, name, ref } }) => (
                               <Select
                                inputRef={ref}
                                placeholder ={t('select_any_one')}
                                className="mb-4"
                                value={getOccupationData.find((c) => c.value === value) || ''}
                                options={getOccupationData}
                                defaultValue={Constants.prompt}
                                onChange={(val) => {
                                 onChange(val.value);
                                }} 
                               />
                             )} 
                          />)}           
                        </div>
                      </div>
                      <div className="col-xl-12">
                        <div className="form-group">
                          <label htmlFor="Userdetails_ud_occupation">Family Value</label>             
                          {Constants.getFamilyValues && (
                            <Controller
                             name="ud_familyvalue"
                             control={control}
                             render={({ field: { onChange, value, name, ref } }) => (
                               <Select
                                inputRef={ref}
                                placeholder ={t('select_any_one')}
                                className="mb-4"
                                value={Constants.getFamilyValues.find((c) => c.value === value) || ''}
                                options={Constants.getFamilyValues}
                                defaultValue={Constants.prompt}
                                onChange={(val) => {
                                 onChange(val.value);
                                }} 
                               />
                             )} 
                          />)}           
                        </div>
                      </div>
                      <div className="col-xl-12">
                        <div className="form-group">
                          <label htmlFor="Userdetails_ud_employee">Employeed In</label>              
                          <div className="row">
                            <div className="col-12"> 
                              <span id="Userdetails_ud_employee">
                                <input type="radio" value="1" onClick={(e) => handleEmployeeChange(e.target.value)}
                                checked={getValues('ud_employee') === "1"}
                                {...register('ud_employee')}
                                />&nbsp; 
                                <label htmlFor="Userdetails_ud_employee_0">Government</label>
                                <br/>
                                <input type="radio" value="2" onClick={(e) => handleEmployeeChange(e.target.value)}
                                checked={getValues('ud_employee') === "2"}
                                {...register('ud_employee')}
                                />&nbsp; 
                                <label htmlFor="Userdetails_ud_employee_1">Private</label>
                                <br/>
                                <input type="radio" value="3" onClick={(e) => handleEmployeeChange(e.target.value)}
                                checked={getValues('ud_employee') === "3"}
                                {...register('ud_employee')}
                                />&nbsp; 
                                <label htmlFor="Userdetails_ud_employee_2">Business
                                </label>
                                <br/>
                                <input type="radio" value="4" onClick={(e) => handleEmployeeChange(e.target.value)}
                                checked={getValues('ud_employee') === "4"}
                                {...register('ud_employee')}
                                />&nbsp; 
                                <label htmlFor="Userdetails_ud_employee_3">Defence</label>
                                <br/>
                                <input type="radio" value="5" onClick={(e) => handleEmployeeChange(e.target.value)}
                                checked={getValues('ud_employee') === "5"}
                                {...register('ud_employee')}
                                />&nbsp; 
                                <label htmlFor="Userdetails_ud_employee_4">
                                Self Employee</label>
                                <br/>
                                <input type="radio" value="6" onClick={(e) => handleEmployeeChange(e.target.value)}
                                checked={getValues('ud_employee') === "6"}
                                {...register('ud_employee')}
                                />&nbsp;
                                <label htmlFor="Userdetails_ud_employee_5">Not Working</label>
                              </span>  
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-12">
                        <div className="form-group text-center">
                          <input className="btn btnRegFsubmit" type="submit" value="Update" />           
                        </div>
                      </div>
                    </div>
                  </div>
                </form>        
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}