import React, { useEffect } from 'react'
import { Link,useNavigate,useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import 'react-toastify/dist/ReactToastify.css';

export default function ErrorComponent() {
	const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const state = location.state;

	useEffect(() => {
		if(state!==null){
			var title = location.state.title;
			if(title===true){
		    	if(location.state.error===true) toast.error(location.state.message);
	        else toast.success(location.state.message);
	       	if(location.state.title) navigate(location.pathname, { replace: true });
	    	}
		}
	})

	return (
			<section className="page_404">
  			<div className="container">
    			<div className="row"> 
    				<div className="col-sm-12 ">
    					<div className="col-sm-10 col-sm-offset-1  text-center">
						    <div className="four_zero_four_bg">
						      <img alt={"error"} src={require('../assets/images/heart.gif')} className="gif-404" />
						    </div>
						    <div className="contant_box_404">
						    	<h3 className="h2">{t('error_page_heading')}</h3>
						    	<p>{t('error_description')}</p>
						    	<Link to="/" className="link_404">{t('go_to_home')}</Link>
						  	</div>
				    	</div>
				    </div>
			    </div>
			  </div>
			</section>
		)
}