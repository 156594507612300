import React, { useEffect,useState } from 'react'
import { useNavigate,useLocation } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';

export default function SuccessComponent() {
	const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;
  const [success,setSuccess] = useState(null);

  useEffect(() => {
		if(state!==null){
      var title = location.state.title;
      if(title===true){
        if(location.state.error===false){
        	setSuccess(1);
        	toast.success(location.state.message);
        }else{
        	setSuccess(0);
        	toast.error(location.state.message);
        } 
        navigate(location.pathname, { replace: true });
      } 
    }
  },[location,navigate,state]);

  return (
  	<div className="container" style={{'marginTop':'100px'}}>
  	<ToastContainer />
  	<div className="row justify-content-center">
  		{success===1 &&
  			<div style={{'backgroundColor':'green','borderRadius':'50%','padding':'10px'}}>
  				<i className="fa fa-check" style={{'fontSize':'50px','color':'#fff'}}></i>
  			</div>
  		}
  		{success===0 &&
				<i className="fa fa-times-circle" style={{'fontSize':'72px','color':'red'}}></i>
			}
		</div>
		<h2 className="row justify-content-center mt-4" style={{'marginBottom':'100px'}}>{success===1 && t('transaction_success')}{success===0 && t('transaction_failure')}</h2>
		</div>
  )
}