import React, { useEffect,useState,useCallback } from 'react'
import { useForm,Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Axios from "axios"
import Select from 'react-select';
import Constants from "../services/Constants";
import useCountry from "../hook/useCountry";
import $ from "jquery";

export default function UserPartnerLocComponent({userBasicInfo,issubmit,setIsSubmit}){
  let userToken = JSON.parse(localStorage.getItem('userToken'));
  const { t } = useTranslation();
  const { handleSubmit:handleSubmit2,getValues ,setValue,clearErrors, control, formState: { errors } } = useForm({mode : "onChange",defaultValue:{
    partner_country :"",partner_state:"",partner_city:""
  }});
  const [getCountryData] = useCountry("location/0/0/0");
  const [getState,setgetState]  = useState([]);
  const [getCity,setgetCity]  = useState([]);
  const [multiCity,setMultiCity] = useState([]);

  const handleCountryChange = useCallback((val) => {
      if(val!==''){
        if(val!==getValues('partner_country')){
          setValue('partner_state','');
        }
        setValue('partner_country',val);
        Axios.get(Constants.API_URL+"location/"+val+"/0/0").then((response)=>{
          if(response.data.data){
            setgetState(response.data.data);
          }
        });
      }
    },[getValues,setValue]);

  const handleStateChange = useCallback((val) => {
    if(val!==''){
      clearErrors("partner_state");
      setValue('partner_state',val);
      Axios.get(Constants.API_URL+"location/"+getValues('partner_country')+"/"+val+"/0").then((response)=>{
        if(response.data.data){
          setgetCity(response.data.data);
        } 
      });
    }
    },[clearErrors,setValue,getValues]);

    const handleMultiCountry = useCallback(() => {
      if (getCity.length > 0 && userBasicInfo.hasOwnProperty('city')) {
        const updateCities = getCity.filter(city => userBasicInfo.city.includes(city.value.toString()));
        setMultiCity(updateCities);
      }
    }, [userBasicInfo, getCity]);

    useEffect(() => {
      handleMultiCountry();
    },[handleMultiCountry,userBasicInfo.partner_city]);

    function handleCityChange(e){
      let value = "";
      setMultiCity(e);
      if(multiCity.length>0) value += e.map((e) => e.value); 
      setValue('partner_city',value);
    }


  const onPartnLoc = (data) => {
    Axios.post(Constants.API_URL+"postmyprofile",{
      api_token : userToken,partner:1,
      partner_country : data.partner_country,partner_state : data.partner_state,
      partner_city : data.partner_city,
    }).then((response)=>{
      setIsSubmit('');
      setIsSubmit(response.data.error+'/'+response.data.message);
      $(".closePatLocModal").click();
    }).catch((err) => {
      console.log(err);
    })
  }

  useEffect(() => {
    if(userBasicInfo!=null){
      setValue('partner_country',userBasicInfo.partner_country);
      setValue('partner_state',userBasicInfo.partner_state);
      handleCountryChange(userBasicInfo.partner_country);
      handleStateChange(userBasicInfo.partner_state);
    }
  },[userBasicInfo,setValue,handleCountryChange,handleStateChange])

  return(
    <div className="modal fade gtEditForm" id="locationPreferenceModal" tabIndex="-1" role="dialog" aria-labelledby="locationPreferenceModal" aria-hidden="true" data-backdrop="static">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header text-center">
            <div className="col-12">
              <h5 className="modal-title" id="exampleModalLabel">Location Preferences
              <button type="button" className="close closePatLocModal" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
              </button>
              </h5>
            </div>
          </div>
          <div className="modal-body">
            <div className="row">
              <form className="form-horizontal" onSubmit={handleSubmit2(onPartnLoc)} >
                <div className="container">
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="form-group">
                        <label htmlFor="Userdetails_partner_country" className="required">Country <span className="required">*</span>
                        </label>              
                        {getCountryData && (
                            <Controller
                             name="partner_country"
                             control={control}
                             rules = {{required:true}}
                             render={({ field: { onChange, value, name, ref } }) => (
                               <Select
                                inputRef={ref}
                                placeholder ={t('select_any_one')}
                                className="mb-4"
                                value={getCountryData.find((c) => c.value === value) || ''}
                                options={getCountryData}
                                defaultValue={Constants.prompt}
                                onChange={(e) => handleCountryChange(e.value)} 
                       
                               />
                             )} 
                          />)}     
                          {errors.partner_country?.type==="required" && <p className="error">{t('country_cannot_be_blank')}</p>}                    
                      </div>
                    </div>
                    <div className="col-xl-12">
                      <div className="form-group">
                        <label htmlFor="Userdetails_partner_state" className="required">State <span className="required">*</span></label> 
                        {getState && (
                            <Controller
                             name="partner_state"
                             control={control}
                             rules = {{required:true}}
                             render={({ field: { onChange, value, name, ref } }) => (
                               <Select
                                inputRef={ref}
                                placeholder ={t('select_any_one')}
                                className="mb-4"
                                value={getState.find((c) => c.value === value) || ''}
                                options={getState}
                                defaultValue={Constants.prompt}
                                onChange={(e) => handleStateChange(e.value)} 
                               />
                             )} 
                          />)}     
                          {errors.partner_state?.type==="required" && <p className="error">{t('state_cannot_be_blank')}</p>}                    
                      </div>
                    </div>
                    <div className="col-xl-12">
                      <div className="form-group">
                        <label htmlFor="Userdetails_partner_city" className="required">City <span className="required">*</span></label>              
                         {getCity && (
                            <Controller
                             name="partner_city"
                             control={control}
                             rules = {{required:true}}
                             render={({ field: { onChange, value, name, ref } }) => (
                               <Select
                                inputRef={ref}
                                placeholder ={t('select_any_one')}
                                className="mb-4"
                                //value={getCity.length>0 ? getCity.filter(obj => multiCity.includes(obj.value)) : ''}
                                //value={getCity.filter((c) => c.value === multiCity) || ''}
                                options={getCity}
                                //defaultValue={Constants.prompt}

                                onChange={(e) => handleCityChange(e)} 
                                value={multiCity}
                                //value={getCity.filter((c) => c.value === value) || ''}
                                //value={getCity.length>0 ? getCity.filter(obj => multiCity.includes(obj.value)) :[]}
                                isMulti 
                                isClearable={true}
                               />
                             )} 
                          />)}     
                          {errors.partner_city?.type==="required" && <p className="error">{t('city_cannot_be_blank')}</p>}                    
                      </div>
                    </div>
                    <div className="col-xl-12">
                      <div className="form-group text-center">
                        <input className="btn btnRegFsubmit" name="location" type="submit" value="Update" />            
                      </div>
                    </div>
                  </div>
                </div>
              </form>        
            </div>
          </div>
        </div>
      </div>
    </div>
    )
}