import React, { useEffect,useState } from 'react'
import { useNavigate } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Axios from "axios"
import Constants from "../services/Constants";

export default function ContactComponent() {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { register, handleSubmit,setValue, formState: { errors } } = useForm();
  const onSubmit = (data) => {
		Axios.post(Constants.API_URL+"contact",{
			contact_email : data.contact_email,contact_message : data.contact_message,
			contact_mobile : data.contact_mobile,contact_name : data.contact_name,
			contact_subject : data.contact_subject,
		}).then((response)=>{
			navigate('/',{state:{title:true,message:t('thanks_for_contacting_us'),error:false}});
		}).catch((err) => {
			console.log(err);
		})
	};
  const [getContactDetails,setgetContactDetails] = useState([]);

	useEffect(() => {
		Axios.get(Constants.API_URL+"contactdetails").then((response)=>{
      if (response.data.data) {
      	setgetContactDetails(response.data.data);
      } 
    }).catch((err) => {
      console.log(err);
    })
  },[]);

  const allowOnlyNumber= (e)=> {
		setValue('contact_mobile',e.target.value.replace(/\D/g,''))
 	}

	return(
			<div className="container mt-5">
    		<div className="row">
    			<div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 col-md-10 offset-md-1">
						<h3 className="gtPageTitle">{t('contact_us')}</h3>
						<p className="gtPageSubTitle">{t('contact_us_if_any_questions')}</p>
						<div className="row mt-4 border-contact">
							<div className="col-xl-5 col-lg-5 mb-3">
								<div className="gtConDet">
									<div className="gtConDetHead">
										<div className="col-xl-12">
											{getContactDetails.site ?  getContactDetails.site : ''}	
										</div>
									</div>
									<div className="clearfix"></div>
									<div className="gtConDetBody">
										<div className="row">
											<div className="col-12">
												<p className="gtConTitle">
													{t('address')}
												</p>
												<h5 className="gtConSubTitle">
													{getContactDetails.address ?  getContactDetails.address : ''}												
												</h5>
											</div>
											<div className="col-12">
												<p className="gtConTitle">
													{t('email_id')}
												</p>
												<h5 className="gtConSubTitle">
													{getContactDetails.email ?  getContactDetails.email : ''}										
												</h5>
											</div>
											<div className="col-12">
												<p className="gtConTitle">
													{t('contact_no')}
												</p>
												<h5 className="gtConSubTitle">
													{getContactDetails.mobile ?  getContactDetails.mobile : ''}									
												</h5>
											</div>
										</div>
									</div>
									<div className="clearfix"></div>
									<div className="gtConDetFooter">
										<div className="col-xl-12 text-center">
											<a rel="noreferrer" href="https://www.facebook.com/" target="_blank">
												<i className="fab fa-facebook-square"></i>
											</a>
											<a rel="noreferrer" href="https://www.twitter.com/" target="_blank">
												<i className="fab fa-twitter-square"></i>
											</a>
											<a rel="noreferrer" href="https://www.youtube.com/" target="_blank">
												<i className="fab fa-youtube-square"></i>
											</a>
											<a rel="noreferrer" href="https://www.instagram.com/" target="_blank">
												<i className="fab fa-instagram"></i>
											</a>
										</div>
									</div>
								</div>
							</div>
							<div className="col-xl-7 col-lg-7 mb-5">
								<form onSubmit={handleSubmit(onSubmit)} id="contact-form" className="form-horizontal" encType="multipart/form-data">						
									<h5 className="gtPageTitle mb-4 mt-4">{t('fill_your_form')}</h5>
									<div className="form-group">
										<input type="text" className="form-control" placeholder={t('enter_your_name')} 
										{...register("contact_name", 
										{
											required: true,
											pattern: /^[A-Za-z\s]*$/, 
											maxLength: 32, 
										})} />
										{errors.contact_name?.type==="required" && <p className="error">{t('name_is_required')}</p>}
										{errors.contact_name?.type==="pattern" && <p className="error">{t('name_is_invalid')}</p>}                 	
									</div>
									<div className="form-group">
										<input type="text" className="form-control" placeholder={t('enter_your_email')}
										{...register("contact_email", 
										{
											required: true, 
											pattern: {
												value : /^\S+@\S+$/i,
											}
										})} />	
										{errors.contact_email?.type==="required" && <p className="error">{t('email_is_required')}</p>}				
										{errors.contact_email?.type==="pattern" && <p className='error'>{t('email_is_invalid')}</p>}
									</div>
									<div className="form-group">
										<input type="text" className="form-control" placeholder={t('enter_the_mobile_no')}
										{...register("contact_mobile", 
										{	
											required: true, 
											pattern:/^[0-9+-]+$/,
											maxLength: 10, 
											minLength: 10,
											onChange: (e) => allowOnlyNumber(e)
										})} />
										{errors.contact_mobile?.type==="required" && <p className="error">{t('mobile_is_required')}</p>}				
										{errors.contact_mobile?.type==="pattern" && <p className='error'>{t('mobile_is_invalid')}</p>}									
										{errors.contact_mobile?.type==="minLength" && <p className='error'>{t('mobile_is_minlength')}</p>}
										{errors.contact_mobile?.type==="maxLength" && <p className='error'>{t('mobile_is_maxlength')}</p>}																		
									</div>
									<div className="form-group">
										<input type="text" className="form-control" placeholder={t('enter_the_subject')}
										{...register("contact_subject", 
										{
											required: true, 
											maxLength: 32, 
										})} />	
										{errors.contact_subject?.type==="required" && <p className="error">{t('subject_is_required')}</p>}				                        		
									</div>
									<div className="form-group">
										<textarea type="text" className="form-control" placeholder={t('enter_message_here')}
										{...register("contact_message", 
										{
											required: true 
										})} >
										</textarea>	    
										{errors.contact_message?.type==="required" && <p className="error">{t('message_is_required')}</p>}				                        		
									</div>
									<div className="form-group">
										<div className="col-xl-16 text-center">
											<input className="btn btnSucPost" name="expectation" type="submit" value={t('submit')} />										
										</div>
									</div>
								</form>							
							</div>
						</div>
					</div>
				</div>
    	</div>
		)
}