import Axios from "axios"
import { useEffect,useState } from "react";
import { useTranslation } from 'react-i18next';
import Constants from "../services/Constants"; 
import ListUserComponent from '../components/ListUserComponent'
import Skeleton , { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import ResultNotFoundComponent from '../components/ResultNotFoundComponent';

export default function BlocklistComponent() {
	let userToken = JSON.parse(localStorage.getItem('userToken'));
  const [blocklistUsers, setBlockListUsers] = useState([]);
  const [update, setUpdate] = useState(0);
  const { t } = useTranslation();
  const [loading,setLoading] = useState(0);


  useEffect(() => { 
    const getShortList =  () => {
      Axios.post(Constants.API_URL+"getblocklist",{
          api_token : userToken
        }).then((response)=>{
          setBlockListUsers(response.data.data);  
          setLoading(1);
        });
    }
      getShortList();
  },[userToken,update]);

    return (
      <>
        {loading === 0 && 
        <SkeletonTheme baseColor="#dfbb26" height="10px" highlightColor="#fff">
          <Skeleton />
        </SkeletonTheme>}
      	<div className="container mt-5">
  	  		<h3 className="gtPageTitle">{t('blocked_profile')}</h3>
  			  <p className="gtPageSubTitle">{t('block_profile_description')}</p>
  				{blocklistUsers.length > 0 ? <ListUserComponent  data={blocklistUsers} update={update} setUpdate={setUpdate} /> : <ResultNotFoundComponent />} 
  			</div>
      </>
   )
}