import Axios from "axios" 
import { useEffect,useState } from "react";
import { useTranslation } from 'react-i18next';
import Constants from "../services/Constants";  
import { useNavigate } from "react-router-dom";
import ListUserComponent from '../components/ListUserComponent'
import Skeleton , { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import ResultNotFoundComponent from '../components/ResultNotFoundComponent';

export default function PaidmemberComponent() {
	let userToken = JSON.parse(localStorage.getItem('userToken'));
  const navigate = useNavigate();
  const [paidMembers, setPaidMembers] = useState([]);
  const { t } = useTranslation();
  const [update, setUpdate] = useState(0);
  const [loading,setLoading] = useState(0);
  let userPaid = parseInt(localStorage.getItem('userPaid'));
  let userTransaction = parseInt(localStorage.getItem('userTransaction'));


  useEffect(() => { 
    const getPaidmembers = () => {
      Axios.post(Constants.API_URL+"getpaidmembers",{
          api_token : userToken
        }).then((response)=>{
          setPaidMembers(response.data.data);  
          setLoading(1);
        });
    }
    if(userPaid===1){
      getPaidmembers();
    }else{
      navigate('/home',{state:{title:true,error:true,message:(userTransaction!==0) ? t('please_contact_support') : (userTransaction===0 ? t('please_add_a_membership') : t('please_login_to_continue'))}})
    }
  },[userToken,update,navigate,t,userPaid,userTransaction]);
  
  return (
    <>
      {loading=== 0 && 
      <SkeletonTheme baseColor="#dfbb26" height="10px" highlightColor="#fff">
        <Skeleton />
      </SkeletonTheme>}
    	<div className="container mt-5">
    		<h3 className="gtPageTitle">{t('paid_members')}</h3>
    		<p className="gtPageSubTitle">{t('paid_members_description')}</p>
        {paidMembers.length > 0 ? <ListUserComponent  data={paidMembers} update={update} setUpdate={setUpdate} /> : <ResultNotFoundComponent />} 
  		</div>	
    </>
 	)
}